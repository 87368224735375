const randomizeSpritePosition = spriteObject => {
    // https://github.com/LLK/scratch-flash/blob/689f3c79a7e8b2e98f5be80056d877f303a8d8ba/src/Scratch.as#L1385
    const data = {
        "Andhra Pradesh": [-68, -34, 32],
        "Arunachal Pradesh": [91, 97, 23],
        "Assam": [71, 74, 25],
        "Bihar": [10, 60, 19],
        "Chhattisgarh": [-35, 19, 26],
        "Goa": [-98, -76, 4],
        "Gujarat": [-150, 29, 24],
        "Haryana": [-62, 79, 15],
        "Himachal Pradesh": [-66, 124, 12], //changed
        "Jharkhand": [11, 33, 17],
        "Karnataka": [-104, -46, 28],
        "Kerala": [-95, -113,19],
        "Madhya Pradesh": [-92, 55, 34],
        "Maharashtra": [-104, -4, 31],
        "Manipur": [110, 45, 8],
        "Meghalaya": [76, 50, 11],
        "Mizoram": [100, 26, 10],
        "Nagaland": [116, 58, 7],
        "Orissa": [-10, 2, 23],
        "Punjab": [-89, 119, 13],
        "Rajasthan": [-135, 92, 33],
        "Sikkim": [60, 64, 4],
        "Tamil Nadu": [-76, -103, 23],
        "Telangana": [-57, -29, 18],
        "Tripura": [94, 26, 6],
        "Uttar Pradesh": [-55, 92, 28],
        "Uttarakhand": [-47, 101, 11],
        "West Bengal": [31, 55, 22],
        "Jammu and Kashmir": [-99, 175, 28]
    }
    // https://github.com/LLK/scratch-flash/blob/689f3c79a7e8b2e98f5be80056d877f303a8d8ba/src/Scratch.as#L1385
    // console.log(spriteObject)
    if(data[spriteObject.name])
    {
        spriteObject.x = data[spriteObject.name][0]
        spriteObject.y = data[spriteObject.name][1]
        spriteObject.size = data[spriteObject.name][2]
    }
    else
    {
        spriteObject.x = Math.floor((200 * Math.random()) - 100);
        spriteObject.y = Math.floor((100 * Math.random()) - 50);
    }
};

export default randomizeSpritePosition;
