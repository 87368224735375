import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { openAudioVisualMenu, selectCamera, selectMicrophone } from '../../reducers/audioVisual';
import AudioVisualModal from './AudioVisualModal';

const AudioVisualMenu = ({ onRequestOpen, onChangeCamera, onChangeMicrophone }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const handleOpenModal = () => {
        setModalOpen(true);
        onRequestOpen();
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const menuStyles = {
        option: {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            padding: '10px',
            transition: 'background-color 0.3s',
            backgroundColor: isHovered ? 'hsla(0, 0%, 0%, 0.15)' : 'transparent',
        },
        submenuLabel: {
            marginLeft: '10px'
        }
    };

    return (
        <div>
            <div
                style={menuStyles.option}
                onClick={handleOpenModal}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <span style={menuStyles.submenuLabel}>
                    Audio & Visual Settings
                </span>
            </div>
            <AudioVisualModal
                isOpen={modalOpen}
                onRequestClose={handleCloseModal}
                onSelectCamera={onChangeCamera}
                onSelectMicrophone={onChangeMicrophone}
            />
        </div>
    );
};

AudioVisualMenu.propTypes = {
    onRequestOpen: PropTypes.func.isRequired,
    onChangeCamera: PropTypes.func.isRequired,
    onChangeMicrophone: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
    onRequestOpen: () => dispatch(openAudioVisualMenu()),
    onChangeCamera: cameraId => dispatch(selectCamera(cameraId)),
    onChangeMicrophone: microphoneId => dispatch(selectMicrophone(microphoneId))
});

export default connect(
    null,
    mapDispatchToProps
)(AudioVisualMenu);