const BlockType = require('../extension-support/block-type');
const ArgumentType = require('../extension-support/argument-type');
const Cast = require('../util/cast');
const THREE = require('three');

class Scratch3CADBlocks {
    constructor(runtime) {
        this.runtime = runtime;
        this.shapes = []; // Array to keep track of added shapes
        this.animationRunning = false; // Animation is off by default
        this.animationId = null; // ID to track requestAnimationFrame
        this.rotationSpeed = 0.005; // Default rotation speed
        this.stackingEnabled = false; // Stacking is off by default

        // Bind methods to ensure 'this' refers to the instance of Scratch3CADBlocks
        this.animate = this.animate.bind(this);
        this.toggleAnimation = this.toggleAnimation.bind(this);
        this.translateShape = this.translateShape.bind(this);
        this.rotateShape = this.rotateShape.bind(this);
        this.scaleShape = this.scaleShape.bind(this);
        this.setShapeColor = this.setShapeColor.bind(this);
        this.setRotationSpeed = this.setRotationSpeed.bind(this);
        this._addShapeToScene = this._addShapeToScene.bind(this);
        this.toggleStacking = this.toggleStacking.bind(this);
    }

    getInfo() {
        return {
            id: 'cad',
            name: '3D',
            blocks: [
                {
                    opcode: 'toggleStacking',
                    blockType: BlockType.COMMAND,
                    text: 'Toggle Stacking'
                },
                {
                    opcode: 'toggleAnimation',
                    blockType: BlockType.COMMAND,
                    text: 'Toggle Animation'
                },
                {
                    opcode: 'createSphere',
                    blockType: BlockType.COMMAND,
                    text: 'Sphere of Radius [RADIUS] cm',
                    arguments: {
                        RADIUS: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 5
                        }
                    }
                },
                {
                    opcode: 'createCube',
                    blockType: BlockType.COMMAND,
                    text: 'Cube of Side [SIDE] cm',
                    arguments: {
                        SIDE: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 5
                        }
                    }
                },
                {
                    opcode: 'createCuboid',
                    blockType: BlockType.COMMAND,
                    text: 'Cuboid of Width [WIDTH] cm, Height [HEIGHT] cm, Depth [DEPTH] cm',
                    arguments: {
                        WIDTH: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 10
                        },
                        HEIGHT: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 5
                        },
                        DEPTH: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 5
                        }
                    }
                },
                {
                    opcode: 'createCone',
                    blockType: BlockType.COMMAND,
                    text: 'Cone of Radius [RADIUS] cm, Height [HEIGHT] cm',
                    arguments: {
                        RADIUS: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 5
                        },
                        HEIGHT: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 10
                        }
                    }
                },
                {
                    opcode: 'createCylinder',
                    blockType: BlockType.COMMAND,
                    text: 'Cylinder with Radius [RADIUS] cm and Height [HEIGHT] cm',
                    arguments: {
                        RADIUS: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 5
                        },
                        HEIGHT: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 10
                        }
                    }
                },
                {
                    opcode: 'createPyramid',
                    blockType: BlockType.COMMAND,
                    text: 'Pyramid with Base Edge [BASE_EDGE] cm and Height [HEIGHT] cm',
                    arguments: {
                        BASE_EDGE: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 8
                        },
                        HEIGHT: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 10
                        }
                    }
                },
                {
                    opcode: 'createTetrahedron',
                    blockType: BlockType.COMMAND,
                    text: 'Tetrahedron with Edge Length [EDGE] cm',
                    arguments: {
                        EDGE: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 5
                        }
                    }
                },
                {
                    opcode: 'createPlane',
                    blockType: BlockType.COMMAND,
                    text: 'Plane of Size [SIZE] cm',
                    arguments: {
                        SIZE: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 20
                        }
                    }
                },
                {
                    opcode: 'clearAllShapes',
                    blockType: BlockType.COMMAND,
                    text: 'Clear All Shapes'
                }
                ,{
                    opcode: 'deleteShape',
                    blockType: BlockType.COMMAND,
                    text: 'Delete Last Shape'
                },
                {
                    opcode: 'setShapeColor',
                    blockType: BlockType.COMMAND,
                    text: 'Set Shape Color to [COLOR]',
                    arguments: {
                        COLOR: {
                            type: ArgumentType.COLOR
                        }
                    }
                },
                {
                    opcode: 'setRotationSpeed',
                    blockType: BlockType.COMMAND,
                    text: 'Set Rotation Speed to [SPEED]',
                    arguments: {
                        SPEED: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 2
                        }
                    }
                },
                {
                    opcode: 'scaleShape',
                    blockType: BlockType.COMMAND,
                    text: 'Scale Shape by [SCALE] Times',
                    arguments: {
                        SCALE: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 2
                        }
                    }
                },
                {
                    opcode: 'translateShape',
                    blockType: BlockType.COMMAND,
                    text: 'Move Shape to X: [X] Y: [Y] Z: [Z]',
                    arguments: {
                        X: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 5
                        },
                        Y: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 5
                        },
                        Z: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 0
                        }
                    }
                },
                {
                    opcode: 'rotateShape',
                    blockType: BlockType.COMMAND,
                    text: 'Rotate Shape to X: [X] Y: [Y] Z: [Z]',
                    arguments: {
                        X: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 0
                        },
                        Y: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 0
                        },
                        Z: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 0
                        }
                    }
                },
            ]
        };
    }    
    createTetrahedron(args) {
        const edge = Cast.toNumber(args.EDGE);
        this._addShapeToScene(new THREE.TetrahedronGeometry(edge, 0), true);
    }      
    createSphere(args) {
        const radius = Cast.toNumber(args.RADIUS);
        this._addShapeToScene(new THREE.SphereGeometry(radius, 32, 32), true);
    }

    createCube(args) {
        const side = Cast.toNumber(args.SIDE);
        this._addShapeToScene(new THREE.BoxGeometry(side, side, side, 8, 8, 8), true);
    }

    createCuboid(args) {
        const width = Cast.toNumber(args.WIDTH);
        const height = Cast.toNumber(args.HEIGHT);
        const depth = Cast.toNumber(args.DEPTH);
        this._addShapeToScene(new THREE.BoxGeometry(width, height, depth, 8, 8, 8), true);
    }

    createCone(args) {
        const radius = Cast.toNumber(args.RADIUS);
        const height = Cast.toNumber(args.HEIGHT);
        this._addShapeToScene(new THREE.ConeGeometry(radius, height, 32, 32), true);
    }

    createCylinder(args) {
        const radius = Cast.toNumber(args.RADIUS);
        const height = Cast.toNumber(args.HEIGHT);
        this._addShapeToScene(new THREE.CylinderGeometry(radius, radius, height, 32, 8), true);
    }

    createPyramid(args) {
        const baseEdge = Cast.toNumber(args.BASE_EDGE);
        const height = Cast.toNumber(args.HEIGHT);
        this._addShapeToScene(new THREE.CylinderGeometry(0, baseEdge / 2, height, 4, 32), true);
    }

    createPlane(args) {
        const size = Cast.toNumber(args.SIZE);
        this._addShapeToScene(new THREE.PlaneGeometry(size, size, 16, 16), true);
    }

    deleteShape() {
        if (this.shapes.length > 0) {
            const shape = this.shapes.pop();
            if (window.CADscene) {
                window.CADscene.remove(shape);
                if (window.CADrenderer) {
                    window.CADrenderer.render(window.CADscene, window.CADcamera);
                }
            } else {
                console.error('CADscene is not available');
            }
        } else {
            console.log('No shapes to delete');
        }
    }

    toggleAnimation() {
        if (this.animationRunning) {
            this.stopAnimation();
        } else {
            this.startAnimation();
        }
    }

    startAnimation() {
        if (!this.animationRunning) {
            this.animationRunning = true;
            this.animate();
        }
    }

    stopAnimation() {
        if (this.animationRunning) {
            cancelAnimationFrame(this.animationId);
            this.animationRunning = false;
        }
    }
    
    clearAllShapes() {
    if (window.CADscene) {
        while (this.shapes.length > 0) {
            const shape = this.shapes.pop();
            window.CADscene.remove(shape);
        }
        if (window.CADrenderer) {
            window.CADrenderer.render(window.CADscene, window.CADcamera);
        }
    } else {
        console.error('CADscene is not available');
    }
}

    animate() {
        if (this.shapes.length > 0 && window.CADscene && window.CADrenderer && window.CADcamera) {
            this.animationId = requestAnimationFrame(this.animate);

            this.shapes.forEach(shape => {
                shape.rotation.x += this.rotationSpeed;
                shape.rotation.y += this.rotationSpeed;
                shape.rotation.z += this.rotationSpeed;
            });

            window.CADrenderer.render(window.CADscene, window.CADcamera);
        } else {
            this.animationRunning = false;
        }
    }

    scaleShape(args) {
        const scale = Cast.toNumber(args.SCALE);
        if (this.shapes.length > 0) {
            const shape = this.shapes[this.shapes.length - 1];
            shape.scale.set(scale, scale, scale);
            if (window.CADrenderer) {
                window.CADrenderer.render(window.CADscene, window.CADcamera);
            }
        }
    }

    translateShape(args) {
        const x = Cast.toNumber(args.X);
        const y = Cast.toNumber(args.Y);
        const z = Cast.toNumber(args.Z);
        if (this.shapes.length > 0) {
            const shape = this.shapes[this.shapes.length - 1];
            shape.position.set(x, y, z);
            if (window.CADrenderer) {
                window.CADrenderer.render(window.CADscene, window.CADcamera);
            }
        }
    }

    rotateShape(args) {
        const x = Cast.toNumber(args.X);
        const y = Cast.toNumber(args.Y);
        const z = Cast.toNumber(args.Z);
        if (this.shapes.length > 0) {
            const shape = this.shapes[this.shapes.length - 1];
            shape.rotation.set(x, y, z);
            if (window.CADrenderer) {
                window.CADrenderer.render(window.CADscene, window.CADcamera);
            }
        }
    }

    setShapeColor(args) {
        const color = args.COLOR;
        if (this.shapes.length > 0) {
            const shape = this.shapes[this.shapes.length - 1];
            if (shape.material instanceof THREE.MeshBasicMaterial) {
                shape.material.color.set(color);
            }
            if (window.CADrenderer) {
                window.CADrenderer.render(window.CADscene, window.CADcamera);
            }
        }
    }

    setRotationSpeed(args) {
        this.rotationSpeed = Cast.toNumber(args.SPEED) / 200;
    }

    toggleStacking() {
        this.stackingEnabled = !this.stackingEnabled;
    }

    _addShapeToScene(geometry, wireframe) {
        if (window.CADscene) {
            if (!this.stackingEnabled && this.shapes.length > 0) {
                this.deleteShape();
            }

            const color = Math.random() * 0xffffff;
            const material = new THREE.MeshBasicMaterial({ color: color, wireframe: wireframe });

            const shape = new THREE.Mesh(geometry, material);
            shape.position.set(0, 0, 0); // Position at the center
            window.CADscene.add(shape);
            this.shapes.push(shape); // Keep track of the added shape

            if (window.CADrenderer) {
                window.CADrenderer.render(window.CADscene, window.CADcamera);
            }
        } else {
            console.error('CADscene is not available');
        }
    }
}

module.exports = Scratch3CADBlocks;
