// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* #E5F0FF */\n/* #E9F1FC */\n/* #D9E3F2 */\n/* 90% transparent version of motion-primary */\n/* #FFFFFF */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 15% transparent version of black */\n/* #575E75 */\n/* #4C97FF */\n/* #3373CC */\n/*#855CD6 #AD88C6*/\n/* 35% transparent version of looks-tertiary */\n/* 15% transparent version of looks-tertiary */\n/* #714EB6 */\n/* #FF661A */\n/* #E64D00 */\n/* #CF63CF */\n/* #BD42BD */\n/* #FFAB19 */\n/* #FF8C1A */\n/* #0FBD8C */\n/* #0FBD8C */\n/* #0B8E69 */\n/* #FF8C1A */\n/* #FFB366 */\n/* #FF8C1A */\n/* #0FBD8C */\n/* #0B8E69 */\n/* 35% transparent version of extensions-primary */\n/* opaque version of extensions-transparent, on white bg */\n/* lighter than motion-primary */\n/* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */\n/* layout contants from `layout-constants.js` */\nbody {\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n}\nh2 {\n    font-size: 1.5rem;\n    font-weight: bold;\n}\np {\n    font-size: 1rem;\n    line-height: 1.5em;\n}\n.crash-message_crash-wrapper_25B61 {\n    background-color: hsla(215, 100%, 65%, 1);\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n.crash-message_body_1q0lu {\n    width: 35%;\n    color: white;\n    text-align: center;\n}\n/* scratch-www was overriding the colors */\n.crash-message_body_1q0lu p, .crash-message_body_1q0lu h2 {\n    color: inherit;\n}\n.crash-message_reloadButton_FoS7x {\n    border: 1px solid hsla(215, 100%, 65%, 1);\n    border-radius: 0.25rem;\n    padding: 0.5rem 2rem;\n    background: white;\n    color: hsla(215, 100%, 65%, 1);\n    font-weight: bold;\n    font-size: 0.875rem;\n    cursor: pointer;\n}\n", ""]);
// Exports
exports.locals = {
	"crash-wrapper": "crash-message_crash-wrapper_25B61",
	"crashWrapper": "crash-message_crash-wrapper_25B61",
	"body": "crash-message_body_1q0lu",
	"reloadButton": "crash-message_reloadButton_FoS7x"
};
module.exports = exports;
