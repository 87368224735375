// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".signUp_signUpForm_2u-8z {\n    position: fixed;\n    top: 45%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    width: 503px;\n    padding: 20px;\n    background-color: white;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    border-radius: 8px;\n    z-index: 1000; /* Ensure it's above other elements */\n    box-shadow: 2px 3px 5px 6px gray;\n    \n}\n\n.signUp_signUpForm_2u-8z h2 {\n    text-align: center;\n    margin-bottom: 20px;\n    color: black;\n    font-family: Arial, Helvetica, sans-serif;\n}\n\n.signUp_signUpForm_2u-8z form {\n    display: flex;\n    flex-direction: column;\n}\n\n.signUp_signUpForm_2u-8z form div {\n    margin-bottom: 15px;\n    padding-left: 30px;\n}\n\n.signUp_signUpForm_2u-8z form label {\n    margin-bottom: 5px;\n    color: black;\n    font-size: medium;\n    margin-left: -27px;\n}\n\n.signUp_signUpForm_2u-8z form input {\n    padding: 10px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n}\n\n.signUp_signUpForm_2u-8z form button {\n    padding: 10px;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    background-color: #4CAF50;\n    color: white;\n    margin-top: 10px;\n}\n\n.signUp_signUpForm_2u-8z form button[type=\"button\"] {\n    background-color: #f44336;\n}", ""]);
// Exports
exports.locals = {
	"signUpForm": "signUp_signUpForm_2u-8z"
};
module.exports = exports;
