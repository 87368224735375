import React from 'react';
import closeimg from './icon--close.svg'; // Import your close icon image
import qrCodeImg from './qr-code-placeholder.png'; // Placeholder for QR code image

const styles = {
    modalOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    modal: {
        background: 'white',
        borderRadius: '12px',
        width: '800px',
        height: '512px',
        maxWidth: '90%',
        maxHeight: '90%',
        boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    modalHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '15px 20px',
        backgroundColor: '#c09d00',
        color: 'white',
        borderTopLeftRadius: '12px',
        borderTopRightRadius: '12px',
    },
    headerTitle: {
        fontSize: '1.5rem',
        margin: 0,
    },
    closeButtonDiv: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    closeButton: {
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        color: 'white',
        fontSize: '1.5rem',
    },
    modalBody: {
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#E0E0E0',
        textAlign: 'center',
    },
    linkSection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '20px',
    },
    linkInput: {
        width: '70%',
        padding: '12px',
        borderRadius: '8px',
        border: '1px solid #ddd',
        fontSize: '16px',
    },
    copyButton: {
        padding: '10px 20px',
        borderRadius: '8px',
        border: 'none',
        backgroundColor: '#6A4C93',
        color: 'white',
        cursor: 'pointer',
        fontSize: '16px',
        marginLeft: '10px',
    },
    qrSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '20px 0',
    },
    qrCode: {
        width: '150px',
        height: '150px',
    },
    saveQrButton: {
        padding: '10px 20px',
        borderRadius: '8px',
        border: 'none',
        backgroundColor: '#6A4C93',
        color: 'white',
        cursor: 'pointer',
        fontSize: '16px',
        marginTop: '10px',
    },
    note: {
        fontSize: '14px',
        color: '#555',
    },
};

class FileSharingModal extends React.Component {
    render() {
        return (
            <div style={styles.modalOverlay}>
                <div style={styles.modal}>
                    <div style={styles.modalHeader}>
                        <h2 style={styles.headerTitle}>Share Your File with Others!</h2>
                        <div style={styles.closeButtonDiv}>
                            <button style={styles.closeButton} onClick={this.props.onClose}>X
                                {/* <img src={closeimg} alt="Close" /> */}
                            </button>
                        </div>
                    </div>
                    <div style={styles.modalBody}>
                        <p>To share the file, please use the link or QR code provided below:</p>
                        <div style={styles.linkSection}>
                            <input
                                type="text"
                                value="https://Stemverse.page.link/DVc8zaaZ67Zh83xz"
                                readOnly
                                style={styles.linkInput}
                            />
                            <button style={styles.copyButton}>Copy Link!</button>
                        </div>
                        <div style={styles.qrSection}>
                            <p>QR Code:</p>
                            <img src={qrCodeImg} alt="QR Code" style={styles.qrCode} />
                            <button style={styles.saveQrButton}>Save QR</button>
                        </div>
                        <p style={styles.note}>
                            Note: The generated link & QR is a public file and it is valid for 30 days only.
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default FileSharingModal;
