const Avrgirl = require('avrgirl-arduino');
const axios = require('axios');
const Scratch3RoboticArmBlocks = require('../../../../stemblocks-VM/src/extensions/Robotic_Arm/index');
const Scratch3ArduinoUnoBlocks = require('../../../../stemblocks-VM/src/extensions/scratch3_arduino/index.js');
const Scratch3ArduinoRoboticCarBlocks = require('../../../../stemblocks-VM/src/extensions/scratch3_car/index.js');
const Scratch3RoboticSpiderBlocks = require('../../../../stemblocks-VM/src/extensions/Robotic_Spider/index.js');
const codeGeneration = require('../../../../stemblocks-VM/src/extensions/codeGeneration.js');

async function generateAndUploadCode() {
    // Instantiate block classes
    const arduinoBlocks = new Scratch3ArduinoUnoBlocks();
    const armBlocks = new Scratch3RoboticArmBlocks();
    const carBlocks = new Scratch3ArduinoRoboticCarBlocks();
    const spiderBlocks = new Scratch3RoboticSpiderBlocks();

    // Accumulate code from each block class
    // arduinoBlocks.accumulateCode();
    // armBlocks.accumulateCode();
    // carBlocks.accumulateCode();
    // spiderBlocks.accumulateCode();

    // Generate the final combined code
    const finalCode = codeGeneration.generateFinalCode();
    console.log('Generated Arduino Code:', finalCode);

    try {
        // Send code to server for compilation
        const response = await axios.post('http://localhost:3500/iot/burnCode', {
            code: finalCode,
            deviceType: 'arduino',
            board: 'arduino:avr:uno'
        }, {
            responseType: 'arraybuffer'
        });

        const hexBuffer = Buffer.from(response.data);
        console.log("Received compiled hex file from server. Size:", hexBuffer.length, "bytes");

        // Set up avrgirl-arduino
        const avrgirl = new Avrgirl({
            board: 'uno',
            // Add any necessary port configuration
        });

        // Flash the compiled hex to the board
        await new Promise((resolve, reject) => {
            avrgirl.flash(hexBuffer, (error) => {
                if (error) {
                    console.error('Error flashing board:', error);
                    reject(error);
                } else {
                    console.log('Code successfully burned to the board');
                    resolve();
                }
            });
        });
    } catch (error) {
        console.error(`Error burning code: ${error}`);
        throw error; // Re-throw the error to be handled by the caller
    } finally {
        // Reset accumulated code after the process is done
        armBlocks.resetCode();
        arduinoBlocks.resetCode();
        carBlocks.resetCode();
        spiderBlocks.resetCode();
        codeGeneration.resetCode();
        console.log('Code has been reset');
    }
}

module.exports = generateAndUploadCode;
