import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

const styles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000,
    },
    modal: {
        background: '#fff',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        padding: '20px',
        maxWidth: '400px',
        width: '100%',
        textAlign: 'center'
    },
    h2: {
        marginTop: '0',
        fontSize: '38px'
    },
    label: {
        display: 'block',
        margin: '15px 0 5px',
        fontSize: '14px'
    },
    select: {
        width: '100%',
        padding: '10px',
        marginBottom: '20px',
        border: '1px solid #ccc',
        borderRadius: '4px'
    },
    modalButtons: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    button: {
        padding: '10px 20px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        backgroundColor: '#4CAF50',
        color: 'white'
    },
    closeButton: {
        padding: '10px 20px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        backgroundColor: '#f44336',
        color: 'white'
    },
    disconnectButton: {
        padding: '10px 20px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        backgroundColor: '#ff9800',
        color: 'white'
    }
};

const ConnectModal = ({ onClose }) => {
    const [deviceType, setDeviceType] = useState('Serial');
    const [isSearching, setIsSearching] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const [connectedPort, setConnectedPort] = useState(null); // State to track connected port
    const [connectedDevice, setConnectedDevice] = useState(null); // State to track connected device

    useEffect(() => {
        const storedIsConnected = localStorage.getItem('isConnected');
        if (storedIsConnected === null) {
            localStorage.setItem('isConnected', 'false');
            setIsConnected(false);
        } else {
            setIsConnected(storedIsConnected === 'true');
        }
    }, []);

    const handleSearch = async () => {
        setIsSearching(true);
        try {
            if (deviceType === 'Serial') {
                console.log('Requesting USB serial port...');
                const usbFilters = [
                    { usbVendorId: 0x2341 }, // Arduino
                    { usbVendorId: 0x1A86 }, // QinHeng Electronics
                ];
                const port = await navigator.serial.requestPort({ filters: usbFilters });
                console.log('Port selected:', port);
                await port.open({ baudRate: 9600 });
                console.log('Port opened successfully');
                alert('USB serial device connected');

                localStorage.setItem('isConnected', 'true');
                setIsConnected(true);
                setConnectedPort(port);
            } else if (deviceType === 'Bluetooth') {
                console.log('Requesting Bluetooth device...');
                const device = await navigator.bluetooth.requestDevice({
                    acceptAllDevices: true,
                    optionalServices: ['battery_service']
                });
                console.log('Bluetooth device selected:', device);
                const server = await device.gatt.connect();
                console.log('Bluetooth device connected');
                alert('Bluetooth device connected');

                localStorage.setItem('isConnected', 'true');
                setIsConnected(true);
                setConnectedDevice(device);
            }
        } catch (error) {
            console.error('Detailed error:', error);
            alert('Failed to connect: ' + error.message);
        }
        setIsSearching(false);
    };

    const handleDisconnect = async () => {
        try {
            if (connectedPort) {
                await connectedPort.close();
                console.log('Port closed successfully');
                alert('USB serial device disconnected');
                setConnectedPort(null);
            } else if (connectedDevice) {
                await connectedDevice.gatt.disconnect();
                console.log('Bluetooth device disconnected successfully');
                alert('Bluetooth device disconnected');
                setConnectedDevice(null);
            }
            localStorage.setItem('isConnected', 'false');
            setIsConnected(false);
        } catch (error) {
            console.error('Detailed error:', error);
            alert('Failed to disconnect: ' + error.message);
        }
    };

    return (
        <div style={styles.overlay}>
            <div style={styles.modal}>
                <h2 style={styles.h2}>Connect Device</h2>
                <label style={styles.label} htmlFor="deviceType">Device Type:</label>
                <select
                    id="deviceType"
                    value={deviceType}
                    onChange={(e) => setDeviceType(e.target.value)}
                    style={styles.select}
                    disabled={isConnected}
                >
                    <option value="Serial">USB Serial</option>
                    <option value="Bluetooth">Bluetooth</option>
                </select>
                <div style={styles.modalButtons}>
                    <button
                        style={styles.button}
                        onClick={isConnected ? handleDisconnect : handleSearch}
                        disabled={isSearching}
                    >
                        {isConnected ? 'Disconnect' : (isSearching ? 'Searching...' : 'Search and Connect')}
                    </button>
                    <button style={styles.closeButton} onClick={onClose}>Close</button>
                </div>
            </div>
        </div>
    );
};

export const openConnectModal = () => {
    const modalRoot = document.createElement('div');
    document.body.appendChild(modalRoot);

    const handleClose = () => {
        ReactDOM.unmountComponentAtNode(modalRoot);
        document.body.removeChild(modalRoot);
    };

    ReactDOM.render(<ConnectModal onClose={handleClose} />, modalRoot);
};

export default ConnectModal;
