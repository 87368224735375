import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages, injectIntl, intlShape } from 'react-intl';

import LibraryItem from '../../containers/library-item.jsx';
import Modal from '../../containers/modal.jsx';
import Divider from '../divider/divider.jsx';
import Filter from '../filter/filter.jsx';
import TagButton from '../../containers/tag-button.jsx';
import Spinner from '../spinner/spinner.jsx';

import Robotics from './Robotics.jpg';
import ai from './ai.jpg';
import math from './math.jpg';
import geography from './Geography.jpg';
import science from './science.jpg';
import iot from './iot.jpg';
import ar from './ar.jpg';
import printing from './printing.jpg';
import cyber from './cyber.png';
import drons from './drons.jpg';

import styles from './library.css';

const messages = defineMessages({
    filterPlaceholder: {
        id: 'gui.library.filterPlaceholder',
        defaultMessage: 'Search',
        description: 'Placeholder text for library search field'
    },
    allTag: {
        id: 'gui.library.allTag',
        defaultMessage: 'All',
        description: 'Label for library tag to revert to all items after filtering by tag.'
    }
});

const ALL_TAG = { tag: 'all', intlLabel: messages.allTag };
const tagListPrefix = [ALL_TAG];

// const [selectedButton, setSelectedButton] = useState('');

class LibraryComponent extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'handleClose',
            'handleFilterChange',
            'handleFilterClear',
            'handleMouseEnter',
            'handleMouseLeave',
            'handlePlayingEnd',
            'handleSelect',
            'handleTagClick',
            'setFilteredDataRef',
            'handleBackButtonClick',
            'handleButtonMouseEnter', // Add this line
            'handleButtonMouseLeave'
        ]);
        this.state = {
            playingItem: null,
            filterQuery: '',
            selectedTag: ALL_TAG.tag,
            loaded: true,
            buttonsVisible: true,
            showData: false,
            hoveredButton: null, // Add this line
            selectedButton: '' // New state variable
        };
    }

    handleButtonClick(button) {
        this.setState({
            buttonsVisible: false,
            showData: button,
            selectedButton: button
        });
    }

    handleBackButtonClick() { // Add this method
        this.setState({
            buttonsVisible: true,
            showData: false,
            selectedButton: ''
        });
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ loaded: true });
        });
        if (this.props.setStopHandler) this.props.setStopHandler(this.handlePlayingEnd);
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.filterQuery !== this.state.filterQuery ||
            prevState.selectedTag !== this.state.selectedTag) {
            this.scrollToTop();
        }
    }
    handleSelect(extensionId) {
        console.log('Selected Extension ID:', extensionId);
        const selectedItem = this.getFilteredData().find(item => item.extensionId === extensionId);
        console.log('Selected Item:', selectedItem);
        if (selectedItem) {
            this.handleClose();
            this.props.onItemSelected(selectedItem);
        } else {
            console.error('Invalid selection:', extensionId);
        }
    }
    handleClose() {
        this.props.onRequestClose();
    }
    handleTagClick(tag) {
        if (this.state.playingItem === null) {
            this.setState({
                filterQuery: '',
                selectedTag: tag.toLowerCase()
            });
        } else {
            this.props.onItemMouseLeave(this.getFilteredData()[[this.state.playingItem]]);
            this.setState({
                filterQuery: '',
                playingItem: null,
                selectedTag: tag.toLowerCase()
            });
        }
    }
    handleMouseEnter(id) {
        if (this.props.onItemMouseEnter && this.state.playingItem !== id) {
            this.props.onItemMouseEnter(this.getFilteredData()[id]);
            this.setState({
                playingItem: id
            });
        }
    }
    handleMouseLeave(id) {
        if (this.props.onItemMouseLeave) {
            this.props.onItemMouseLeave(this.getFilteredData()[id]);
            this.setState({
                playingItem: null
            });
        }
    }
    handlePlayingEnd() {
        if (this.state.playingItem !== null) {
            this.setState({
                playingItem: null
            });
        }
    }
    handleFilterChange(event) {
        if (this.state.playingItem === null) {
            this.setState({
                filterQuery: event.target.value,
                selectedTag: ALL_TAG.tag
            });
        } else {
            this.props.onItemMouseLeave(this.getFilteredData()[[this.state.playingItem]]);
            this.setState({
                filterQuery: event.target.value,
                playingItem: null,
                selectedTag: ALL_TAG.tag
            });
        }
    }
    handleButtonMouseEnter(button) {
        this.setState({ hoveredButton: button });
    }

    handleButtonMouseLeave() {
        this.setState({ hoveredButton: null });
    }
    handleFilterClear() {
        this.setState({ filterQuery: '' });
    }


    getFilteredData() {
        if (!this.state.showData) return [];
        if (this.state.selectedTag === 'all') {
            if (!this.state.filterQuery) return this.props.data;
            let res = this.props.data.filter(dataItem => (
                (dataItem.tags || [])
                    .map(String.prototype.toLowerCase.call, String.prototype.toLowerCase)
                    .concat(dataItem.name ?
                        (typeof dataItem.name === 'string' ?
                            dataItem.name : this.props.intl.formatMessage(dataItem.name.props)
                        ).toLowerCase() :
                        null)
                    .join('\n')
                    .indexOf(this.state.filterQuery.toLowerCase()) !== -1
            ));
            console.log(res);
            return res;
        }
        return this.props.data.filter(dataItem => (
            dataItem.tags &&
            dataItem.tags
                .map(String.prototype.toLowerCase.call, String.prototype.toLowerCase)
                .indexOf(this.state.selectedTag) !== -1
        ));
    }
    scrollToTop() {
        this.filteredDataRef.scrollTop = 0;
    }
    setFilteredDataRef(ref) {
        this.filteredDataRef = ref;
    }
    render() {
        return (
            <Modal
                fullScreen
                contentLabel={this.props.title}
                id={this.props.id}
                onRequestClose={this.handleClose}

            >
                <div className={styles.modalContent}>
                    {!this.state.buttonsVisible && (
                        <button
                            className={styles.backButton} // Add CSS class for styling
                            onClick={this.handleBackButtonClick}

                        >
                            Back
                        </button>
                    )}
                    {this.state.buttonsVisible && (
                        <>
                            <button
                                style={{
                                    margin: "20px",
                                    borderColor: this.state.hoveredButton === 'Robotics' ? 'white' : '#3295a8',
                                    backgroundColor: "#3295a8"
                                }}
                                onClick={() => this.handleButtonClick('Robotics')}
                                onMouseEnter={() => this.handleButtonMouseEnter('Robotics')}
                                onMouseLeave={this.handleButtonMouseLeave}
                            >
                                <img src={Robotics} style={{ width: "300px", height: "200px" }} />
                                <p style={{ color: "white", fontSize: "18px", font: "arial sans" }}>Robotics</p>
                            </button>


                            <button
                                style={{
                                    margin: "20px",
                                    borderColor: this.state.hoveredButton === 'AI | ML' ? 'white' : '#3295a8',
                                    backgroundColor: "#3295a8"
                                }}
                                onClick={() => this.handleButtonClick('AI | ML')}
                                onMouseEnter={() => this.handleButtonMouseEnter('AI | ML')}
                                onMouseLeave={this.handleButtonMouseLeave}
                            >                            <img src={ai} style={{ width: "300px", height: "200px" }} />
                                <p style={{ color: "white", fontSize: "18px", font: "arial sans" }}>AI | ML</p>
                            </button>

                            <button
                                style={{
                                    margin: "20px",
                                    borderColor: this.state.hoveredButton === 'Mathematics' ? 'white' : '#3295a8',
                                    backgroundColor: "#3295a8"
                                }}
                                onClick={() => this.handleButtonClick('Mathematics')}
                                onMouseEnter={() => this.handleButtonMouseEnter('Mathematics')}
                                onMouseLeave={this.handleButtonMouseLeave}
                            >                            <img src={math} style={{ width: "300px", height: "200px" }} />
                                <p style={{ color: "white", fontSize: "18px", font: "arial sans" }}>Mathematics</p>
                            </button>

                            <button
                                style={{
                                    margin: "20px",
                                    borderColor: this.state.hoveredButton === 'Geography' ? 'white' : '#3295a8',
                                    backgroundColor: "#3295a8"
                                }}
                                onClick={() => this.handleButtonClick('Geography')}
                                onMouseEnter={() => this.handleButtonMouseEnter('Geography')}
                                onMouseLeave={this.handleButtonMouseLeave}
                            >                            <img src={geography} style={{ width: "300px", height: "200px" }} />
                                <p style={{ color: "white", fontSize: "18px", font: "arial sans" }}>Geography</p>
                            </button>
                            <button
                                style={{
                                    margin: "20px",
                                    borderColor: this.state.hoveredButton === 'Science' ? 'white' : '#3295a8',
                                    backgroundColor: "#3295a8"
                                }}
                                onClick={() => this.handleButtonClick('Science')}
                                onMouseEnter={() => this.handleButtonMouseEnter('Science')}
                                onMouseLeave={this.handleButtonMouseLeave}
                            >                            <img src={science} style={{ width: "300px", height: "200px" }} />
                                <p style={{ color: "white", fontSize: "18px", font: "arial sans" }}>Science</p>
                            </button>

                            <button
                                style={{
                                    margin: "20px",
                                    borderColor: this.state.hoveredButton === 'IoT | Electronics' ? 'white' : '#3295a8',
                                    backgroundColor: "#3295a8"
                                }}
                                onClick={() => this.handleButtonClick('IoT | Electronics')}
                                onMouseEnter={() => this.handleButtonMouseEnter('IoT | Electronics')}
                                onMouseLeave={this.handleButtonMouseLeave}
                            >                            <img src={iot} style={{ width: "300px", height: "200px" }} />
                                <p style={{ color: "white", fontSize: "18px", font: "arial sans" }}>IoT | Electronics</p>
                            </button>

                            <button
                                style={{
                                    margin: "20px",
                                    borderColor: this.state.hoveredButton === 'AR | VR' ? 'white' : '#3295a8',
                                    backgroundColor: "#3295a8"
                                }}
                                onClick={() => this.handleButtonClick('AR | VR')}
                                onMouseEnter={() => this.handleButtonMouseEnter('AR | VR')}
                                onMouseLeave={this.handleButtonMouseLeave}
                            >                            <img src={ar} style={{ width: "300px", height: "200px" }} />
                                <p style={{ color: "white", fontSize: "18px", font: "arial sans" }}>AR | VR</p>
                            </button>

                            <button
                                style={{
                                    margin: "20px",
                                    borderColor: this.state.hoveredButton === '3D Printings' ? 'white' : '#3295a8',
                                    backgroundColor: "#3295a8"
                                }}
                                onClick={() => this.handleButtonClick('3D Printings')}
                                onMouseEnter={() => this.handleButtonMouseEnter('3D Printings')}
                                onMouseLeave={this.handleButtonMouseLeave}
                            >                            <img src={printing} style={{ width: "300px", height: "200px" }} />
                                <p style={{ color: "white", fontSize: "18px", font: "arial sans" }}>3D Printings</p>
                            </button>

                            <button
                                style={{
                                    margin: "20px",
                                    borderColor: this.state.hoveredButton === 'Cybersecurity' ? 'white' : '#3295a8',
                                    backgroundColor: "#3295a8"
                                }}
                                onClick={() => this.handleButtonClick('Cybersecurity')}
                                onMouseEnter={() => this.handleButtonMouseEnter('Cybersecurity')}
                                onMouseLeave={this.handleButtonMouseLeave}
                            >                            <img src={cyber} style={{ width: "300px", height: "200px" }} />
                                <p style={{ color: "white", fontSize: "18px", font: "arial sans" }}>Cybersecurity</p>
                            </button>

                            <button
                                style={{
                                    margin: "20px",
                                    borderColor: this.state.hoveredButton === 'Drones' ? 'white' : '#3295a8',
                                    backgroundColor: "#3295a8"
                                }}
                                onClick={() => this.handleButtonClick('Drones')}
                                onMouseEnter={() => this.handleButtonMouseEnter('Drones')}
                                onMouseLeave={this.handleButtonMouseLeave}
                            >                            <img src={drons} style={{ width: "300px", height: "200px" }} />
                                <p style={{ color: "white", fontSize: "18px", font: "arial sans" }}>Drones</p>
                            </button>
                        </>
                    )}

                    {(this.props.filterable || this.props.tags) && (
                        <div className={styles.filterBar}>
                            {this.props.filterable && (
                                <Filter
                                    className={classNames(
                                        styles.filterBarItem,
                                        styles.filter
                                    )}
                                    filterQuery={this.state.filterQuery}
                                    inputClassName={styles.filterInput}
                                    placeholderText={this.props.intl.formatMessage(messages.filterPlaceholder)}
                                    onChange={this.handleFilterChange}
                                    onClear={this.handleFilterClear}
                                />
                            )}
                            {this.props.filterable && this.props.tags && (
                                <Divider className={classNames(styles.filterBarItem, styles.divider)} />
                            )}
                            {this.props.tags && (
                                <div className={styles.tagWrapper}>
                                    {tagListPrefix.concat(this.props.tags).map((tagProps, id) => (
                                        <TagButton
                                            active={this.state.selectedTag === tagProps.tag.toLowerCase()}
                                            className={classNames(
                                                styles.filterBarItem,
                                                styles.tagButton,
                                                tagProps.className
                                            )}
                                            key={`tag-button-${id}`}
                                            onClick={() => this.handleTagClick(tagProps.tag)}
                                            {...tagProps}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                    {!this.state.buttonsVisible && (
                        <div
                            className={classNames(styles.libraryScrollGrid, {
                                [styles.withFilterBar]: this.props.filterable || this.props.tags
                            })}
                            ref={this.setFilteredDataRef}
                        >
                            {this.state.loaded ? this.getFilteredData()
                                .filter(dataItem => this.state.selectedButton === '' || dataItem?.category?.toLowerCase() === this.state.selectedButton.toLowerCase()) // Add this line
                                .map((dataItem, index) => (
                                    <LibraryItem
                                        bluetoothRequired={dataItem.bluetoothRequired}
                                        collaborator={dataItem.collaborator}
                                        description={dataItem.description}
                                        disabled={dataItem.disabled}
                                        extensionId={dataItem.extensionId}
                                        featured={dataItem.featured}
                                        hidden={dataItem.hidden}
                                        iconMd5={dataItem.costumes ? dataItem.costumes[0].md5ext : dataItem.md5ext}
                                        iconRawURL={dataItem.rawURL}
                                        icons={dataItem.costumes}
                                        id={dataItem.extensionId}
                                        tags={dataItem.tags}
                                        insetIconURL={dataItem.insetIconURL}
                                        internetConnectionRequired={dataItem.internetConnectionRequired}
                                        isPlaying={this.state.playingItem === index}
                                        key={typeof dataItem.name === 'string' ? dataItem.name : dataItem.rawURL}
                                        name={dataItem.name}
                                        showPlayButton={this.props.showPlayButton}
                                        onMouseEnter={this.handleMouseEnter}
                                        onMouseLeave={this.handleMouseLeave}
                                        onSelect={this.handleSelect}
                                    />
                                )) : (
                                <div className={styles.spinnerWrapper}>
                                    <Spinner
                                        large
                                        level="primary"
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </Modal>
        );
    }
}

LibraryComponent.propTypes = {
    data: PropTypes.arrayOf(
        /* eslint-disable react/no-unused-prop-types, lines-around-comment */
        // An item in the library
        PropTypes.shape({
            // @todo remove md5/rawURL prop from library, refactor to use storage
            md5: PropTypes.string,
            name: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.node
            ]),
            rawURL: PropTypes.string
        })
        /* eslint-enable react/no-unused-prop-types, lines-around-comment */
    ),
    filterable: PropTypes.bool,
    id: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
    onItemMouseEnter: PropTypes.func,
    onItemMouseLeave: PropTypes.func,
    onItemSelected: PropTypes.func,
    onRequestClose: PropTypes.func,
    setStopHandler: PropTypes.func,
    showPlayButton: PropTypes.bool,
    tags: PropTypes.arrayOf(PropTypes.shape(TagButton.propTypes)),
    title: PropTypes.string.isRequired
};

LibraryComponent.defaultProps = {
    filterable: true,
    showPlayButton: true
};

export default injectIntl(LibraryComponent);