const BlockType = require('../../extension-support/block-type');
const ArgumentType = require('../../extension-support/argument-type');
const Scratch3LooksBlocks = require('../../blocks/scratch3_looks');
const languageNames = require('scratch-translate-extension-languages');
const formatMessage = require('format-message');
const Cast = require('../../util/cast');
const log = require('../../util/log');
const {fetchWithTimeout} = require('../../util/fetch-with-timeout');
const nlp = require('compromise');


const serverURL = 'https://translate-service.scratch.mit.edu/';

const serverTimeoutMs = 10000; 


// const ScratchRender = require('scratch-render'); // Or the correct path from documentation
const menuIconURI = 'https://imgs.search.brave.com/O-qQeVgnU9McXQgrblY8q5AR1kWh81lp7-2xDVD1Hlc/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9pY29u/cy52ZXJ5aWNvbi5j/b20vcG5nLzEyOC9t/aXNjZWxsYW5lb3Vz/L2JsdWUtbGluZWFy/LWljb24tMS9ubHAt/MS5wbmc'
const blockIconURI = 'https://imgs.search.brave.com/2pJkUQ4YsMPrDhYUdEhCYtHm3RD2tVnaBdnL_u6AQk4/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9pY29u/cy52ZXJ5aWNvbi5j/b20vcG5nLzEyOC9m/aWxlLXR5cGUvY29t/cG9uZW50L2xpZ2h0/LWNvbXBvbmVudC1u/bHAucG5n'

class Scratch3ShowImageExtension {
    constructor(runtime) {
        this.runtime = runtime;
        this._viewerLanguageCode = this.getViewerLanguageCode();

        /**
         * List of supported language name and language code pairs, for use in the block menu.
         * Filled in by getInfo so it is updated when the interface language changes.
         * @type {Array.<object.<string, string>>}
         * @private
         */
        this._supportedLanguages = [];

        /**
         * A randomly selected language code, for use as the default value in the language menu.
         * Properly filled in getInfo so it is updated when the interface languages changes.
         * @type {string}
         * @private
         */
        this._randomLanguageCode = 'en';


        /**
         * The result from the most recent translation.
         * @type {string}
         * @private
         */
        this._translateResult = '';

        /**
         * The language of the text most recently translated.
         * @type {string}
         * @private
         */
        this._lastLangTranslated = '';

        /**
         * The text most recently translated.
         * @type {string}
         * @private
         */
        this._lastTextTranslated = '';
    }

    getInfo () {
        this._supportedLanguages = this._getSupportedLanguages(this.getViewerLanguageCode());
        this._randomLanguageCode = this._supportedLanguages[
            Math.floor(Math.random() * this._supportedLanguages.length)].value;


        return {
            id: 'NLP',
            name: formatMessage({
                id: 'NLP.categoryName',
                default: 'NLP',
                description: 'Name of extension that adds NLP blocks'
            }),
            blockIconURI: blockIconURI,
            menuIconURI: menuIconURI,
            color1: '#ffA500',
            color2: '#ffA500',
            blocks: [
                {
                    opcode: 'getTranslate',
                    text: formatMessage({
                        id: 'NLP.translateBlock',
                        default: 'translate [WORDS] to [LANGUAGE]',
                        description: 'translate some text to a different language'
                    }),
                    blockType: BlockType.REPORTER,
                    arguments: {
                        WORDS: {
                            type: ArgumentType.STRING,
                            defaultValue: formatMessage({
                                id: 'NLP.defaultTextToTranslate',
                                default: 'hello',
                                description: 'hello: the default text to translate'
                            })
                        },
                        LANGUAGE: {
                            type: ArgumentType.STRING,
                            menu: 'languages',
                            defaultValue: this._randomLanguageCode
                        }
                    }
                },
                {
                    opcode: 'lexicalAnalysis',
                    text: formatMessage({
                        id: 'NLP.lexicalBlock',
                        default: 'Lexical Analysis Text: [TEXT]',
                        description: 'lexical analysis of text'
                    }),
                    blockType: BlockType.REPORTER,
                    arguments: {
                        TEXT: {
                            type: ArgumentType.STRING,
                            defaultValue: formatMessage({
                                id: 'translate.defaultTextToTranslate',
                                default: 'Dogs and Cats',
                                description: 'How are you?: the default text to translate'
                            })
                        }
                    }
                },
                {
                    opcode: 'sentimentAnalysis',
                    text: formatMessage({
                        id: 'NLP.sentimentBlock',
                        default: 'Sentiment Analysis Text: [TEXT]',
                        description: 'Sentiment analysis of text'
                    }),
                    blockType: BlockType.REPORTER,
                    arguments: {
                        TEXT: {
                            type: ArgumentType.STRING,
                            defaultValue: formatMessage({
                                id: 'sentiment.defaultTextToAnalyze',
                                default: 'I love programming!',
                                description: 'The default text to analyze for sentiment'
                            })
                        }
                    }
                },
                {
                    opcode: 'semanticSimilarityAnalysis',
                    text: 'Semantic similarity analysis text1: [TEXT1] text2: [TEXT2]',
                    blockType: BlockType.COMMAND,
                    arguments: {
                        TEXT1: {
                            type: ArgumentType.STRING,
                            defaultValue: 'semantic analysis'
                        },
                        TEXT2: {
                            type: ArgumentType.STRING,
                            defaultValue: 'sentiment analysis'
                        }
                    }
                },
                {
                    opcode: 'semanticSimilarityResult',
                    text: 'Semantic similarity analysis result',
                    blockType: BlockType.REPORTER
                }
            ],
            menus: {
                languages: {
                    acceptReporters: true,
                    items: this._supportedLanguages
                }
            }
        };
    }

    lexicalAnalysis(args){
        const userText = args.TEXT;
        const doc = nlp(userText);
        console.log(doc['document'])

    // Initialize an array to store the analysis results
        const analysis = {};

        // Iterate over each word in the document
        doc.document[0].forEach(element => {
            const word = element.text; // Extract the word text
            const posTags = [...element.tags]; // Convert the Set of tags to an array
    
            // If there are POS tags, take the first one
            if (posTags.length > 0) {
                const pos = posTags[0];
                if(posTags[1] == 'Pronoun')
                {
                    analysis[word] = posTags[1]
                }
                else{
                    analysis[word] = pos;
                }
            } else {
                analysis[word] = 'unknown';
            }
        });    
// Return the analysis
        console.log(analysis)
        return JSON.stringify(analysis);    

    }
    sentimentAnalysis(args) {
        const userText = args.TEXT.toLowerCase();
        let sentimentScore = 0; // Initialize sentimentScore
    
        // Define simple positive and negative word lists
        const positiveWords = ['good', 'happy', 'love', 'excellent', 'awesome','like','Joyful', 'Brilliant', 'Excellent', 'Happy', 'Wonderful', 'Fantastic', 'Amazing', 'Peaceful', 'Grateful', 'Optimistic', 'Loving', 'Kind', 'Cheerful', 'Successful', 'Generous', 'Delightful', 'Confident', 'Vibrant', 'Inspiring', 'Enthusiastic', 'Courageous', 'Harmonious', 'Thrilled', 'Resilient', 'Blissful'];
        const negativeWords = ['bad', 'sad', 'hate', 'terrible', 'awful','Sad', 'Angry', 'Miserable', 'Frustrated', 'Anxious', 'Hopeless', 'Boring', 'Depressed', 'Fearful', 'Annoying', 'Hateful', 'Jealous', 'Bitter', 'Disappointed', 'Lonely', 'Tired', 'Guilty', 'Upset', 'Discouraged', 'Hurt', 'Unhappy', 'Terrified', 'Irritated', 'Overwhelmed', 'Desperate'];
    
        // Tokenize the user text and check against word lists
        userText.split(' ').forEach(word => {
            if (positiveWords.includes(word)) {
                sentimentScore++; // Increment sentimentScore for positive words
            } else if (negativeWords.includes(word)) {
                sentimentScore--; // Decrement sentimentScore for negative words
            }
        });
    
        // Determine the sentiment based on the sentimentScore
        let sentiment;
        if (sentimentScore > 0) {
            sentiment = 'positive';
        } else if (sentimentScore < 0) {
            sentiment = 'negative';
        } else {
            sentiment = 'neutral';
        }
    
        // Return the sentiment result
        console.log(`Sentiment: ${sentiment}`);
        return sentiment;
    }   
    semanticSimilarityAnalysis(args) {
        try {
            const text1 = args.TEXT1;
            const text2 = args.TEXT2;

            if (!text1 || !text2) {
                console.error('One or both text inputs are missing.');
                return;
            }

            // Call the function using 'this' to access it within the same object
            const similarityScore = this.calculateSemanticSimilarity(text1, text2);

            // Convert the similarity score to a percentage
            const similarityPercentage = similarityScore * 100;

            // Save the result for later retrieval
            this.lastSimilarityResult = similarityPercentage;

            console.log(`Semantic similarity between "${text1}" and "${text2}" is: ${similarityPercentage}%`);

            // No return value; this block only sets the result
        } catch (error) {
            console.error('Error during semantic similarity analysis:', error);
            this.lastSimilarityResult = 'Error';
        }
    }

    // Block to retrieve the result of the last semantic similarity analysis
    semanticSimilarityResult() {
        try {
            if (typeof this.lastSimilarityResult === 'undefined' || this.lastSimilarityResult === null) {
                console.warn('No analysis performed yet.');
                return 'No analysis performed yet';
            }
            return `${this.lastSimilarityResult}%`;
        } catch (error) {
            console.error('Error retrieving similarity result:', error);
            return 'Error: ' + error.message;
        }
    }

    // Helper function to calculate semantic similarity
    calculateSemanticSimilarity(text1, text2) {
        try {
            // Example of calculating similarity using Jaccard index
            const set1 = new Set(text1.split(' '));
            const set2 = new Set(text2.split(' '));

            const intersection = new Set([...set1].filter(x => set2.has(x)));
            const union = new Set([...set1, ...set2]);

            const similarity = intersection.size / union.size;

            console.log(`Calculated similarity: ${similarity}`);

            return similarity; // This returns a value between 0 and 1
        } catch (error) {
            console.error('Error in calculateSemanticSimilarity function:', error);
            return 'Error: ' + error.message;
        }
    }
    _getSupportedLanguages (code) {
        return languageNames.menuMap[code].map(entry => {
            const obj = {text: entry.name, value: entry.code};
            return obj;
        });
    }

    getViewerLanguage () {
        this._viewerLanguageCode = this.getViewerLanguageCode();
        const names = languageNames.menuMap[this._viewerLanguageCode];
        let langNameObj = names.find(obj => obj.code === this._viewerLanguageCode);

        // If we don't have a name entry yet, try looking it up via the Google langauge
        // code instead of Scratch's (e.g. for es-419 we look up es to get espanol)
        if (!langNameObj && languageNames.scratchToGoogleMap[this._viewerLanguageCode]) {
            const lookupCode = languageNames.scratchToGoogleMap[this._viewerLanguageCode];
            langNameObj = names.find(obj => obj.code === lookupCode);
        }

        let langName = this._viewerLanguageCode;
        if (langNameObj) {
            langName = langNameObj.name;
        }
        return langName;
    }

    getViewerLanguageCode () {
        const locale = formatMessage.setup().locale;
        const viewerLanguages = [locale].concat(navigator.languages);
        const languageKeys = Object.keys(languageNames.menuMap);
        // Return the first entry in viewerLanguages that matches
        // one of the available language keys.
        const languageCode = viewerLanguages.reduce((acc, lang) => {
            if (acc) {
                return acc;
            }
            if (languageKeys.indexOf(lang.toLowerCase()) > -1) {
                return lang;
            }
            return acc;
        }, '') || 'en';
    
        return languageCode.toLowerCase();
    }

    getLanguageCodeFromArg (arg) {
        const languageArg = Cast.toString(arg).toLowerCase();
        // Check if the arg matches a language code in the menu.
        if (Object.prototype.hasOwnProperty.call(languageNames.menuMap, languageArg)) {
            return languageArg;
        }
        // Check for a dropped-in language name, and convert to a language code.
        if (Object.prototype.hasOwnProperty.call(languageNames.nameMap, languageArg)) {
            return languageNames.nameMap[languageArg];
        }

        // There are some languages we launched in the language menu that Scratch did not
        // end up launching in. In order to keep projects that may have had that menu item
        // working, check for those language codes and let them through.
        // Examples: 'ab', 'hi'.
        if (languageNames.previouslySupported.indexOf(languageArg) !== -1) {
            return languageArg;
        }
        // Default to English.
        return 'en';
    }

    getTranslate (args) {
        // If the text contains only digits 0-9 and nothing else, return it without
        // making a request.
        if (/^\d+$/.test(args.WORDS)) return Promise.resolve(args.WORDS);

        // Don't remake the request if we already have the value.
        if (this._lastTextTranslated === args.WORDS &&
            this._lastLangTranslated === args.LANGUAGE) {
            return this._translateResult;
        }

        const lang = this.getLanguageCodeFromArg(args.LANGUAGE);

        let urlBase = `${serverURL}translate?language=`;
        urlBase += lang;
        urlBase += '&text=';
        urlBase += encodeURIComponent(args.WORDS);

        const tempThis = this;
        const translatePromise = fetchWithTimeout(urlBase, {}, serverTimeoutMs)
            .then(response => response.text())
            .then(responseText => {
                const translated = JSON.parse(responseText).result;
                tempThis._translateResult = translated;
                // Cache what we just translated so we don't keep making the
                // same call over and over.
                tempThis._lastTextTranslated = args.WORDS;
                tempThis._lastLangTranslated = args.LANGUAGE;
                console.log(translated);
                return translated;
            })
            .catch(err => {
                log.warn(`error fetching translate result! ${err}`);
                return '';
            });
        return translatePromise;
    }
    
    
}



module.exports = Scratch3ShowImageExtension;