import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import VM from 'scratch-vm';

import Box from '../box/box.jsx';
import { STAGE_DISPLAY_SIZES } from '../../lib/layout-constants.js';
import StageHeader from '../../containers/stage-header.jsx';
import Stage from '../../containers/stage.jsx';
import Loader from '../loader/loader.jsx';
import SimulationComponent from '../simulation/simulation-component.jsx';

import Video from '../../../../stemblocks-VM/src/io/video.js';
import styles from './stage-wrapper.css';

// Assuming a simple video provider implementation
class SimpleVideoProvider {
    constructor() {
        this.canvas = document.createElement('canvas');
        this.context = this.canvas.getContext('2d');
        this.videoElement = document.createElement('video');
        this.videoElement.autoplay = true;
    }

    enableVideo() {
        return navigator.mediaDevices.getUserMedia({ video: true })
            .then(stream => {
                this.videoElement.srcObject = stream;
                this.stream = stream;
                return stream;
            });
    }

    disableVideo() {
        if (this.stream) {
            this.stream.getTracks().forEach(track => track.stop());
            this.stream = null;
        }
    }

    getStream() {
        return this.stream;
    }

    getFrame() {
        if (this.videoElement.readyState === this.videoElement.HAVE_ENOUGH_DATA) {
            this.canvas.width = this.videoElement.videoWidth;
            this.canvas.height = this.videoElement.videoHeight;
            this.context.drawImage(this.videoElement, 0, 0, this.canvas.width, this.canvas.height);
            return this.context.getImageData(0, 0, this.canvas.width, this.canvas.height);
        }
        return null;
    }
}

const StageWrapperComponent = function (props) {
    const {
        isFullScreen,
        isRtl,
        isRendererSupported,
        loading,
        stageSize,
        vm,
        onToggleSimulation,
        isSimulationOpen
    } = props;

    const [isCameraOpen, setIsCameraOpen] = useState(false);

    const videoRef = useRef(null);
    const isInitialRender = useRef(true); // Tracks if it's the first render
    const canvasRef = useRef(null);

    useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false; // Skip the first render
            return;
        }

        localStorage.setItem('isCameraOpen', JSON.stringify(isCameraOpen));

        const handleCameraToggle = async () => {
            if (isCameraOpen) {
                try {
                    await vm.runtime.ioDevices.video.enableVideo();
                    if (canvasRef.current) {
                        const frameData = await vm.runtime.ioDevices.video.getFrame({
                            format: 'canvas'
                        });

                        // Draw frame data to canvas
                        const context = canvasRef.current.getContext('2d');
                        if (frameData) {
                            const img = new Image();
                            img.src = URL.createObjectURL(new Blob([frameData]));
                            img.onload = () => {
                                context.drawImage(img, 0, 0);
                                URL.revokeObjectURL(img.src);
                            };
                        }
                    }
                } catch (err) {
                    console.error("Error enabling video: ", err);
                    setIsCameraOpen(false);
                    localStorage.setItem('isCameraOpen', JSON.stringify(false));
                }
            } else {
                vm.runtime.ioDevices.video.disableVideo();
                if (canvasRef.current) {
                    const context = canvasRef.current.getContext('2d');
                    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                }
            }
        };

        handleCameraToggle();
    }, [isCameraOpen, vm.runtime.ioDevices.video]);

    const handleToggleCamera = () => {
        const newState = !isCameraOpen;
        setIsCameraOpen(newState);
        localStorage.setItem('isCameraOpen', JSON.stringify(newState));
    };

    const handleCloseCamera = () => {
        vm.runtime.ioDevices.video.disableVideo();
        if (canvasRef.current) {
            const context = canvasRef.current.getContext('2d');
            context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        }
        setIsCameraOpen(false);
        localStorage.setItem('isCameraOpen', JSON.stringify(false));
    };

    useEffect(() => {
        window.toggleCamera = handleToggleCamera;
        window.closeCamera = handleCloseCamera;
        window.toggleSimulation = onToggleSimulation;
        window.closeSimulation = () => onToggleSimulation(false);

        // Disable camera on component mount
        handleCloseCamera();

        return () => {
            delete window.toggleCamera;
            delete window.closeCamera;
        };
    }, []);

    return (
        <Box
            className={classNames(
                styles.stageWrapper,
                { [styles.fullScreen]: isFullScreen }
            )}
            dir={isRtl ? 'rtl' : 'ltr'}
        >
            <Box className={styles.stageMenuWrapper}>
                <StageHeader
                    stageSize={stageSize}
                    vm={vm}
                    onToggleCamera={handleToggleCamera}
                    isCameraOpen={isCameraOpen}
                    onToggleSimulation={onToggleSimulation}
                    isSimulationOpen={isSimulationOpen}
                />
                <img
                    onClick={handleToggleCamera}
                    className={styles.cameraIcon}
                    alt="Toggle Camera"
                />
            </Box>
            <Box className={styles.stageCanvasWrapper}>
                {isRendererSupported ? <Stage stageSize={stageSize} vm={vm} /> : null}
                {isCameraOpen && (
                    <canvas
                        ref={canvasRef}
                        className={styles.cameraFeed}
                        width={480}
                        height={360}
                    />
                )}
            </Box>
            {isSimulationOpen && (
                <div className= {styles.simulationContainer}>
                    <SimulationComponent />
                </div>
            )}
            {loading ? (
                <Loader isFullScreen={isFullScreen} />
            ) : null}
        </Box>
    );
};

StageWrapperComponent.propTypes = {
    isFullScreen: PropTypes.bool,
    isRendererSupported: PropTypes.bool.isRequired,
    isRtl: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    stageSize: PropTypes.oneOf(Object.keys(STAGE_DISPLAY_SIZES)).isRequired,
    vm: PropTypes.instanceOf(VM).isRequired,
    onToggleSimulation: PropTypes.func.isRequired,
    isSimulationOpen:PropTypes.bool.isRequired
};

export default StageWrapperComponent;
