// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* #E5F0FF */\n/* #E9F1FC */\n/* #D9E3F2 */\n/* 90% transparent version of motion-primary */\n/* #FFFFFF */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 15% transparent version of black */\n/* #575E75 */\n/* #4C97FF */\n/* #3373CC */\n/*#855CD6 #AD88C6*/\n/* 35% transparent version of looks-tertiary */\n/* 15% transparent version of looks-tertiary */\n/* #714EB6 */\n/* #FF661A */\n/* #E64D00 */\n/* #CF63CF */\n/* #BD42BD */\n/* #FFAB19 */\n/* #FF8C1A */\n/* #0FBD8C */\n/* #0FBD8C */\n/* #0B8E69 */\n/* #FF8C1A */\n/* #FFB366 */\n/* #FF8C1A */\n/* #0FBD8C */\n/* #0B8E69 */\n/* 35% transparent version of extensions-primary */\n/* opaque version of extensions-transparent, on white bg */\n/* lighter than motion-primary */\n.icon-button_container_278u5 {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    cursor: pointer;\n    font-size: 0.75rem;\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    color: #3295a8;\n    border-radius: 0.5rem;\n}\n.icon-button_container_278u5 + .icon-button_container_278u5 {\n    margin-top: 1.25rem;\n}\n.icon-button_title_36ChS {\n    margin-top: 0.5rem;\n    text-align: center;\n}\n.icon-button_disabled_2HD_J {\n    opacity: 0.5;\n    pointer-events: none;\n}\n.icon-button_container_278u5:active {\n    background-color: hsla(260, 60%, 60%, 0.15);\n}\n", ""]);
// Exports
exports.locals = {
	"container": "icon-button_container_278u5",
	"title": "icon-button_title_36ChS",
	"disabled": "icon-button_disabled_2HD_J"
};
module.exports = exports;
