import React, { useEffect, useState } from 'react';

const SimulationComponent = () => {
    const [unityInstance, setUnityInstance] = useState(null);
    const [isUnityInputEnabled, setIsUnityInputEnabled] = useState(false);

    useEffect(() => {
        // Global variable to control Unity input capture

        const container = document.querySelector("#unity-container");
        const canvas = document.querySelector("#unity-canvas");
        const loadingBar = document.querySelector("#unity-loading-bar");
        const progressBarFull = document.querySelector("#unity-progress-bar-full");
        const fullscreenButton = document.querySelector("#unity-fullscreen-button");

        const script = document.createElement("script");
        script.src = "/Build/car_sim.loader.js";
        script.onload = () => {
            createUnityInstance(canvas, {
                dataUrl: "/Build/car_sim.data/webgl.data",
                frameworkUrl: "/Build/car_sim.framework.js/build.framework.js",
                codeUrl: "/Build/car_sim.wasm/build.wasm",
                streamingAssetsUrl: "StreamingAssets",
                companyName: "Stem World",
                productName: "Car Simulation",
                productVersion: "0.1",
            }, (progress) => {
                progressBarFull.style.width = 100 * progress + "%";
            }).then((instance) => {
                setUnityInstance(instance);
                loadingBar.style.display = "none";
                fullscreenButton.onclick = () => {
                    instance.SetFullscreen(1);
                };

                let isForward = false;
                let isBackward = false;
                let isLeft = false;
                let isRight = false;

                const startSendingInput = () => {
                    if (instance) {
                        if (isForward) instance.SendMessage('Car', 'ReceiveInput', 'forward');
                        if (isBackward) instance.SendMessage('Car', 'ReceiveInput', 'backward');
                        if (isLeft) instance.SendMessage('Car', 'ReceiveInput', 'left');
                        if (isRight) instance.SendMessage('Car', 'ReceiveInput', 'right');
                    }
                };

                window.startSendingInput = startSendingInput;

                const inputInterval = setInterval(startSendingInput, 100);

                window.moveForward = () => { isForward = true; };
                window.stopForward = () => { isForward = false; };
                window.moveBackward = () => { isBackward = true; };
                window.stopBackward = () => { isBackward = false; };
                window.turnLeft = () => { isLeft = true; };
                window.stopLeft = () => { isLeft = false; };
                window.turnRight = () => { isRight = true; };
                window.stopRight = () => { isRight = false; };

                const buttons = document.querySelectorAll("#controls button");
                buttons[0].addEventListener('mousedown', window.moveForward);
                buttons[0].addEventListener('mouseup', window.stopForward);
                buttons[1].addEventListener('mousedown', window.moveBackward);
                buttons[1].addEventListener('mouseup', window.stopBackward);
                buttons[2].addEventListener('mousedown', window.turnLeft);
                buttons[2].addEventListener('mouseup', window.stopLeft);
                buttons[3].addEventListener('mousedown', window.turnRight);
                buttons[3].addEventListener('mouseup', window.stopRight);

                return () => {
                    clearInterval(inputInterval);
                    document.body.removeChild(script);
                };
            }).catch((message) => {
                alert(message);
            });
        };

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div id="unity-container" className="unity-desktop" style={{ height: '100%' }}>
            <canvas id="unity-canvas" width="960" height="600" tabIndex="-1"></canvas>
            <div id="unity-loading-bar">
                <div id="unity-logo"></div>
                <div id="unity-progress-bar-empty">
                    <div id="unity-progress-bar-full"></div>
                </div>
            </div>
            <div id="unity-warning"></div>
            <div id="unity-footer">
                <div id="unity-webgl-logo"></div>
                <div id="unity-fullscreen-button"></div>
                <div id="unity-build-title">Car Simulation</div>
            </div>
            <div id="controls">
                <button>Forward</button>
                <button>Backward</button>
                <button>Left</button>
                <button>Right</button>
            </div>
            {/* <button onClick={toggleUnityInputCapture}>
                {isUnityInputEnabled ? 'Disable' : 'Enable'} Unity Input
            </button> */}
        </div>
    );
};

export default SimulationComponent;