// const ArgumentType = require('../../extension-support/argument-type');
// const BlockType = require('../../extension-support/block-type');
// const formatMessage = require('format-message');
// const axios = require('axios');

// const HEADER_TEMPLATE = `
// #include <Wire.h>
// #include <Adafruit_PWMServoDriver.h>

// #define PCA9685_ADDR 0x40 // I2C address of PCA9685 module
// Adafruit_PWMServoDriver pwm = Adafruit_PWMServoDriver(PCA9685_ADDR);

// #define SERVOMIN_puls 170
// #define SERVOMAX_puls 600
// #define SERVOMIN_angle 5
// #define SERVOMAX_angle 180

// const int coksa1_s= 0;
// const int coksa2_s= 4;
// const int coksa3_s= 8;
// const int coksa4_s= 12;

// const int tibia1_s= 1;
// const int tibia2_s= 5;
// const int tibia3_s= 9;
// const int tibia4_s= 13;

// const int femur1_s= 2;
// const int femur2_s= 6;
// const int femur3_s= 10;
// const int femur4_s= 14;

// uint16_t angleToPulse(int angle) {
//   return map(angle, SERVOMIN_angle, SERVOMAX_angle, SERVOMIN_puls, SERVOMAX_puls);
// }`;
// const SETUP_TEMPLATE = `void setup() { Serial.begin(9600);
//   pwm.begin();
//   pwm.setPWMFreq(60);
//   delay(10);
//   loop();
//   delay(1000); }\n`;
// const LOOP_TEMPLATE = `void loop() {\n{loopCode}\n}\n`;
// const DEFINE_TEMPLATE = `{defineCode}\n`;

// let setupCode = '';
// let loopCode = '';
// let defineCode = '';
// let headerCode = '';
// let initializedPins = new Set();

// class Scratch3ArduinoUnoBlocks {
//     constructor(runtime) {
//         this.runtime = runtime;
//     }
 
//     getInfo() {
//         return {
//             id: 'spider',
//             name: formatMessage({
//                 id: 'spider.categoryName',
//                 default: 'Robo-Spider',
//                 description: 'Arduino Uno extension category'
//             }),
//             blocks: [
//                 {
//                     opcode: 'homePosition',
//                     blockType: BlockType.COMMAND,
//                     text: formatMessage({
//                         id: 'arduinouno.homePosition',
//                         default: 'Move Spider to home position',
//                         description: 'Move servos to home position'
//                     })
//                 },
//                 {
//                     opcode: 'moveDown',
//                     blockType: BlockType.COMMAND,
//                     text: formatMessage({
//                         id: 'arduinouno.moveDown',
//                         default: 'Move Spider to down position',
//                         description: 'Move servos to down position'
//                     })
//                 },
//                 {
//                     opcode: 'moveDownExtreme',
//                     blockType: BlockType.COMMAND,
//                     text: formatMessage({
//                         id: 'arduinouno.moveDownExtreme',
//                         default: 'Move Spder to extreme down position',
//                         description: 'Move servos to extreme down position'
//                     })
//                 },
//                 {
//                     opcode: 'moveUp',
//                     blockType: BlockType.COMMAND,
//                     text: formatMessage({
//                         id: 'arduinouno.moveUp',
//                         default: 'Move Spider to up position',
//                         description: 'Move servos to up position'
//                     })
//                 },
//                 {
//                     opcode: 'twist',
//                     blockType: BlockType.COMMAND,
//                     text: formatMessage({
//                         id: 'arduinouno.twist',
//                         default: 'Twist Spider',
//                         description: 'Twist servos'
//                     })
//                 }
//             ],
//             menus: {}
//         };
//     }

//     accumulateCode() {
//         return `
// ${HEADER_TEMPLATE}
// ${DEFINE_TEMPLATE.replace('{defineCode}', defineCode)}
// ${SETUP_TEMPLATE.replace('{setupCode}', setupCode)}
// ${LOOP_TEMPLATE.replace('{loopCode}', loopCode)}
//         `;
//     }

//     resetTemplates() {
//         setupCode = '';
//         loopCode = '';
//         defineCode = '';
//         initializedPins = new Set();
//     }

//     async generateAndUploadCode() {
//         const finalCode = this.accumulateCode();

//         console.log('Generated Arduino Code:', finalCode);

//         try {
//             const response = await axios.post('http://localhost:3500/iot/burnCode', {
//                 code: finalCode,
//                 deviceType: 'arduino',
//                 board: 'arduino:avr:uno'
//             });

//             console.log('Code successfully burned to the board');
//         } catch (error) {
//             console.error(`Error burning code: ${error}`);
//         }

//         // Reset templates after burning code
//         this.resetTemplates();
//     }

//     homePosition() {
       
//     loopCode +=` pwm.setPWM(coksa1_s, 0,angleToPulse(90)); 
//             pwm.setPWM(coksa2_s, 0,angleToPulse(90)); 
//             pwm.setPWM(coksa3_s, 0,angleToPulse(90)); 
//             pwm.setPWM(coksa4_s, 0,angleToPulse(90)); 
            
//             pwm.setPWM(tibia1_s, 0,angleToPulse(90));
//             pwm.setPWM(tibia2_s, 0,angleToPulse(90));
//             pwm.setPWM(tibia3_s, 0,angleToPulse(90));
//             pwm.setPWM(tibia4_s, 0,angleToPulse(90));
            
//             pwm.setPWM(femur1_s, 0,angleToPulse(90));
//             pwm.setPWM(femur2_s, 0,angleToPulse(90));
//             pwm.setPWM(femur3_s, 0,angleToPulse(90));
//             pwm.setPWM(femur4_s, 0,angleToPulse(90));
//             `;
//     }

//     moveDown() {
//         loopCode += `pwm.setPWM(tibia1_s, 0,angleToPulse(40));
//         pwm.setPWM(femur1_s, 0,angleToPulse(40));
//         pwm.setPWM(tibia2_s, 0,angleToPulse(40));
//         pwm.setPWM(femur2_s, 0,angleToPulse(40));
//         pwm.setPWM(tibia3_s, 0,angleToPulse(40));
//         pwm.setPWM(femur3_s, 0,angleToPulse(40));
//         pwm.setPWM(tibia4_s, 0,angleToPulse(40));
//         pwm.setPWM(femur4_s, 0,angleToPulse(40));`;
//     }

//     moveDownExtreme() {
//         loopCode += `pwm.setPWM(tibia3_s, 0,angleToPulse(10));
//         pwm.setPWM(femur3_s, 0,angleToPulse(0));
//         pwm.setPWM(tibia1_s, 0,angleToPulse(10));
//         pwm.setPWM(femur1_s, 0,angleToPulse(0));
//         pwm.setPWM(tibia2_s, 0,angleToPulse(10));
//         pwm.setPWM(femur2_s, 0,angleToPulse(0));
//         pwm.setPWM(tibia4_s, 0,angleToPulse(10));
//         pwm.setPWM(femur4_s, 0,angleToPulse(0));`;
//     }

//     moveUp() {
//         loopCode += `
//   pwm.setPWM(tibia1_s, 0,angleToPulse(120));
//   pwm.setPWM(tibia2_s, 0,angleToPulse(120));
//   pwm.setPWM(tibia3_s, 0,angleToPulse(120));
//   pwm.setPWM(tibia4_s, 0,angleToPulse(120));
// `;
//     }

//     twist() {
//         loopCode += `pwm.setPWM(coksa1_s, 0,angleToPulse(40));
//         pwm.setPWM(coksa2_s, 0,angleToPulse(40));
//         pwm.setPWM(coksa3_s, 0,angleToPulse(40));
//         pwm.setPWM(coksa4_s, 0,angleToPulse(40));
//         delay(500);
//         pwm.setPWM(coksa1_s, 0,angleToPulse(140));
//         pwm.setPWM(coksa2_s, 0,angleToPulse(140));
//         pwm.setPWM(coksa3_s, 0,angleToPulse(140));
//         pwm.setPWM(coksa4_s, 0,angleToPulse(140));
//         delay(500);`;
//     }

//     codeSetup() {
//         headerCode += `
// #include <Wire.h>
// #include <Adafruit_PWMServoDriver.h>

// #define PCA9685_ADDR 0x40 // I2C address of PCA9685 module
// Adafruit_PWMServoDriver pwm = Adafruit_PWMServoDriver(PCA9685_ADDR);

// #define SERVOMIN_puls 170
// #define SERVOMAX_puls 600
// #define SERVOMIN_angle 5
// #define SERVOMAX_angle 180

// const int coksa1_s= 0;
// const int coksa2_s= 4;
// const int coksa3_s= 8;
// const int coksa4_s= 12;

// const int tibia1_s= 1;
// const int tibia2_s= 5;
// const int tibia3_s= 9;
// const int tibia4_s= 13;

// const int femur1_s= 2;
// const int femur2_s= 6;
// const int femur3_s= 10;
// const int femur4_s= 14;

// uint16_t angleToPulse(int angle) {
//   return map(angle, SERVOMIN_angle, SERVOMAX_angle, SERVOMIN_puls, SERVOMAX_puls);
// }

// void home_pos(){
//   pwm.setPWM(coksa1_s, 0,angleToPulse(90)); 
//   pwm.setPWM(coksa2_s, 0,angleToPulse(90)); 
//   pwm.setPWM(coksa3_s, 0,angleToPulse(90)); 
//   pwm.setPWM(coksa4_s, 0,angleToPulse(90)); 
  
//   pwm.setPWM(tibia1_s, 0,angleToPulse(90));
//   pwm.setPWM(tibia2_s, 0,angleToPulse(90));
//   pwm.setPWM(tibia3_s, 0,angleToPulse(90));
//   pwm.setPWM(tibia4_s, 0,angleToPulse(90));
  
//   pwm.setPWM(femur1_s, 0,angleToPulse(90));
//   pwm.setPWM(femur2_s, 0,angleToPulse(90));
//   pwm.setPWM(femur3_s, 0,angleToPulse(90));
//   pwm.setPWM(femur4_s, 0,angleToPulse(90));
// }

// void down(){
//   pwm.setPWM(tibia1_s, 0,angleToPulse(40));
//   pwm.setPWM(femur1_s, 0,angleToPulse(40));
//   pwm.setPWM(tibia2_s, 0,angleToPulse(40));
//   pwm.setPWM(femur2_s, 0,angleToPulse(40));
//   pwm.setPWM(tibia3_s, 0,angleToPulse(40));
//   pwm.setPWM(femur3_s, 0,angleToPulse(40));
//   pwm.setPWM(tibia4_s, 0,angleToPulse(40));
//   pwm.setPWM(femur4_s, 0,angleToPulse(40));
// }

// void down_extrm(){
//   pwm.setPWM(tibia3_s, 0,angleToPulse(10));
//   pwm.setPWM(femur3_s, 0,angleToPulse(0));
//   pwm.setPWM(tibia1_s, 0,angleToPulse(10));
//   pwm.setPWM(femur1_s, 0,angleToPulse(0));
//   pwm.setPWM(tibia2_s, 0,angleToPulse(10));
//   pwm.setPWM(femur2_s, 0,angleToPulse(0));
//   pwm.setPWM(tibia4_s, 0,angleToPulse(10));
//   pwm.setPWM(femur4_s, 0,angleToPulse(0));
// }

// void up(){
//   pwm.setPWM(tibia1_s, 0,angleToPulse(120));
//   pwm.setPWM(tibia2_s, 0,angleToPulse(120));
//   pwm.setPWM(tibia3_s, 0,angleToPulse(120));
//   pwm.setPWM(tibia4_s, 0,angleToPulse(120));
// }

// void twist(){
//   pwm.setPWM(coksa1_s, 0,angleToPulse(40));
//   pwm.setPWM(coksa2_s, 0,angleToPulse(40));
//   pwm.setPWM(coksa3_s, 0,angleToPulse(40));
//   pwm.setPWM(coksa4_s, 0,angleToPulse(40));
//   delay(500);
//   pwm.setPWM(coksa1_s, 0,angleToPulse(140));
//   pwm.setPWM(coksa2_s, 0,angleToPulse(140));
//   pwm.setPWM(coksa3_s, 0,angleToPulse(140));
//   pwm.setPWM(coksa4_s, 0,angleToPulse(140));
//   delay(500);
//   home_pos();
// }`;
//         setupCode += `
// pwm.begin();
// pwm.setPWMFreq(50); // Set the PWM frequency to 50 Hz
// home_pos(); // Initialize servos to home position
//         `;
//         loopCode += `void loop() {\n// Empty loop as actions are called directly\n}\n`;
//     }
// }

// module.exports = Scratch3ArduinoUnoBlocks;

const ArgumentType = require('../../extension-support/argument-type');
const BlockType = require('../../extension-support/block-type');
const formatMessage = require('format-message');
const codeGeneration = require('../codeGeneration');
let setupCode = '';
let loopCode = '';
let defineCode = '';
let bodycode = '';
let codeContext = 'setup';
const definedFunctions = new Set();
const definedServos = new Set();

class Scratch3RoboticSpiderBlocks {
  constructor(runtime) {
    this.runtime = runtime;
    codeContext = localStorage.getItem('codeContext');
  }

  getInfo() {
    return {
      id: 'roboticspider',
      name: formatMessage({
        id: 'roboticspider.categoryName',
        default: 'Robotic Spider',
        description: 'Extension to control a robotic spider based on Arduino.'
      }),
      blocks: [
        {
          opcode: 'setServo',
          blockType: BlockType.COMMAND,
          text: formatMessage({
            id: 'roboticspider.setServo',
            default: 'Set servo [servo] to pin number [pinNo]',
            description: 'Attach a servo to the specified pin.'
          }),
          arguments: {
            servo: {
              type: ArgumentType.STRING,
              menu: 'servoMenu',
              defaultValue: 'servo1'
            },
            pinNo: {
              type: ArgumentType.STRING,
              menu: 'pinMenu',
              defaultValue: '2'
            }
          },
          func: 'setServo'
        },
        // Add other blocks as needed
      ],
      menus: {
        servoMenu: {
          items: Array.from({ length: 12 }, (_, i) => `servo${i + 1}`)
        },
        pinMenu: {
          items: ['2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13']
        }
      }
    };
  }


  resetCode() {
    setupCode = '';
    loopCode = '';
    defineCode = '';
    bodycode = '';
    definedServos.clear();
    definedFunctions.clear();
    codeGeneration.resetCode();
    console.log('Code has been reset.');
  }

  accumulateCode() {
    codeGeneration.accumulateCode(defineCode, bodycode, setupCode, loopCode);
  }

  setServo(args) {
    const servo = args.servo;
    const pinNo = args.pinNo;
    const servoVar = `servo_${servo.split('servo')[1]}`;

    // Add the servo variable initialization to the define code if not already present
    if (!definedServos.has(servoVar)) {
      defineCode += `Servo ${servoVar};\n`;
      definedServos.add(servoVar);
    }

    // Add the servo attachment to the setup code
    setupCode += `${servoVar}.attach(${pinNo});\n`;
  }
}

module.exports = Scratch3RoboticSpiderBlocks;
