import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

const AudioVisualModal = ({ isOpen, onRequestClose, onSelectCamera, onSelectMicrophone }) => {
    const [devices, setDevices] = useState({ cameras: [], microphones: [] });
    const [selectedCamera, setSelectedCamera] = useState('default');
    const [selectedMicrophone, setSelectedMicrophone] = useState('default');
    const [mediaStream, setMediaStream] = useState(null);
    const [audioContext, setAudioContext] = useState(null);
    const [analyser, setAnalyser] = useState(null);
    const [permissionDenied, setPermissionDenied] = useState(false);
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const animationFrameId = useRef(null);

    useEffect(() => {
        const getDevices = async () => {
            try {
                const deviceInfos = await navigator.mediaDevices.enumerateDevices();
                const cameras = deviceInfos.filter(device => device.kind === 'videoinput');
                const microphones = deviceInfos.filter(device => device.kind === 'audioinput');
                setDevices({ cameras, microphones });
            } catch (error) {
                setPermissionDenied(true);
            }
        };

        if (isOpen) {
            getDevices();
        }

        return () => {
            if (animationFrameId.current) {
                cancelAnimationFrame(animationFrameId.current);
            }
            if (audioContext && audioContext.state !== 'closed') {
                audioContext.close().catch(e => console.error('Error closing AudioContext:', e));
            }
            stopMediaStream(mediaStream);
        };
    }, [isOpen]);

    useEffect(() => {
        const updateMediaStream = async () => {
            if (!isOpen) {
                return;
            }

            try {
                if (mediaStream) {
                    stopMediaStream(mediaStream);
                }

                const constraints = {
                    video: selectedCamera !== 'default' ? { deviceId: { exact: selectedCamera } } : true,
                    audio: selectedMicrophone !== 'default' ? { deviceId: { exact: selectedMicrophone } } : true,
                };

                const stream = await navigator.mediaDevices.getUserMedia(constraints);
                setMediaStream(stream);

                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                }

                if (audioContext && audioContext.state === 'closed') {
                    const newAudioContext = new (window.AudioContext || window.webkitAudioContext)();
                    setAudioContext(newAudioContext);
                }

                const localAudioContext = audioContext || new (window.AudioContext || window.webkitAudioContext)();
                setAudioContext(localAudioContext);
                const analyserNode = localAudioContext.createAnalyser();
                analyserNode.fftSize = 2048;
                setAnalyser(analyserNode);

                const source = localAudioContext.createMediaStreamSource(stream);
                source.connect(analyserNode);

                drawWaveform(analyserNode);
            } catch (error) {
                setPermissionDenied(true);
            }
        };

        updateMediaStream();
    }, [selectedCamera, selectedMicrophone, isOpen]);

    const drawWaveform = (analyserNode) => {
        const canvas = canvasRef.current;
        const canvasCtx = canvas.getContext('2d');
        const bufferLength = analyserNode.fftSize;
        const dataArray = new Uint8Array(bufferLength);

        const draw = () => {
            analyserNode.getByteTimeDomainData(dataArray);
            canvasCtx.fillStyle = 'rgb(200, 200, 200)';
            canvasCtx.fillRect(0, 0, canvas.width, canvas.height);

            canvasCtx.lineWidth = 2;
            canvasCtx.strokeStyle = 'rgb(0, 0, 0)';
            canvasCtx.beginPath();

            const sliceWidth = canvas.width * 1.0 / bufferLength;
            let x = 0;

            for (let i = 0; i < bufferLength; i++) {
                const v = dataArray[i] / 128.0;
                const y = v * canvas.height / 2;

                if (i === 0) {
                    canvasCtx.moveTo(x, y);
                } else {
                    canvasCtx.lineTo(x, y);
                }

                x += sliceWidth;
            }

            canvasCtx.lineTo(canvas.width, canvas.height / 2);
            canvasCtx.stroke();

            animationFrameId.current = requestAnimationFrame(draw);
        };

        draw();
    };

    const stopMediaStream = (stream) => {
        if (stream) {
            stream.getTracks().forEach(track => track.stop());
            setMediaStream(null);
        }
    };

    const handleCloseModal = () => {
        if (mediaStream) {
            stopMediaStream(mediaStream);
        }
        if (audioContext && audioContext.state !== 'closed') {
            audioContext.close().catch(e => console.error('Error closing AudioContext:', e));
        }
        setAudioContext(null);
        setAnalyser(null);
        if (animationFrameId.current) {
            cancelAnimationFrame(animationFrameId.current);
        }
        onRequestClose();
    };

    const handleCameraSelect = (event) => {
        const selectedCameraId = event.target.value;
        setSelectedCamera(selectedCameraId);
        onSelectCamera(selectedCameraId);
    };

    const handleMicrophoneSelect = (event) => {
        const selectedMicrophoneId = event.target.value;
        setSelectedMicrophone(selectedMicrophoneId);
        onSelectMicrophone(selectedMicrophoneId);
    };

    if (!isOpen) return null;

    const modalContent = permissionDenied ? (
        <div style={modalContentStyle}>
            <div style={modalHeaderStyle}>
                <div style={titleContainerStyle}>
                    <h2 style={modalTitleStyle}>Audio/Visual Settings</h2>
                </div>
                <button style={closeButtonStyle} onClick={handleCloseModal}>&times;</button>
            </div>
            <div style={permissionDeniedMessageStyle}>
                <p>Permission to access media devices was denied. Please allow access and try again.</p>
            </div>
        </div>
    ) : (
        <div style={modalContentStyle}>
            <div style={modalHeaderStyle}>
                <div style={titleContainerStyle}>
                    <h2 style={modalTitleStyle}>Audio/Visual Settings</h2>
                </div>
                <button style={closeButtonStyle} onClick={handleCloseModal}>&times;</button>
            </div>
            <div style={deviceListStyle}>
                <h3>Cameras</h3>
                <select style={{ ...selectStyle, ...selectHoverFocusStyle }} value={selectedCamera} onChange={handleCameraSelect}>
                    <option value="default">Default Camera</option>
                    {devices.cameras.map(camera => (
                        <option key={camera.deviceId} value={camera.deviceId}>
                            {camera.label || `Camera ${camera.deviceId}`}
                        </option>
                    ))}
                </select>
            </div>
            <div style={videoContainerStyle}>
                <video ref={videoRef} style={videoStyle} autoPlay playsInline muted />
            </div>
            <div style={deviceListStyle}>
                <h3>Microphones</h3>
                <select style={{ ...selectStyle, ...selectHoverFocusStyle }} value={selectedMicrophone} onChange={handleMicrophoneSelect}>
                    <option value="default">Default Microphone</option>
                    {devices.microphones.map(microphone => (
                        <option key={microphone.deviceId} value={microphone.deviceId}>
                            {microphone.label || `Microphone ${microphone.deviceId}`}
                        </option>
                    ))}
                </select>
            </div>
            <div style={canvasContainerStyle}>
                <canvas ref={canvasRef} style={canvasStyle} />
            </div>
        </div>
    );

    return (
        <div style={overlayStyle}>
            {modalContent}
        </div>
    );
};

AudioVisualModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    onSelectCamera: PropTypes.func.isRequired,
    onSelectMicrophone: PropTypes.func.isRequired
};

const overlayStyle = {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000
};

const modalContentStyle = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    width: '90%',
    maxWidth: '500px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    position: 'relative'
};

const modalHeaderStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px'
};

const titleContainerStyle = {
    backgroundColor: 'purple',
    padding: '10px',
    borderRadius: '4px',
};

const modalTitleStyle = {
    fontSize: '1.5em',
    margin: 0,
    color: 'white',
};

const closeButtonStyle = {
    backgroundColor: 'red',
    color: 'white',
    border: 'none',
    fontSize: '1.5em',
    cursor: 'pointer',
    width: '40px',
    height: '40px',
    borderRadius: '10px',
    textAlign: 'center',
    lineHeight: '40px'
};

const permissionDeniedMessageStyle = {
    textAlign: 'center',
    margin: '20px 0'
};

const deviceListStyle = {
    marginBottom: '20px'
};

const selectStyle = {
    width: '100%',
    padding: '10px',
    margin: '5px 0',
    backgroundColor: '#ccc',
    border: 'none',
    borderRadius: '4px',
    textAlign: 'left',
    cursor: 'pointer',
    fontSize: '1em',
    outline: 'none',
    appearance: 'none',
    backgroundImage: 'linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc)',
    backgroundPosition: 'calc(100% - 20px) calc(100% - 15px), calc(100% - 15px) calc(100% - 15px), 0 0',
    backgroundSize: '5px 5px, 5px 5px, 100%',
    backgroundRepeat: 'no-repeat'
};

const selectHoverFocusStyle = {
    ':hover': {
        backgroundColor: '#b3b3b3'
    },
    ':focus': {
        backgroundColor: '#b3b3b3'
    }
};

const videoContainerStyle = {
    width: '100%',
    paddingTop: '56.25%', // 16:9 aspect ratio
    position: 'relative',
    marginBottom: '20px'
};

const videoStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover'
};

const canvasContainerStyle = {
    width: '100%',
    position: 'relative',
    marginBottom: '20px'
};

const canvasStyle = {
    width: '100%',
    height: '150px',
    backgroundColor: '#f1f1f1',
    border: '1px solid #ddd'
};

export default AudioVisualModal;
