const BlockType = require('../../extension-support/block-type');
const ArgumentType = require('../../extension-support/argument-type');
const Scratch3LooksBlocks = require('../../blocks/scratch3_looks');

class Scratch3SpeechRecognitionExtension {
    constructor(runtime) {
        this.runtime = runtime;
        this.recognition = null;
        this.lastResult = '';
        this.noiseThreshold = 30; // Default noise threshold
        this.autoClear = false; // Auto-clearing feature
        this.clearAfterSeconds = Infinity; // Time after which results are cleared
        this.clearAfterPause = Infinity; // Pause duration after which results are cleared
        this.clearTimer = null;
    }

    getInfo() {
        return {
            id: 'speechRecognition',
            name: 'Speech Recognition',
            blocks: [
                {
                    opcode: 'recognizeSpeech',
                    blockType: BlockType.COMMAND,
                    text: 'recognize speech for [SECONDS] s in [LANGUAGE]',
                    arguments: {
                        SECONDS: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 2
                        },
                        LANGUAGE: {
                            type: ArgumentType.STRING,
                            defaultValue: 'English (UK)',
                            menu: 'languagesMenu'
                        }
                    }
                },
                {
                    opcode: 'getSpeechRecognitionResult',
                    blockType: BlockType.REPORTER,
                    text: 'speech recognition result'
                },
                {
                    opcode: 'setNoiseThreshold',
                    blockType: BlockType.COMMAND,
                    text: 'set noise removal threshold to [PERCENT] %',
                    arguments: {
                        PERCENT: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 30
                        }
                    }
                },
                {
                    opcode: 'turnOnAutoClearing',
                    blockType: BlockType.COMMAND,
                    text: 'turn [STATE] auto clearing',
                    arguments: {
                        STATE: {
                            type: ArgumentType.STRING,
                            defaultValue: 'on',
                            menu: 'onOffMenu'
                        }
                    }
                },
                {
                    opcode: 'clearResultsAfterSeconds',
                    blockType: BlockType.COMMAND,
                    text: 'clear results after [SECONDS] seconds of speech',
                    arguments: {
                        SECONDS: {
                            type: ArgumentType.NUMBER,
                            defaultValue: Infinity
                        }
                    }
                },
                {
                    opcode: 'clearResultsAfterPause',
                    blockType: BlockType.COMMAND,
                    text: 'clear results after a pause of [SECONDS] seconds',
                    arguments: {
                        SECONDS: {
                            type: ArgumentType.NUMBER,
                            defaultValue: Infinity
                        }
                    }
                },
                {
                    opcode: 'setLanguage',
                    blockType: BlockType.COMMAND,
                    text: 'set language to [LANGUAGE]',
                    arguments: {
                        LANGUAGE: {
                            type: ArgumentType.STRING,
                            defaultValue: 'English (UK)',
                            menu: 'languagesMenu'
                        }
                    }
                },
                {
                    opcode: 'startListening',
                    blockType: BlockType.COMMAND,
                    text: 'start listening'
                },
                {
                    opcode: 'stopListening',
                    blockType: BlockType.COMMAND,
                    text: 'stop listening'
                },
                {
                    opcode: 'clearSpeechRecognitionResult',
                    blockType: BlockType.COMMAND,
                    text: 'clear speech recognition result'
                }
            ],
            menus: {
                languagesMenu: {
                    acceptReporters: true,
                    items: [  'Arabic',
                        'Catalan',
                        'Danish',
                        'German',
                        'English (UK)',
                        'English (US)',
                        'Spanish',
                        'Finnish',
                        'French',
                        'Gujarati',
                        'Hindi',
                        'Italian',
                        'Japanese',
                        'Korean',
                        'Marathi',
                        'Norwegian',
                        'Dutch',
                        'Polish',
                        'Portuguese',
                        'Russian',
                        'Swedish',
                        'Tamil',
                        'Telugu',
                        'Thai',
                        'Turkish',
                        'Chinese (Mandarin)'
                    ]
                },
                onOffMenu: {
                    acceptReporters: true,
                    items: ['on', 'off']
                }
            }
        };
    }

    recognizeSpeech(args, util) {
        const seconds = args.SECONDS;
        const language = args.LANGUAGE;
        const languageCodes = {
            'Arabic': 'ar-SA',
            'Catalan': 'ca-ES',
            'Danish': 'da-DK',
            'German': 'de-DE',
            'English (UK)': 'en-GB',
            'English (US)': 'en-US',
            'Spanish': 'es-ES',
            'Finnish': 'fi-FI',
            'French': 'fr-FR',
            'Gujarati': 'gu-IN',
            'Hindi': 'hi-IN',
            'Italian': 'it-IT',
            'Japanese': 'ja-JP',
            'Korean': 'ko-KR',
            'Marathi': 'mr-IN',
            'Norwegian': 'nb-NO',
            'Dutch': 'nl-NL',
            'Polish': 'pl-PL',
            'Portuguese': 'pt-PT',
            'Russian': 'ru-RU',
            'Swedish': 'sv-SE',
            'Tamil': 'ta-IN',
            'Telugu': 'te-IN',
            'Thai': 'th-TH',
            'Turkish': 'tr-TR',
            'Chinese (Mandarin)': 'zh-CN'
        };
        

        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

        if (SpeechRecognition) {
            this.recognition = new SpeechRecognition();

            this.recognition.continuous = false;
            this.recognition.interimResults = false;
            this.recognition.lang = languageCodes[language] || 'en-US';
            console.log(this.recognition.lang)

            this.recognition.onresult = (event) => {
                const transcript = event.results[0][0].transcript;
                this.lastResult = transcript;
                console.log(`You said: ${transcript}`);

                if (this.autoClear && this.clearTimer) {
                    clearTimeout(this.clearTimer);
                    this.clearTimer = null;
                }

                if (this.clearAfterSeconds !== Infinity) {
                    this.clearTimer = setTimeout(() => {
                        this.clearRecognitionResult();
                    }, this.clearAfterSeconds * 1000);
                }
            };

            this.recognition.onerror = (event) => {
                console.error(`Speech recognition error: ${event.error}`);
            };

            this.recognition.onend = () => {
                console.log('Speech recognition ended');

                if (this.autoClear && this.clearAfterPause !== Infinity) {
                    this.clearTimer = setTimeout(() => {
                        this.clearRecognitionResult();
                    }, this.clearAfterPause * 1000);
                }
            };

            const startRecognition = (stream) => {
                this.recognition.start();
                setTimeout(() => {
                    this.recognition.stop();
                    if (stream) {
                        stream.getTracks().forEach(track => track.stop());
                    }
                }, seconds * 1000);
            };

            navigator.permissions.query({ name: 'microphone' }).then((result) => {
                if (result.state === 'granted') {
                    startRecognition(null);
                } else if (result.state === 'prompt') {
                    navigator.mediaDevices.getUserMedia({ audio: true })
                        .then((stream) => {
                            startRecognition(stream);
                        })
                        .catch((err) => {
                            console.error('Microphone permission denied:', err);
                        });
                } else if (result.state === 'denied') {
                    console.error('Microphone permission denied. Please enable it in your browser settings.');
                }
            }).catch((err) => {
                console.error('Error checking microphone permission:', err);
            });

        } else {
            console.log('Speech recognition not supported in this browser.');
        }
    }

    getSpeechRecognitionResult(args, util) {
        return this.lastResult || 'Null';
    }

    setNoiseThreshold(args) {
        this.noiseThreshold = args.PERCENT;
        console.log(`Noise removal threshold set to ${this.noiseThreshold}%`);
    }

    turnOnAutoClearing(args) {
        this.autoClear = args.STATE === 'on';
        console.log(`Auto clearing turned ${args.STATE}`);
    }

    clearResultsAfterSeconds(args) {
        this.clearAfterSeconds = args.SECONDS;
        console.log(`Results will be cleared after ${args.SECONDS} seconds of speech`);
    }

    clearResultsAfterPause(args) {
        this.clearAfterPause = args.SECONDS;
        console.log(`Results will be cleared after a pause of ${args.SECONDS} seconds`);
    }

    setLanguage(args) {
        if (this.recognition) {
            this.recognition.lang = args.LANGUAGE;
            console.log(`Language set to ${args.LANGUAGE}`);
        }
    }

    resetSettings() {
        this.noiseThreshold = 30;
        this.autoClear = false;
        this.clearAfterSeconds = Infinity;
        this.clearAfterPause = Infinity;
        console.log('Settings have been reset to default values');
    }

    startListening() {
        if (this.recognition) {
            this.recognition.start();
            console.log('Started listening');
        } else {
            console.error('Recognition instance not found. Please initialize it first.');
        }
    }

    stopListening() {
        if (this.recognition) {
            this.recognition.stop();
            console.log('Stopped listening');
        } else {
            console.error('Recognition instance not found. Please initialize it first.');
        }
    }

    clearSpeechRecognitionResult() {
        this.clearRecognitionResult();
    }

    clearRecognitionResult() {
        this.lastResult = '';
        console.log('Speech recognition result cleared');
    }
}

module.exports = Scratch3SpeechRecognitionExtension;
