const BlockType = require('../../extension-support/block-type');
const ArgumentType = require('../../extension-support/argument-type');

class Scratch3ActuatorsBlocks {
    constructor(runtime) {
        this.runtime = runtime;
    }

    getInfo() {
        return {
            id: 'display',
            name: 'Display Modules',
            color1: '#d62222',
            
            blocks: [
                {
                    opcode: 'displayrst',
                    blockType: BlockType.COMMAND,
                    text: 'initialize 16 x 2 display on RST [NUMBER_1] EN [NUMBER_2] D4 [NUMBER_3] D5 [NUMBER_4] D6 [NUMBER_5] D7 [NUMBER_6]',
                    arguments: {
                        NUMBER_1: {
                            type: ArgumentType.NUMBER,
                            menu: 'pinMenu',
                            defaultValue: 3
                        },
                        NUMBER_2: {
                            type: ArgumentType.NUMBER,
                            menu: 'pinMenu',
                            defaultValue: 5
                        },
                        NUMBER_3: {
                            type: ArgumentType.NUMBER,
                            menu: 'pinMenu',
                            defaultValue: 6
                        },
                        NUMBER_4: {
                            type: ArgumentType.NUMBER,
                            menu: 'pinMenu',
                            defaultValue: 9
                        },
                        NUMBER_5: {
                            type: ArgumentType.NUMBER,
                            menu: 'pinMenu',
                            defaultValue: 10
                        },
                        NUMBER_6: {
                            type: ArgumentType.NUMBER,
                            menu: 'pinMenu',
                            defaultValue: 11
                        }
                    }
                },
                {
                    opcode: 'displayadd',
                    blockType: BlockType.COMMAND,
                    text: 'initialize 16 x 2 display at address [NUM_1]',
                    arguments: {
                        NUM_1: {
                            type: ArgumentType.STRING,
                            defaultValue: '0x27'
                        }
                    }
                },
                {
                    opcode: 'setcursor',
                    blockType: BlockType.COMMAND,
                    text: 'set cursor at column [NUM_1] row [NUM_2]',
                    arguments: {
                        NUM_1: {
                            type: ArgumentType.NUMBER,
                            menu:'columnMenu',
                            defaultValue: 1
                        },
                        NUM_2: {
                            type:ArgumentType.NUMBER,
                            menu: 'rowMenu',
                            defaultValue: 1
                        }
                    } 
                },
                {
                    opcode: 'displaytxt',
                    blockType: BlockType.COMMAND,
                    text: 'Write [text] display',
                    arguments: {
                        text: {
                            type: ArgumentType.STRING,
                            defaultValue: 'Hello World'
                        }
                    }
                },
                {
                    opcode: 'clrscreen',
                    blockType: BlockType.COMMAND,
                    text: 'Clear display'
                },
                {
                    opcode: 'setmode',
                    blockType: BlockType.COMMAND,
                    text: 'set [mode] mode',
                    arguments: {
                        mode: {
                            type: ArgumentType.STRING,
                            menu: 'modeMenu',
                            defaultValue: 'blink'
                        }
                    }
                },
                {
                    opcode: 'initTM1637',
                    blockType: BlockType.COMMAND,
                    text: 'initialize TM1637 4x7 segment display to CLK [NUM_1] DIO [NUM_2]',
                    arguments: {
                        NUM_1: {
                            type: ArgumentType.NUMBER,
                            menu:'pinMenu',
                            defaultValue: 3
                        },
                        NUM_2: {
                            type:ArgumentType.NUMBER,
                            menu: 'pinMenu',
                            defaultValue: 5
                        }
                    } 
                },
                {
                    opcode: 'dispNum',
                    blockType: BlockType.COMMAND,
                    text: 'display [num] of length [len], position [pos], [colon] colon & [zeros]',
                    arguments: {
                        num: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 50
                        },
                        len: {
                            type:ArgumentType.NUMBER,
                            menu: 'numMenu',
                            defaultValue: 4
                        },
                        pos: {
                            type:ArgumentType.NUMBER,
                            menu: 'numMenu',
                            defaultValue: 1
                        },
                        colon: {
                            type:ArgumentType.STRING,
                            menu: 'colonMenu',
                            defaultValue:'without'
                        },
                        zeros: {
                            type: ArgumentType.STRING,
                            menu:'zeroMenu',
                            defaultValue:'no leading zero'
                        }
                    } 
                },
                {
                    opcode: 'initDIN',
                    blockType: BlockType.COMMAND,
                    text: 'initialize display to DIN [NUM_1] CS [NUM_2] CLK [NUM_3]',
                    arguments: {
                        NUM_1: {
                            type: ArgumentType.NUMBER,
                            menu:'pinMenu',
                            defaultValue: 3
                        },
                        NUM_2: {
                            type:ArgumentType.NUMBER,
                            menu: 'pinMenu',
                            defaultValue: 5
                        },
                        NUM_3: {
                            type:ArgumentType.NUMBER,
                            menu:'pinMenu',
                            defaultValue: 6
                        }
                    } 
                }
            ],
            menus: {
                rowMenu: {
                    items: ['1', '2']
                },
                pinMenu: {
                    items: ['2','3','4','5','6','7','8','9','10','11','12','13','A0','A1','A2','A3','A4','A5']
                },
                columnMenu: {
                    items: ['1','2','3','4','5','6','7','8','9','10','12','13','14','15','16']
                },
                modeMenu: {
                    items: ['blink','no blink','cursor','no cursor','display','no display','auto-scroll','no auto-scroll','scroll display to left','scroll display to right']
                },
                numMenu: {
                    items: ['1','2','3','4']
                },
                colonMenu: {
                    items: ['with','without']
                },
                zeroMenu: {
                    items:['leading zeros','no leading zeros']
                }
            }
        };
    }

    myactuators(args) {
        console.log(`Motor ${args.NUMBER_1}, Direction 1: ${args.NUMBER_2}, Direction 2: ${args.NUMBER_3}, PWM: ${args.NUMBER_4}`);
        // Add the logic to control the actuators here
    }
}

module.exports = Scratch3ActuatorsBlocks;
