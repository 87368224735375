import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { defineMessages, injectIntl } from 'react-intl';
import MenuBarMenu from './menu-bar-menu.jsx';
import styles from './login-dropdown.css';

// Define messages for internationalization
const LoginDropdownMessages = defineMessages({
    username: {
        defaultMessage: 'Username',
        description: 'Label for login username input',
        id: 'general.username'
    },
    password: {
        defaultMessage: 'Password',
        description: 'Label for login password input',
        id: 'general.password'
    },
    signin: {
        defaultMessage: 'Sign in',
        description: 'Button text for user to sign in',
        id: 'general.signIn'
    },
    register: {
        defaultMessage: 'Register',
        description: 'Button text for user to register',
        id: 'general.register'
    },
    name: {
        defaultMessage: 'Name',
        description: 'Label for name input',
        id: 'general.name'
    },
    email: {
        defaultMessage: 'Email',
        description: 'Label for email input',
        id: 'general.email'
    },
    validationRequired: {
        defaultMessage: 'This field is required',
        description: 'Message to tell user they must enter text in a form field',
        id: 'form.validationRequired'
    },
    invalidCredentials: {
        defaultMessage: 'Invalid username or password',
        description: 'Message to tell user their credentials are invalid',
        id: 'login.invalidCredentials'
    }
});

const LoginDropdown = ({
    className,
    isOpen,
    isRtl,
    onClose,
    intl
}) => {
    const [isLogin, setIsLogin] = useState(true);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [error, setError] = useState('');

    // Hard-coded credentials for testing purposes
    const validUsername = 'stem';
    const validPassword = '1234';

    const handleSubmit = (e) => {
        e.preventDefault();
        setError('');

        if (isLogin) {
            // Login check
            if (username === validUsername && password === validPassword) {
                setUsername('');
                setPassword('');
                onClose();
            } else {
                setError(intl.formatMessage(LoginDropdownMessages.invalidCredentials));
            }
        } else {
            // Registration logic (can be enhanced with actual API calls)
            if (name && email && password) {
                alert('Registration successful');
                setIsLogin(true);
                setName('');
                setEmail('');
                setPassword('');
            } else {
                setError(intl.formatMessage(LoginDropdownMessages.validationRequired));
            }
        }
    };

    const toggleForm = () => {
        setIsLogin(!isLogin);
        setError('');
    };

    return (
        <MenuBarMenu
            className={className}
            open={isOpen}
            place={isRtl ? 'right' : 'left'}
            onRequestClose={onClose}
        >
            <div className={styles.loginwrapper}>
                <div className={classNames(styles.login, "relative flex flex-row justify-center items-center m-5")}>
                    {/* Social Media Icons Positioned at the Top-Right */}
                    <div className="absolute top-4 right-4 flex space-x-4">
                        <span className="text-gray-400">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="blue" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
                            </svg>
                        </span>
                        <span className="text-gray-400">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="green" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <path d="M20.287 16.626a8.967 8.967 0 0 0 1.067-4.626c0-4.96-4.04-9-9-9s-9 4.04-9 9a8.964 8.964 0 0 0 1.067 4.626l-1.46 1.46a1 1 0 0 0-.232 1.084l.29.849a1 1 0 0 0 1.177.61L5.48 19.88a8.964 8.964 0 0 0 4.647 1.288c4.96 0 9-4.04 9-9 0-.644-.072-1.274-.21-1.888zM12 15.2a6.2 6.2 0 1 1 0-12.4 6.2 6.2 0 0 1 0 12.4zm-1.68-3.863l-1.03-1.03a1.075 1.075 0 0 0-.48-.231 1.078 1.078 0 0 0-1.142.226l-.638.68a1.084 1.084 0 0 0-.14 1.244c.061.117.155.215.274.27l.802.329.462.189a.753.753 0 0 0 .694-.121l.83-.712a1.142 1.142 0 0 0 .28-.426 1.085 1.085 0 0 0-.163-1.086z"></path>
                            </svg>
                        </span>
                        <span className="text-gray-400">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="purple" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
                                <path d="M17 10.5a3.5 3.5 0 1 0-7 0 3.5 3.5 0 0 0 7 0zM17 7l.01 0"></path>
                            </svg>
                        </span>
                        <span className="text-gray-400">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="blue" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <path d="M18 2.01c-4.347 0-7.933 3.598-7.933 8.037v1.726H7v2.684h3.067v6.858H15V14.448h3.467l.483-2.684H15V9.8c0-.774.235-1.276 1.205-1.276h1.567V5.254A21.839 21.839 0 0 0 18 2.01z"></path>
                            </svg>
                        </span>
                    </div>

                    <div className="w-full lg:w-2/3 bg-white p-8">
                        <div className="justify-between items-center mb-6">
                            <h2 className="text-2xl font-bold">{isLogin ? intl.formatMessage(LoginDropdownMessages.signin) : 'Register'}</h2>
                        </div>
                        <form onSubmit={handleSubmit}>
                            {!isLogin && (
                                <div className="mb-4">
                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                                        {intl.formatMessage(LoginDropdownMessages.name)}
                                    </label>
                                    <input
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id="name"
                                        type="text"
                                        placeholder={intl.formatMessage(LoginDropdownMessages.name)}
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        style={{ marginLeft: '14px' }}
                                    />
                                </div>
                            )}
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                                    {intl.formatMessage(LoginDropdownMessages.username)}
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="username"
                                    type="text"
                                    placeholder={intl.formatMessage(LoginDropdownMessages.username)}
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    style={{ marginLeft: '14px' }}
                                />
                            </div>
                            {!isLogin && (
                                <div className="mb-4">
                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                                        {intl.formatMessage(LoginDropdownMessages.email)}
                                    </label>
                                    <input
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id="email"
                                        type="email"
                                        placeholder={intl.formatMessage(LoginDropdownMessages.email)}
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        style={{ marginLeft: '14px' }}
                                    />
                                </div>
                            )}
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                                    {intl.formatMessage(LoginDropdownMessages.password)}
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="password"
                                    type="password"
                                    placeholder={intl.formatMessage(LoginDropdownMessages.password)}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    style={{ marginLeft: '14px' }}
                                />
                            </div>
                            {error && <p className="text-red-500 text-xs italic mb-4">{error}</p>}
                            <button
                                type="submit"
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            >
                                {isLogin ? intl.formatMessage(LoginDropdownMessages.signin) : intl.formatMessage(LoginDropdownMessages.register)}
                            </button>
                        </form>
                        <div className="mt-4 text-center">
                            <button
                                onClick={toggleForm}
                                className="text-blue-500 hover:text-blue-700 font-bold"
                            >
                                {isLogin ? 'Create an account' : 'Already have an account? Sign in'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </MenuBarMenu>
    );
};

LoginDropdown.propTypes = {
    className: PropTypes.string,
    isOpen: PropTypes.bool,
    isRtl: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired
};

export default injectIntl(LoginDropdown);
