import React, { useState, useEffect } from 'react';
import { Unity, useUnityContext } from "react-unity-webgl";
import styles from './game.css';
// import './game.css'

// import Build from '../../../'

function UnityGame() {
  const { unityProvider, loadingProgression, isLoaded, addEventListener, removeEventListener } = useUnityContext({
    loaderUrl: "/Build/Pictures.loader.js",  // Adjust the path if necessary
    dataUrl: "/Build/Pictures.data/webgl.data",
    frameworkUrl: "/Build/Pictures.framework.js/build.framework.js",
    codeUrl: "/Build/Pictures.wasm/build.wasm",
  });

  const [isOpen, setIsOpen] = useState(true);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    // Define the progress update handler
    window.handleProgressUpdate = (newProgress) => {
      setProgress(newProgress);
    };

    return () => {
      // Clean up when component is unmounted
      delete window.handleProgressUpdate;
    };
  }, []);

  const closeWindow = () => {
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && (
        <div className={styles.unityTab}>
          {/* <button className={styles.closeBtn} onClick={closeWindow}>X</button>
          {console.log('Inside the car game')} */}
          <div className={styles.progressBar}>
            <div className={styles.progress} style={{ width: `${progress}%` }}></div>
          </div>
          <div className={styles.unityContainer}>
            {!isLoaded && (
              <p className={styles.loadingText}>Loading Application... {Math.round(loadingProgression * 100)}%</p>
            )}
            <Unity
              unityProvider={unityProvider}
              style={{ visibility: isLoaded ? "visible" : "hidden" }}
              className={styles.unityCanvas}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default UnityGame;
