const BlockType = require('../../extension-support/block-type');
const ArgumentType = require('../../extension-support/argument-type');

class Scratch3SensorsBlocks {
    constructor(runtime) {
        this.runtime = runtime;
    }

    getInfo() {
        return {
            id: 'sensors',
            name: 'Sensors',
            color1: '#18b3d6',
            
            blocks: [
                {
                    opcode: 'ultrasonic',
                    blockType: BlockType.REPORTER,
                    text: 'get ultrasonic sensor distance(cm) | trig [NUM_1], echo [NUM_2]',
                    arguments: {
                        NUM_1: {
                            type: ArgumentType.NUMBER,
                            menu: 'numMenu2',
                            defaultValue: 2
                        },
                        NUM_2: {
                            type: ArgumentType.NUMBER,
                            menu: 'numMenu2',
                            defaultValue: 3
                        }
                    }
                },
                {
                    opcode: 'digital',
                    blockType: BlockType.BOOLEAN,
                    text: 'read digital sensor [SENSING] at [NUM_2]',
                    arguments: {
                        SENSING: {
                            type: ArgumentType.STRING,
                            menu: 'sensingMenu',
                            defaultValue: 'PIR'
                        },
                        NUM_2: {
                            type: ArgumentType.NUMBER,
                            menu: 'numMenu2',
                            defaultValue: 2
                        }
                    }
                },
                {
                    opcode: 'dht',
                    blockType: BlockType.REPORTER,
                    text: 'get [TEMP] from DTH sensor at pin[NUM_2]',
                    arguments: {
                        TEMP: {
                            type: ArgumentType.STRING,
                            menu: 'tempMenu',
                            defaultValue: 'tempreture'
                        },
                        NUM_2: {
                            type: ArgumentType.NUMBER,
                            menu: 'numMenu2',
                            defaultValue: 2
                        }
                    }
                },
                {
                    opcode: 'analog',
                    blockType: BlockType.REPORTER,
                    text: 'read analog sensor [SENSOR] at [PORT]',
                    arguments: {
                        SENSOR: {
                            type: ArgumentType.STRING,
                            menu: 'sensorMenu',
                            defaultValue: 'light/photoresistor'
                        },
                        PORT: {
                            type: ArgumentType.STRING,
                            menu: 'portMenu',
                            defaultValue: 'A0'
                        }
                    }
                }
                
            ],
            menus: {
                numMenu2: {
                    items: ['2','3','4','5','6','7','8','9','10','11','12','13','A0','A1','A2','A3','A4','A5']
                },
                sensingMenu: {
                    items: ['IR(proximity)','PIR','soil moisture','hall effect/ magnetic field','touch','Generic']
                },
                tempMenu: {
                    items: ['tempreture','humidity']
                },
                sensorMenu: {
                    items: ['light / photoresistor','soil moisture','raindrop','sound / microphone','gas sensor','joystick X','joystick Y','geneic']
                },
                portMenu: {
                    items: ['A0','A1','A2','A3','A4','A5']
                }
            }
        };
    }

    sensors(args) {
        console.log(`Motor ${args.NUMBER_1}, Direction 1: ${args.NUMBER_2}, Direction 2: ${args.NUMBER_3}, PWM: ${args.NUMBER_4}`);
        // Add the logic to control the actuators here
    }
}

module.exports = Scratch3SensorsBlocks;
