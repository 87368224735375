// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* #E5F0FF */\n/* #E9F1FC */\n/* #D9E3F2 */\n/* 90% transparent version of motion-primary */\n/* #FFFFFF */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 15% transparent version of black */\n/* #575E75 */\n/* #4C97FF */\n/* #3373CC */\n/*#855CD6 #AD88C6*/\n/* 35% transparent version of looks-tertiary */\n/* 15% transparent version of looks-tertiary */\n/* #714EB6 */\n/* #FF661A */\n/* #E64D00 */\n/* #CF63CF */\n/* #BD42BD */\n/* #FFAB19 */\n/* #FF8C1A */\n/* #0FBD8C */\n/* #0FBD8C */\n/* #0B8E69 */\n/* #FF8C1A */\n/* #FFB366 */\n/* #FF8C1A */\n/* #0FBD8C */\n/* #0B8E69 */\n/* 35% transparent version of extensions-primary */\n/* opaque version of extensions-transparent, on white bg */\n/* lighter than motion-primary */\n.progress-ring_progress-ring_P_His {\n    margin: 0.5rem;\n}\n.progress-ring_progress-ring-svg_17Srp {\n    fill: transparent;\n}\n.progress-ring_progress-ring_P_His .progress-ring_progress-ring-ring_2NyUq {\n    stroke: hsla(260, 60%, 60%, 0.15);\n}\n.progress-ring_progress-ring_P_His .progress-ring_progress-ring-value_1SVN5 {\n    stroke: #3295a8;\n    stroke-linecap: round;\n}\n", ""]);
// Exports
exports.locals = {
	"progress-ring": "progress-ring_progress-ring_P_His",
	"progressRing": "progress-ring_progress-ring_P_His",
	"progress-ring-svg": "progress-ring_progress-ring-svg_17Srp",
	"progressRingSvg": "progress-ring_progress-ring-svg_17Srp",
	"progress-ring-ring": "progress-ring_progress-ring-ring_2NyUq",
	"progressRingRing": "progress-ring_progress-ring-ring_2NyUq",
	"progress-ring-value": "progress-ring_progress-ring-value_1SVN5",
	"progressRingValue": "progress-ring_progress-ring-value_1SVN5"
};
module.exports = exports;
