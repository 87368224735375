import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import FeedbackReviewModal from './FeedbackReviewModal.jsx';

import LanguageMenu from './language-menu.jsx';
import MenuBarMenu from './menu-bar-menu.jsx';
import ThemeMenu from './theme-menu.jsx';
import AudioVideoMenu from './audio&video-menu.jsx';
import { MenuItem, MenuSection } from '../menu/menu.jsx';

import menuBarStyles from './menu-bar.css';
import styles from './settings-menu.css';

import dropdownCaret from './dropdown-caret.svg';
import settingsIcon from './icon--settings.svg';

const SettingsMenu = ({
    canChangeLanguage,
    canChangeTheme,
    audioVideoSetting,
    isRtl,
    onRequestClose,
    onRequestOpen,
    settingsMenuOpen
}) => {
    const [isFeedbackReviewOpen, setFeedbackReviewOpen] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);

    useEffect(() => {
        const fullscreenChangeHandler = () => {
            setIsFullScreen(!!document.fullscreenElement);
        };

        document.addEventListener('fullscreenchange', fullscreenChangeHandler);

        return () => {
            document.removeEventListener('fullscreenchange', fullscreenChangeHandler);
        };
    }, []);

    const toggleFullScreen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen().catch(err => {
                console.error(`Error attempting to enable full-screen mode: ${err.message}`);
            });
        } else {
            document.exitFullscreen().catch(err => {
                console.error(`Error attempting to disable full-screen mode: ${err.message}`);
            });
        }
    };

    // Determine the dynamic message based on isFullScreen state
    const fullScreenMessage = isFullScreen ? "Exit Full Screen" : "Switch to Full Screen";

    return (
        <div
            className={classNames(menuBarStyles.menuBarItem, menuBarStyles.hoverable, menuBarStyles.themeMenu, {
                [menuBarStyles.active]: settingsMenuOpen
            })}
            onMouseUp={onRequestOpen}
        >
            <img src={settingsIcon} />
            <span className={styles.dropdownLabel}>
                <FormattedMessage
                    defaultMessage="Settings"
                    description="Settings menu"
                    id="gui.menuBar.settings"
                />
            </span>
            <img src={dropdownCaret} />
            <MenuBarMenu
                className={menuBarStyles.menuBarMenu}
                open={settingsMenuOpen}
                place={isRtl ? 'left' : 'right'}
                onRequestClose={onRequestClose}
            >
                <MenuSection>
                    {canChangeLanguage && <LanguageMenu onRequestCloseSettings={onRequestClose} />}
                    {canChangeTheme && <ThemeMenu onRequestCloseSettings={onRequestClose} />}
                    {audioVideoSetting && <AudioVideoMenu onRequestCloseSettings={onRequestClose} />}
                </MenuSection>
                <MenuSection>
                    <MenuItem>
                        <FormattedMessage
                            defaultMessage="Start Here"
                            description="Start Here menu item"
                            id="gui.menuBar.startHere"
                        />
                    </MenuItem>
                    <MenuItem>
                        <FormattedMessage
                            defaultMessage="Preferences for Coding Environment"
                            description="Preferences for Coding Environment menu item"
                            id="gui.menuBar.codingEnvironmentPreferences"
                        />
                    </MenuItem>
                    <MenuItem>
                        <FormattedMessage
                            defaultMessage="Technical Assistance & Support"
                            description="Assistance & Support menu item"
                            id="gui.menuBar.assistanceSupport"
                        />
                    </MenuItem>
                    <MenuItem onClick={toggleFullScreen}>
                        {fullScreenMessage}
                    </MenuItem>
                    <MenuItem onClick={() => setFeedbackReviewOpen(true)}>
                        <FormattedMessage
                            defaultMessage="Feedback & Review"
                            description="Feedback & Reviews menu item"
                            id="gui.menuBar.feedbackReviews"
                        />
                    </MenuItem>
                    <MenuItem>
                        <a href='https://www.stemworld.in/' style={{textDecoration:"none",color:"white"}}>
                        <FormattedMessage
                            defaultMessage="About STEMBlocks & Information"
                            description="About STEMBlocks Information menu item"
                            id="gui.menuBar.aboutSTEMBlocksInfo"
                        />
                        </a>
                    </MenuItem>
                </MenuSection>
            </MenuBarMenu>
            <FeedbackReviewModal
                isOpen={isFeedbackReviewOpen}
                onClose={() => setFeedbackReviewOpen(false)}
            />
        </div>
    );
};

SettingsMenu.propTypes = {
    canChangeLanguage: PropTypes.bool,
    canChangeTheme: PropTypes.bool,
    audioVideoSetting: PropTypes.bool,
    isRtl: PropTypes.bool,
    onRequestClose: PropTypes.func,
    onRequestOpen: PropTypes.func,
    settingsMenuOpen: PropTypes.bool
};

export default SettingsMenu;
