// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* #E5F0FF */\n/* #E9F1FC */\n/* #D9E3F2 */\n/* 90% transparent version of motion-primary */\n/* #FFFFFF */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 15% transparent version of black */\n/* #575E75 */\n/* #4C97FF */\n/* #3373CC */\n/*#855CD6 #AD88C6*/\n/* 35% transparent version of looks-tertiary */\n/* 15% transparent version of looks-tertiary */\n/* #714EB6 */\n/* #FF661A */\n/* #E64D00 */\n/* #CF63CF */\n/* #BD42BD */\n/* #FFAB19 */\n/* #FF8C1A */\n/* #0FBD8C */\n/* #0FBD8C */\n/* #0B8E69 */\n/* #FF8C1A */\n/* #FFB366 */\n/* #FF8C1A */\n/* #0FBD8C */\n/* #0B8E69 */\n/* 35% transparent version of extensions-primary */\n/* opaque version of extensions-transparent, on white bg */\n/* lighter than motion-primary */\n.loupe_color-picker_1OaU- {\n    position: absolute;\n    border-radius: 100%;\n    border: 4px solid hsla(0, 0%, 0%, 0.15);\n}\n", ""]);
// Exports
exports.locals = {
	"color-picker": "loupe_color-picker_1OaU-",
	"colorPicker": "loupe_color-picker_1OaU-"
};
module.exports = exports;
