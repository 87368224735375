const initialState = {
    cameras: [],
    currentCamera: null,
    microphones: [],
    currentMicrophone: null,
    menuOpen: false
};

export { initialState };

export const selectCamera = cameraId => ({
    type: 'SELECT_CAMERA',
    cameraId
});

export const selectMicrophone = microphoneId => ({
    type: 'SELECT_MICROPHONE',
    microphoneId
});

export const openAudioVisualMenu = () => ({
    type: 'OPEN_AUDIO_VISUAL_MENU'
});

export default function audioVisual(state = initialState, action) {
    switch (action.type) {
    case 'SELECT_CAMERA':
        return {
            ...state,
            currentCamera: action.cameraId
        };
    case 'SELECT_MICROPHONE':
        return {
            ...state,
            currentMicrophone: action.microphoneId
        };
    case 'OPEN_AUDIO_VISUAL_MENU':
        return {
            ...state,
            menuOpen: !state.menuOpen
        };
    default:
        return state;
    }
}
