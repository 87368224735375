import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import closeIcon from './icon--close.svg';

const FeedbackReviewModal = ({ isOpen, onClose }) => {
    const [feedback, setFeedback] = useState('');
    const [isFeedbackFocused, setIsFeedbackFocused] = useState(false);
    const [rating, setRating] = useState(0);
    const [hoverRating, setHoverRating] = useState(0);

    const handleFeedbackChange = (event) => {
        setFeedback(event.target.value);
    };

    const handleFeedbackFocus = () => {
        setIsFeedbackFocused(true);
        if (feedback === 'Something to tell us? :)') {
            setFeedback('');
        }
    };

    const handleFeedbackBlur = () => {
        setIsFeedbackFocused(false);
        if (feedback.trim() === '') {
            setFeedback('Something to tell us? :)');
        }
    };

    const handleStarHover = (ratingValue) => {
        setHoverRating(ratingValue);
    };

    const handleStarClick = (ratingValue) => {
        setRating(ratingValue);
    };

    const handleSubmit = () => {
        // Implement your submit logic here
        // Remove console logs
        // Close the modal after submission (if needed)
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1000
            }}
        >
            <div
                style={{
                    background: '#6c7ae0', // Unique color for the header background
                    borderRadius: '8px 8px 0 0', // Rounded corners only at the top
                    width: '80%',
                    maxWidth: '600px',
                    padding: '20px',
                    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
                    textAlign: 'center',
                    color: '#333', // Text color
                    position: 'relative' // Ensure relative positioning for close icon
                }}
            >
                <img
                    src={closeIcon}
                    alt="Close"
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        width: '24px',
                        height: '24px',
                        cursor: 'pointer'
                    }}
                    onClick={onClose}
                />
                <h2 style={{ margin: 0, padding: '10px', color: '#333' }}> {/* Header box style */}
                    <FormattedMessage
                        defaultMessage="Feedback & Review"
                        description="Feedback & Reviews modal header"
                        id="gui.menuBar.feedbackReviews"
                    />
                </h2>
                <div style={{ marginTop: '20px' }}>
                    <textarea
                        value={feedback}
                        onChange={handleFeedbackChange}
                        onFocus={handleFeedbackFocus}
                        onBlur={handleFeedbackBlur}
                        style={{
                            width: '100%',
                            minHeight: '150px', // Increased height
                            padding: '10px',
                            borderRadius: '0 0 8px 8px', // Rounded corners only at the bottom
                            border: '1px solid #ccc',
                            fontSize: '1rem',
                            outline: 'none',
                            textAlign: 'left'
                        }}
                        placeholder="Something to tell us? :)"
                    />
                </div>
                <div style={{ marginTop: '20px' }}>
                    <h3 style={{ marginBottom: '10px', color: '#333' , fontSize: '16px'}}> {/* Text color */}
                        <FormattedMessage
                            defaultMessage="Rate your experience:"
                            description="Star rating section header"
                            id="gui.feedbackReview.starRatingHeader"
                        />
                    </h3>
                    <div style={{ fontSize: '3rem' }}> {/* Star size increased */}
                        {[1, 2, 3, 4, 5].map((star) => {
                            const filled = star <= (hoverRating || rating);
                            return (
                                <span
                                    key={star}
                                    style={{
                                        cursor: 'pointer',
                                        color: filled ? '#FFD700' : '#ccc', // Star color changed
                                        marginRight: '10px'
                                    }}
                                    onMouseEnter={() => handleStarHover(star)}
                                    onMouseLeave={() => handleStarHover(0)}
                                    onClick={() => handleStarClick(star)}
                                >
                                    &#9733;
                                </span>
                            );
                        })}
                    </div>
                    {rating > 0 && (
                        <p style={{ marginTop: '10px', color: '#333' }}>
                            <FormattedMessage
                                defaultMessage="Thank you for leaving a review!"
                                description="Review confirmation message"
                                id="gui.feedbackReview.thankYou"
                            />
                        </p>
                    )}
                </div>
                <div style={{ marginTop: '20px' }}>
                    <button
                        onClick={handleSubmit}
                        style={{
                            padding: '10px 20px',
                            fontSize: '1rem',
                            background: '#007bff',
                            color: 'white',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer'
                        }}
                    >
                        <FormattedMessage
                            defaultMessage="Submit"
                            description="Submit button"
                            id="gui.feedbackReview.submitButton"
                        />
                    </button>
                </div>
            </div>
        </div>
    );
};

FeedbackReviewModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};

export default FeedbackReviewModal;
