// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* src/game.css */\n.game_unityTab_ISD_c {\n  /* position: fixed;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%); */\n  /* width: 80%;\n  height: 80%; */\n  background-color: white;\n  border-radius: 5px;\n  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); */\n  margin-top: 2px;\n  z-index: 1000;\n  padding: 30px;\n}\n.game_closeBtn_3JaY0 {\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  padding: 10px 15px;\n  background-color: #ff4d4d;\n  color: white;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n  font-size: 14px;\n}\n.game_progresBar_3vvvj {\n  width: 94%;\n  height: 20px;\n  background-color: #e0e0e0;\n  border-radius: 10px;\n  overflow: hidden;\n  margin: 20px auto 10px auto;\n}\n.game_progress_1ACNc {\n  height: 20px;\n  background-color: #4caf50;\n  width: 0;\n  border-radius: 10px;\n  transition: width 0.3s;\n}\n.game_unityContainer_dk1xp {\n  width: 100%;\n  height: calc(100% - 40px);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.game_loadingText_3Ou3P {\n  font-size: 18px;\n  color: #333;\n  text-align: center;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n.game_unityCanvas_1JvD0 {\n  width: 75vw; /* Adjust the width as needed */\n  height: 70vh; /* Adjust the height as needed */\n  border: 2px solid #fff; /* Optional: Add a border to the game window */\n  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);  */\n  margin-top: 20px;\n  background-color: transparent;\n}\n", ""]);
// Exports
exports.locals = {
	"unityTab": "game_unityTab_ISD_c",
	"closeBtn": "game_closeBtn_3JaY0",
	"progresBar": "game_progresBar_3vvvj",
	"progress": "game_progress_1ACNc",
	"unityContainer": "game_unityContainer_dk1xp",
	"loadingText": "game_loadingText_3Ou3P",
	"unityCanvas": "game_unityCanvas_1JvD0"
};
module.exports = exports;
