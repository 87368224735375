const ArgumentType = require('../../extension-support/argument-type');
const BlockType = require('../../extension-support/block-type');
const formatMessage = require('format-message');

class Scratch3ActuatorsBlocks {
    constructor(runtime) {
        this.runtime = runtime;
        this.setupCode = '';
        this.loopCode = '';
        this.defineCode = '';
    }

    getInfo() {
        return {
            id: 'actuators',
            color1: '#0c6e26',
            name: formatMessage({
                id: 'actuators.categoryName',
                default: 'Actuators',
                description: 'Actuators extension category'
            }),
            blocks: [
                {
                    opcode: 'connectmotor',
                    blockType: BlockType.COMMAND,
                    text: 'connect motor [NUMBER_1] direction 1 [NUMBER_2] direction 2 [NUMBER_3] & PWM [NUMBER_4]',
                    arguments: {
                        NUMBER_1: {
                            type: ArgumentType.NUMBER,
                            menu: 'numMenu1',
                            defaultValue: 1
                        },
                        NUMBER_2: {
                            type: ArgumentType.NUMBER,
                            menu: 'numMenu2',
                            defaultValue: 2
                        },
                        NUMBER_3: {
                            type: ArgumentType.NUMBER,
                            menu: 'numMenu2',
                            defaultValue: 4
                        },
                        NUMBER_4: {
                            type: ArgumentType.NUMBER,
                            menu: 'numMenu3',
                            defaultValue: 3
                        }
                    }
                },
                {
                    opcode: 'runmotor',
                    blockType: BlockType.COMMAND,
                    text: 'run motor [NUMBER] in direction [DIR_1] with speed [PERCENT] %',
                    arguments: {
                        NUMBER: {
                            type: ArgumentType.NUMBER,
                            menu: 'numMenu1',
                            defaultValue: 1
                        },
                        DIR_1: {
                            type: ArgumentType.STRING,
                            menu: 'dirMenu',
                            defaultValue: 'forward'
                        },
                        PERCENT: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 100
                        }
                    }
                },
                {
                    opcode: 'statemotor',
                    blockType: BlockType.COMMAND,
                    text: '[STATE] motor [NUMBER]',
                    arguments: {
                        NUMBER: {
                            type: ArgumentType.NUMBER,
                            menu: 'numMenu1',
                            defaultValue: 1
                        },
                        STATE: {
                            type: ArgumentType.STRING,
                            menu: 'stateMenu',
                            defaultValue: 'free'
                        }
                    }
                },
                {
                    opcode: 'setservo',
                    blockType: BlockType.COMMAND,
                    text: 'set servo on [NUMBER_1] to [ANGLE_1] angle',
                    arguments: {
                        NUMBER_1: {
                            type: ArgumentType.NUMBER,
                            menu: 'numMenu3',
                            defaultValue: 11
                        },
                        ANGLE_1: {
                            type: ArgumentType.ANGLE,
                            defaultValue: 30
                        }
                    }
                },
                {
                    opcode: 'setrelay',
                    blockType: BlockType.COMMAND,
                    text: 'set relay at pin [NUMBER_1] to [STATE]',
                    arguments: {
                        NUMBER_1: {
                            type: ArgumentType.NUMBER,
                            menu: 'numMenu2',
                            defaultValue: 3
                        },
                        STATE: {
                            type: ArgumentType.STRING,
                            menu: 'onoffMenu',
                            defaultValue: 'OFF'
                        }
                    }
                },
                {
                    opcode: 'runbldcmotor',
                    blockType: BlockType.COMMAND,
                    text: 'run BLDC motor on [NUMBER_1] at [PERCENT] % speed',
                    arguments: {
                        NUMBER_1: {
                            type: ArgumentType.NUMBER,
                            menu: 'numMenu3',
                            defaultValue: 11
                        },
                        PERCENT: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 30
                        }
                    }
                }
            ],
            menus: {
                numMenu1: {
                    items: ['1', '2', '3', '4']
                },
                numMenu2: {
                    items: ['1', '2', '3', '4']
                },
                numMenu3: {
                    items: ['5', '6', '7', '8', '9', '10', '11', '12']
                },
                dirMenu: {
                    items: ['forward', 'backward']
                },
                stateMenu: {
                    items: ['free', 'hold']
                },
                onoffMenu: {
                    items: ['ON', 'OFF']
                }
            }
        };
    }

    connectmotor(args) {
        const { NUMBER_1, NUMBER_2, NUMBER_3, NUMBER_4 } = args;
        this.setupCode += `motor[${NUMBER_1}].dir_1 = ${NUMBER_2};\n`;
        this.setupCode += `motor[${NUMBER_1}].dir_2 = ${NUMBER_3};\n`;
        this.setupCode += `motor[${NUMBER_1}].pwm = ${NUMBER_4};\n`;
    }

    runmotor(args) {
        const { NUMBER, DIR_1, PERCENT } = args;
        this.loopCode += `runMotor(${NUMBER}, ${DIR_1 === 'forward' ? 'HIGH' : 'LOW'}, ${PERCENT});\n`;
    }

    statemotor(args) {
        const { NUMBER, STATE } = args;
        this.loopCode += `motorState(${NUMBER}, ${STATE});\n`;
    }

    setservo(args) {
        const { NUMBER_1, ANGLE_1 } = args;
        this.loopCode += `setServo(${NUMBER_1}, ${ANGLE_1});\n`;
    }

    setrelay(args) {
        const { NUMBER_1, STATE } = args;
        this.loopCode += `setRelay(${NUMBER_1}, ${STATE});\n`;
    }

    runbldcmotor(args) {
        const { NUMBER_1, PERCENT } = args;
        this.loopCode += `runBLDCMotor(${NUMBER_1}, ${PERCENT});\n`;
    }

    resetCode() {
        this.setupCode = '';
        this.loopCode = '';
        this.defineCode = '';
    }
}

module.exports = Scratch3ActuatorsBlocks;
