import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as THREE from 'three';
import styles from './stage.css';

const Stage3D = ({ isFullScreen, stageHeight, stageWidth }) => {
    console.log('Stage3D rendered', { isFullScreen, stageHeight, stageWidth });
    const mountRef = useRef(null);

    useEffect(() => {
        console.log('Stage3D useEffect called');
        const scene = new THREE.Scene();
        scene.background = new THREE.Color(0xf0f0f0);  // Light gray background
        const camera = new THREE.PerspectiveCamera(75, stageWidth / stageHeight, 0.1, 1000);
        const renderer = new THREE.WebGLRenderer();

        renderer.setSize(stageWidth, stageHeight);
        mountRef.current.appendChild(renderer.domElement);

        camera.position.set(0, 0, 20);
        camera.lookAt(0, 0, 0);

        // // Add rotating cube
        // const geometry = new THREE.BoxGeometry(1, 1, 1);
        // const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
        // const cube = new THREE.Mesh(geometry, material);
        // scene.add(cube);

        // const animate = () => {
        //     requestAnimationFrame(animate);
        //     cube.rotation.x += 0.01;
        //     cube.rotation.y += 0.01;
        //     renderer.render(scene, camera);
        // };
        // animate();

        // Make scene, camera, and renderer globally accessible
        window.CADscene = scene;
        window.CADcamera = camera;
        window.CADrenderer = renderer;

        console.log('CAD scene, camera, and renderer set globally');

        return () => {
            mountRef.current.removeChild(renderer.domElement);
            console.log('Clean-up: removed renderer');
        };
    }, [stageHeight, stageWidth]);

    return <div ref={mountRef} className={styles.stage3d} />;
};

Stage3D.propTypes = {
    isFullScreen: PropTypes.bool.isRequired,
    stageHeight: PropTypes.number.isRequired,
    stageWidth: PropTypes.number.isRequired
};

export default Stage3D;