// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* #E5F0FF */\n/* #E9F1FC */\n/* #D9E3F2 */\n/* 90% transparent version of motion-primary */\n/* #FFFFFF */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 15% transparent version of black */\n/* #575E75 */\n/* #4C97FF */\n/* #3373CC */\n/*#855CD6 #AD88C6*/\n/* 35% transparent version of looks-tertiary */\n/* 15% transparent version of looks-tertiary */\n/* #714EB6 */\n/* #FF661A */\n/* #E64D00 */\n/* #CF63CF */\n/* #BD42BD */\n/* #FFAB19 */\n/* #FF8C1A */\n/* #0FBD8C */\n/* #0FBD8C */\n/* #0B8E69 */\n/* #FF8C1A */\n/* #FFB366 */\n/* #FF8C1A */\n/* #0FBD8C */\n/* #0B8E69 */\n/* 35% transparent version of extensions-primary */\n/* opaque version of extensions-transparent, on white bg */\n/* lighter than motion-primary */\n.dial_container_3hox- {\n    padding: 1rem;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n}\n.dial_dial-container_ktOnA {\n    position: relative;\n}\n.dial_dial-face_3NuSA, .dial_dial-handle_1tfZT, .dial_gauge_3g5NZ {\n    position: absolute;\n    top: 0;\n    left: 0;\n    overflow: visible;\n}\n.dial_dial-face_3NuSA {\n    width: 100%;\n}\n.dial_dial-handle_1tfZT {\n    cursor: pointer;\n    width: 40px;\n    height: 40px;\n    /* Use margin to make positioning via top/left easier */\n    margin-left: calc(40px / -2);\n    margin-top: calc(40px / -2);\n}\n.dial_gauge-path_1WoJ6 {\n    fill: hsla(260, 60%, 60%, 0.35);\n    stroke: #3295a8;\n    stroke-width: 1px;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "dial_container_3hox-",
	"dial-container": "dial_dial-container_ktOnA",
	"dialContainer": "dial_dial-container_ktOnA",
	"dial-face": "dial_dial-face_3NuSA",
	"dialFace": "dial_dial-face_3NuSA",
	"dial-handle": "dial_dial-handle_1tfZT",
	"dialHandle": "dial_dial-handle_1tfZT",
	"gauge": "dial_gauge_3g5NZ",
	"gauge-path": "dial_gauge-path_1WoJ6",
	"gaugePath": "dial_gauge-path_1WoJ6"
};
module.exports = exports;
