const Scratch3LooksBlocks = require('../../blocks/scratch3_looks');
const BlockType = require('../../extension-support/block-type');
const ArgumentType = require('../../extension-support/argument-type');


class Scratch3CameraExtension {
    constructor(runtime) {
        this.runtime = runtime;
        this.videoElement = document.createElement('video');
        document.body.appendChild(this.videoElement);
        this.videoElement.style.display = 'none';
    }

    getInfo () {
        return {
            // unique ID for your extension
            id: 'MathJoy',

            // name that will be displayed in the Scratch UI
            name: 'Maths',

            // colours to use for your extension blocks
            color1: '#AD88C6',
            color2: '#AD88C6',

            // icons to display
            // blockIconURI: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAFCAAAAACyOJm3AAAAFklEQVQYV2P4DwMMEMgAI/+DEUIMBgAEWB7i7uidhAAAAABJRU5ErkJggg==',
            // menuIconURI: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAFCAAAAACyOJm3AAAAFklEQVQYV2P4DwMMEMgAI/+DEUIMBgAEWB7i7uidhAAAAABJRU5ErkJggg==',

            blocks: [
                {
                    opcode: 'calculatePower',
                    blockType: BlockType.REPORTER,
                    text: 'Power [int1] ^ [int2]',
                    arguments: {
                        int1 : {
                            type: ArgumentType.NUMBER
                        },
                        int2 : {
                            type: ArgumentType.NUMBER
                        }
                    }
                },
                {
                    opcode: 'calculate',
                    blockType: BlockType.REPORTER,
                    text: 'operate [int1] [operation] [int2]',
                    arguments: {
                        operation: {
                            type: ArgumentType.STRING,
                            menu: 'operationsMenu'
                        },
                        int1: {
                            type: ArgumentType.NUMBER
                        },
                        int2: {
                            type: ArgumentType.NUMBER
                        }
                    }
                },
                

                {
                    opcode: 'checkNumberType',
                    blockType: BlockType.COMMAND,
                    text: 'Check if [number] is [type]',
                    arguments: {
                        number: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 0
                        },
                        type: {
                            type: ArgumentType.STRING,
                            menu: 'typeMenu'
                        }
                    }
                },
                {
                    opcode: 'getConstantValue',
                    blockType: BlockType.REPORTER,
                    text: '[constant] value',
                    arguments: {
                        constant: {
                            type: ArgumentType.STRING,
                            menu: 'constantsMenu'
                        }
                    }
                },
                {
                    opcode: 'getTrigValue',
                    blockType: BlockType.COMMAND,
                    text: '[function] of [angle] degrees for [seconds] seconds',
                    arguments: {
                        function: {
                            type: ArgumentType.STRING,
                            menu: 'functionsMenu'
                        },
                        angle: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 0
                        },
                        seconds: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 2
                        }
                    }
                },
                {
                    opcode: 'getTrigExactValue',
                    blockType: BlockType.COMMAND,
                    text: '[function] of [angle] degrees for [seconds] seconds',
                    arguments: {
                        function: {
                            type: ArgumentType.STRING,
                            menu: 'functionsMenu'
                        },
                        angle: {
                            type: ArgumentType.NUMBER,
                            menu: 'anglesMenu'
                        },
                        seconds: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 2
                        }
                    }
                },
                {
                    opcode: 'rollDie',
                    blockType: BlockType.COMMAND,
                    text: 'roll die',
                    arguments: {},
                },    
                // 
                {
                    opcode: 'calculateStatistic',
                    blockType: BlockType.REPORTER,
                    text: '[STATISTIC] of [NUMBERS]',
                    arguments: {
                        STATISTIC: {
                            type: ArgumentType.STRING,
                            menu: 'statisticMenu',
                            defaultValue: 'mean'
                        },
                        NUMBERS: {
                            type: ArgumentType.STRING,
                            defaultValue: '1, 2, 3'
                        }
                    },
                },   
                {
                    opcode: 'mathOperations',
                    blockType: BlockType.REPORTER,
                    text: '[OPERATION] of [NUMBERS]',
                    arguments: {
                        OPERATION: {
                            type: ArgumentType.STRING,
                            menu: 'operationMenu',
                            defaultValue: 'LCM'
                        },
                        NUMBERS: {
                            type: ArgumentType.STRING,
                            defaultValue: '4,5,6'
                        }
                    },
                   
                    
                },
                {
                    opcode: 'performMathOperation',
                    blockType: BlockType.REPORTER,
                    text: '[OPERATION] of [NUMBER]',
                    arguments: {
                        OPERATION: {
                            type: ArgumentType.STRING,
                            menu: 'performMenu',
                            defaultValue: 'square root'
                        },
                        NUMBER: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 9
                        }
                                      
                    }
                },
                {
                    opcode: 'decimalToHex',
                    blockType: BlockType.REPORTER,
                    text: 'hexadecimal of [NUMBER]',
                    arguments: {
                        NUMBER: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 42
                        }
                    }
                },
                {
                    opcode: 'calculateDistance',
                    blockType: BlockType.REPORTER,
                    text: 'Distance between (x1: [x1], y1: [y1]) and (x2: [x2], y2: [y2])',
                    arguments: {
                        x1: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 0
                        },
                        y1: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 0
                        },
                        x2: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 6
                        },
                        y2: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 6
                        }
                    }
                },
                {
                    opcode: 'calculatePermutation',
                    blockType: BlockType.REPORTER,
                    text: 'Permutation of n: [n] r: [r]',
                    arguments: {
                        n: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 4
                        },
                        r: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 2
                        }
                    }
                },
                {
                    opcode: 'binaryConversion',
                    blockType: BlockType.REPORTER,
                    text: '[OPERATION] [NUMBER]',
                    arguments: {
                        OPERATION: {
                            type: ArgumentType.STRING,
                            menu: 'binaryOperationMenu',
                            defaultValue: 'decimal to binary'
                        },
                        NUMBER: {
                            type: ArgumentType.STRING,
                            defaultValue: 'B/D'
                        }
                    },
                }    
            ],

               
            menus: {
                operationsMenu: {
                    acceptReporters: false,
                    items: [
                        {
                            text: '+',
                            value: 'add'
                        },
                        {
                            text: '-',
                            value: 'subtract'
                        },
                        {
                            text: '*',
                            value: 'multiply'
                        },
                        {
                            text: '/',
                            value: 'divide'
                        },
                        {
                            text: '%',
                            value: 'remainder'
                        }
                    ]
                },
                
                typeMenu: {
                    acceptReporters: true,
                    items: [
                        {
                            text: 'even',
                            value: 'even'
                        },
                        {
                            text: 'odd',
                            value: 'odd'
                        },
                        {
                            text: 'whole',
                            value: 'whole'
                        },
                        {
                            text: 'prime',
                            value: 'prime'
                        },
                        {
                            text: 'positive',
                            value: 'positive'
                        },
                        {
                            text: 'negative',
                            value: 'negative'
                        }
                    ]
                },
                constantsMenu: {
                    acceptReporters: false,
                    items: [
                        { text: 'π', value: 'pi' },
                        { text: 'e', value: 'e' },
                        { text: 'φ', value: 'phi' },
                        { text: 'sqrt 2', value: 'sqrt2' },
                        { text: 'sqrt 1/2', value: 'sqrtHalf' }
                    ]
                },
                    functionsMenu: {
                        acceptReporters: true,
                        items: [
                            { text: 'sin', value: 'sin' },
                            { text: 'cos', value: 'cos' },
                            { text: 'tan', value: 'tan' },
                            { text: 'cot', value: 'cot' },
                            { text: 'sec', value: 'sec' },
                            { text: 'cosec', value: 'cosec' }
                        ]
                },
            
                functionsMenu: {
                    acceptReporters: true,
                    items: [
                        { text: 'sin', value: 'sin' },
                        { text: 'cos', value: 'cos' },
                        { text: 'tan', value: 'tan' },
                        { text: 'cot', value: 'cot' },
                        { text: 'sec', value: 'sec' },
                        { text: 'cosec', value: 'cosec' }
                    ]
                },
                anglesMenu: {
                    acceptReporters: true,
                    items: [
                        { text: '0', value: '0' },
                        { text: '30', value: '30' },
                        { text: '45', value: '45' },
                        { text: '60', value: '60' },
                        { text: '90', value: '90' }
                    ]
                },
                statisticMenu: {
                    acceptReporters: true,
                    items: ['mean', 'median', 'mode']
                },
                performMenu: {
                    acceptReporters: true,
                    items: [
                        'square root',
                        'cubic root',
                        'absolute',
                        '-',
                        'ln',
                        'log2',
                        'log10',
                        'e^',
                        '10^',
                        'factorial'
                    ]
                },
                
                operationMenu: {
                        acceptReporters: true,
                        items: ['LCM', 'HCF', 'factors']
                },
                binaryOperationMenu: {
                    acceptReporters: true,
                    items: ['decimal to binary', 'binary to decimal']
                }
                

            
            }
        };    
    }
    
    calculate(args) {
        const int1 = parseInt(args.int1);
        const int2 = parseInt(args.int2);
        switch (args.operation) {
            case 'add':
                return int1 + int2;
            case 'subtract':
                return int1 - int2;
            case 'multiply':
                return int1 * int2;
            case 'divide':
                return int1 / int2;
            case 'remainder':
                return int1 % int2;    
            default:
                return 0;
        }
    }
    

    calculatePower(args) {
        return (args.int1 ** args.int2) // Return image as base64 encoded string
    }

    checkNumberType(args,util) {
        const type = args.type;
        const number = parseFloat(args.number);
        let result;

        switch (type) {
            case 'even':
                result = (number % 2 === 0) ? 'even' : 'not even';
                break;
            case 'odd':
                result = (number % 2 !== 0) ? 'odd' : 'not odd';
                break;
            case 'whole':
                result = (number % 1 === 0) ? 'whole' : 'not whole';
                break;
            case 'prime':
                result = this.isPrime(number) ? 'prime' : 'not prime';
                break;
            case 'positive':
                result = (number > 0) ? 'positive' : 'not positive';
                break;
            case 'negative':
                result = (number < 0) ? 'negative' : 'not negative';
                break;
            default:
                result = 'unknown type';
                break;
        }

        this.runtime.emit(Scratch3LooksBlocks.SAY_OR_THINK, util.target, 'say',`${number} is ${result}`);

    }

    isPrime(num) {
        if (num <= 1) return false;
        if (num <= 3) return true;
        if (num % 2 === 0 || num % 3 === 0) return false;
        for (let i = 5; i * i <= num; i += 6) {
            if (num % i === 0 || num % (i + 2) === 0) return false;
        }
        return true;
    }
    getConstantValue(args) {
        const constant = args.constant;
        switch (constant) {
            case 'pi':
                return Math.PI;
            case 'e':
                return Math.E;
            case 'phi':
                return (1 + Math.sqrt(5)) / 2;
            case 'sqrt2':
                return Math.sqrt(2);
            case 'sqrtHalf':
                return Math.sqrt(1/2);
            default:
                return 'Unknown';
        }
    }
    async getTrigValue(args, util) {
        const func = args.function;
        const angle = parseFloat(args.angle);
        const seconds = parseFloat(args.seconds);
        const radians = (Math.PI / 180) * angle;
        let result;

        switch (func) {
            case 'sin':
                result = Math.sin(radians).toFixed(2);
                break;
            case 'cos':
                result = Math.cos(radians).toFixed(2);
                break;
            case 'tan':
                result = Math.tan(radians).toFixed(2);
                break;
            case 'cot':
                result = (1 / Math.tan(radians)).toFixed(2);
                break;
            case 'sec':
                result = (1 / Math.cos(radians)).toFixed(2);
                break;
            case 'cosec':
                result = (1 / Math.sin(radians)).toFixed(2);
                break;
            default:
                result = 'unknown function';
                break;
        }

        const message = `${func} of ${angle} degrees is ${result}`;
        this.runtime.emit(Scratch3LooksBlocks.SAY_OR_THINK, util.target, 'say', message);

        // Wait for the specified number of seconds
        await new Promise(resolve => setTimeout(resolve, seconds * 1000));

        // Clear the message
        this.runtime.emit(Scratch3LooksBlocks.SAY_OR_THINK, util.target, 'say', '');
    }
    async getTrigExactValue(args, util) {
        const func = args.function;
        const angle = args.angle;
        const seconds = args.seconds;
        let result;

        const values = {
            sin: {
                0: '0',
                30: '1/2',
                45: '1/√2',
                60: '√3/2',
                90: '1'
            },
            cos: {
                0: '1',
                30: '√3/2',
                45: '1/√2',
                60: '1/2',
                90: '0'
            },
            tan: {
                0: '0',
                30: '1/√3',
                45: '1',
                60: '√3',
                90: 'undefined'
            },
            cot: {
                0: 'undefined',
                30: '√3',
                45: '1',
                60: '1/√3',
                90: '0'
            },
            sec: {
                0: '1',
                30: '2/√3',
                45: '√2',
                60: '2',
                90: 'undefined'
            },
            cosec: {
                0: 'undefined',
                30: '2',
                45: '√2',
                60: '2/√3',
                90: '1'
            }
        };

        result = values[func][angle];

        this.runtime.emit(Scratch3LooksBlocks.SAY_OR_THINK, util.target, 'say', `${func} of ${angle} degrees is ${result}`);
        
        // Wait for the specified number of seconds
        await new Promise(resolve => setTimeout(resolve, seconds * 1000));
        
        // Clear the message
        this.runtime.emit(Scratch3LooksBlocks.SAY_OR_THINK, util.target, 'say', '');
    }
    rollDie(args, util) {
        const number = Math.floor(Math.random() * 6) + 1;

        // Switch costume to the corresponding dice image
        const costumeName = `dice${number}`;
        const target = util.target;
        
        // Ensure the target has the corresponding costume
        const costume = target.getCostumes().find(c => c.name === costumeName);
        if (costume) {
            target.setCostume(costumeName);
        }

        // Optionally, say the result
        this.runtime.emit(Scratch3LooksBlocks.SAY_OR_THINK, util.target, 'say', `You rolled a ${number}`);
    }
    // 
    calculateStatistic(args) {
        const numbersString = args.NUMBERS;
        const numbersArray = numbersString.split(',').map(Number);
    
        switch (args.STATISTIC) {
            case 'mean':
                const sum = numbersArray.reduce((a, b) => a + b, 0);
                return sum / numbersArray.length;
    
            case 'median':
                const sorted = numbersArray.sort((a, b) => a - b);
                const length = sorted.length;
                if (length % 2 === 0) {
                    return (sorted[length / 2 - 1] + sorted[length / 2]) / 2;
                } else {
                    return sorted[(length - 1) / 2];
                }
    
            case 'mode':
                const frequency = {};
                let maxFreq = 0;
                let mode;
                numbersArray.forEach(number => {
                    frequency[number] = (frequency[number] || 0) + 1;
                    if (frequency[number] > maxFreq) {
                        maxFreq = frequency[number];
                        mode = number;
                    }
                });
                return mode;
    
            default:
                return 'Invalid statistic';
        }
    }

    mathOperations(args) {
        const operation = args.OPERATION;
        const numbersString = args.NUMBERS;
        const numbers = numbersString.split(',').map(Number);
    
        switch (operation) {
            case 'LCM':
                return this.lcmMultiple(numbers);
            case 'HCF':
                return this.gcdMultiple(numbers);
            case 'factors':
                return this.factors(numbers[0]);
            default:
                return 'Invalid operation';
        }
    }
    
    lcmMultiple(numbers) {
        if (numbers.length === 0) return 0;
        let result = numbers[0];
        for (let i = 1; i < numbers.length; i++) {
            result = this.lcm(result, numbers[i]);
        }
        return result;
    }
    
    gcdMultiple(numbers) {
        if (numbers.length === 0) return 0;
        let result = numbers[0];
        for (let i = 1; i < numbers.length; i++) {
            result = this.gcd(result, numbers[i]);
        }
        return result;
    }
    
    factors(n) {
        let factorList = [];
        for (let i = 1; i <= n; i++) {
            if (n % i === 0) {
                factorList.push(i);
            }
        }
        return factorList.toString();
    }
    
    gcd(a, b) {
        while (b !== 0) {
            let temp = b;
            b = a % b;
            a = temp;
        }
        return a;
    }
    
    lcm(a, b) {
        return Math.abs(a * b) / this.gcd(a, b);
    }
    
    // Block functionality
    performMathOperation(args) {
    const operation = args.OPERATION;
    const number = args.NUMBER;

    switch (operation) {
        case 'square root':
            return Math.sqrt(number);
        case 'cubic root':
            return Math.cbrt(number);
        case 'absolute':
            return Math.abs(number);
        case '-':
            return -number;
        case 'ln':
            return Math.log(number);
        case 'log2':
            return Math.log2(number);
        case 'log10':
            return Math.log10(number);
        case 'e^':
            return Math.exp(number);
        case '10^':
            return Math.pow(10, number);
        case 'factorial':
            if (number < 0) return NaN;
            let result = 1;
            for (let i = 2; i <= number; i++) result *= i;
            return result;
        default:
            return 'Invalid operation';
    }
    }
   decimalToHex(args) {
    const number = args.NUMBER;
    
    // Check if the input is a valid number
    if (isNaN(number)) {
        return 'Invalid input';
    }
    
    // Convert the number to an integer
    const intNumber = Math.floor(number);
    
    // Convert to hexadecimal
    let hex = intNumber.toString(16).toUpperCase();
    
    // Add '0x' prefix to indicate hexadecimal
    return '0x' + hex;
   }
   calculateDistance(args) {
    const x1 = args.x1;
    const y1 = args.y1;
    const x2 = args.x2;
    const y2 = args.y2;

    const distance = Math.sqrt((x2 - x1) * 2 + (y2 - y1) * 2);
    return distance;
  }
  calculatePermutation(args) {
    const n = args.n;
    const r = args.r;

    function factorial(num) {
        if (num <= 1) return 1;
        return num * factorial(num - 1);
    }

    const permutation = factorial(n) / factorial(n - r);
    return permutation;
   }
   binaryConversion(args) {
    const operation = args.OPERATION;
    const number = args.NUMBER;

    switch (operation) {
        case 'decimal to binary':
            return parseInt(number).toString(2);
        case 'binary to decimal':
            return parseInt(number, 2).toString();
        default:
            return 'Invalid operation';
    }
  }
}


module.exports = Scratch3CameraExtension;
