// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* #E5F0FF */\n/* #E9F1FC */\n/* #D9E3F2 */\n/* 90% transparent version of motion-primary */\n/* #FFFFFF */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 15% transparent version of black */\n/* #575E75 */\n/* #4C97FF */\n/* #3373CC */\n/*#855CD6 #AD88C6*/\n/* 35% transparent version of looks-tertiary */\n/* 15% transparent version of looks-tertiary */\n/* #714EB6 */\n/* #FF661A */\n/* #E64D00 */\n/* #CF63CF */\n/* #BD42BD */\n/* #FFAB19 */\n/* #FF8C1A */\n/* #0FBD8C */\n/* #0FBD8C */\n/* #0B8E69 */\n/* #FF8C1A */\n/* #FFB366 */\n/* #FF8C1A */\n/* #0FBD8C */\n/* #0B8E69 */\n/* 35% transparent version of extensions-primary */\n/* opaque version of extensions-transparent, on white bg */\n/* lighter than motion-primary */\n/* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */\n/* layout contants from `layout-constants.js` */\n.tag-button_tag-button_nBLRM {\n    padding: .625rem 1rem;\n    background: #3295a8;\n    border-radius: 1.375rem;\n    color: hsla(0, 100%, 100%, 1);\n    height: 2.5rem;\n}\n.tag-button_tag-button-icon_26hvC {\n    max-width: 1rem;\n    max-height: 1rem;\n}\n.tag-button_active_2stEz {\n    background: hsla(30, 100%, 55%, 1);\n}\n", ""]);
// Exports
exports.locals = {
	"tag-button": "tag-button_tag-button_nBLRM",
	"tagButton": "tag-button_tag-button_nBLRM",
	"tag-button-icon": "tag-button_tag-button-icon_26hvC",
	"tagButtonIcon": "tag-button_tag-button-icon_26hvC",
	"active": "tag-button_active_2stEz"
};
module.exports = exports;
