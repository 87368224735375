import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import tabStyles from 'react-tabs/style/react-tabs.css';
import VM from 'scratch-vm';
import Renderer from 'scratch-render';

import PYTHONTab from './code-editor.jsx';
import Blocks from '../../containers/blocks.jsx';
import CostumeTab from '../../containers/costume-tab.jsx';
import TargetPane from '../../containers/target-pane.jsx';
import SoundTab from '../../containers/sound-tab.jsx';
// import PYTHONTab from '../../containers/python-tab.jsx'
import StageWrapper from '../../containers/stage-wrapper.jsx';
import Loader from '../loader/loader.jsx';
import Box from '../box/box.jsx';
import MenuBar from '../menu-bar/menu-bar.jsx';
import CostumeLibrary from '../../containers/costume-library.jsx';
import BackdropLibrary from '../../containers/backdrop-library.jsx';
import Watermark from '../../containers/watermark.jsx';
import Backpack from '../../containers/backpack.jsx';
// import WebGlModal from '../../containers/webgl-modal.jsx';
import TipsLibrary from '../../containers/tips-library.jsx';
import Cards from '../../containers/cards.jsx';
import Alerts from '../../containers/alerts.jsx';
import DragLayer from '../../containers/drag-layer.jsx';
import ConnectionModal from '../../containers/connection-modal.jsx';
import TelemetryModal from '../telemetry-modal/telemetry-modal.jsx';

import layout, { STAGE_SIZE_MODES } from '../../lib/layout-constants';
import { resolveStageSize } from '../../lib/screen-utils';
import { themeMap } from '../../lib/themes';

import styles from './gui.css';
import addExtensionIcon from './icon--extensions.svg';
import codeIcon from './icon--code.svg';
import costumesIcon from './icon--costumes.svg';
import soundsIcon from './icon--sounds.svg';
import logoalt from './img--stemworld.png';
import codingImage from './img--coding.png';
import aiMlImage from './img--ai_ml.png';
import iotElecImage from './img--iot_elec.png';
import arVrImage from './img--ar_vr.png';
import roboticsImage from './img--robotics.png';
import gameDevImage from './img--gamedev.png';
import print3dImage from './img--3dprint.png';
import dronesImage from './img--drones.png';
import gen_ai_img from './img--gen_ai.png';
import Cyber_img from './img--cyber.png';
import pythonIcon from './icon--python.svg';
import gameIcon from './icon--game.svg';
import leftvid from './universe9.mp4';
// import rightvid from './universe.mp4';


import UnityGame from './game.jsx';
const messages = defineMessages({
    addExtension: {
        id: 'gui.gui.addExtension',
        description: 'Button to add an extension in the target pane',
        defaultMessage: 'Add Extension'
    }
});

// let isRendererSupported = null;

const GUIComponent = props => {
    const {
        accountNavOpen,
        activeTabIndex,
        alertsVisible,
        authorId,
        authorThumbnailUrl,
        authorUsername,
        basePath,
        backdropLibraryVisible,
        backpackHost,
        backpackVisible,
        blocksId,
        blocksTabVisible,
        cardsVisible,
        canChangeLanguage,
        audioVideoSetting,
        canChangeTheme,
        canCreateNew,
        canEditTitle,
        canManageFiles,
        canRemix,
        canSave,
        canCreateCopy,
        canShare,
        canUseCloud,
        children,
        connectionModalVisible,
        costumeLibraryVisible,
        costumesTabVisible,
        enableCommunity,
        intl,
        isCreating,
        isFullScreen,
        isPlayerOnly,
        isRtl,
        isShared,
        isTelemetryEnabled,
        isTotallyNormal,
        loading,
        logo,
        renderLogin,
        onClickAbout,
        onClickAccountNav,
        onCloseAccountNav,
        onLogOut,
        onOpenRegistration,
        onToggleLoginOpen,
        onActivateCostumesTab,
        onActivateSoundsTab,
        onPythontab,
        onGametab,
        onActivateTab,
        onClickLogo,
        onExtensionButtonClick,
        onProjectTelemetryEvent,
        onRequestCloseBackdropLibrary,
        onRequestCloseCostumeLibrary,
        onRequestCloseTelemetryModal,
        onSeeCommunity,
        onShare,
        onShowPrivacyPolicy,
        onStartSelectingFileUpload,
        onTelemetryModalCancel,
        onTelemetryModalOptIn,
        onTelemetryModalOptOut,
        showComingSoon,
        soundsTabVisible,
        pythonTabVisible,
        gameTabVisible,
        stageSizeMode,
        targetIsStage,
        telemetryModalVisible,
        theme,
        tipsLibraryVisible,
        vm,
        ...componentProps
    } = props;

    const [isModalOpen, setIsModalOpen] = useState(true);

    const [isRendererSupported, setIsRendererSupported] = useState(Renderer.isSupported());
    const [isSimulationOpen, setIsSimulationOpen] = useState(false);

    const handleToggleSimulation = () => {
        const newSimulationState = !isSimulationOpen;
        setIsSimulationOpen(newSimulationState);
        setIsRendererSupported(!newSimulationState);
    };
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);


    // Function to close the main modal
    // const handleCloseModal = () => {
    //     setIsModalOpen(false);
    //     if (timeoutId) {
    //         clearTimeout(timeoutId);
    //     }
    // };

    // Function to handle option click in the modal
    const handleOptionClick = (option) => {
        onExtensionButtonClick();
        console.log('Option clicked:', option);
        setIsModalOpen(false); // Close the main modal after handling the option

        // Set a timeout to open the login modal after 2 seconds
        const id = setTimeout(() => {
            setIsLoginModalOpen(true);
        }, 1000);

        // Store the timeout ID to clear it if needed
        setTimeoutId(id);
    };
    const modalOptions = [
        { label: 'Coding', image: codingImage, value: 'coding' },
        { label: 'AI and ML', image: aiMlImage, value: 'ai_ml' },
        { label: 'IoT and Electronics', image: iotElecImage, value: 'iot_elec' },
        { label: 'AR/VR', image: arVrImage, value: 'ar_vr' },
        { label: 'Robotics', image: roboticsImage, value: 'robotics' },
        { label: 'Game Development', image: gameDevImage, value: 'game_dev' },
        { label: '3D Printing', image: print3dImage, value: '3d_print' },
        { label: 'Drones', image: dronesImage, value: 'drones' }
    ];
    const LoginModal = ({ onClose }) => {
        const [name, setName] = useState('');
        const [password, setPassword] = useState('');
        const [error, setError] = useState('');

        // Function to handle form submission
        const handleSubmit = (e) => {
            e.preventDefault();

            // Basic validation example
            if (!name || !password) {
                setError('Invalid UserName or Password.');
                return;
            }

            // You can replace this with your actual validation logic
            if (name !== 'stem' || password !== '1234') {
                setError('Invalid UserName or Password.');
                return;
            }

            // Clear the error if validation passes
            setError('');
            // Handle successful login here (e.g., redirect, show a success message, etc.)
            console.log('Login successful');
            onClose(); // Close the modal after successful login
        };

        return (
            <div className={styles.loginModalOverlay}>
                <div className={styles.loginModalContent} onClick={(e) => e.stopPropagation()}>
                    <h2>Account Login</h2>
                    <form onSubmit={handleSubmit}>
                        <div className={styles.inputGroup}>
                            <label>Username:</label>
                            <input
                                type="text"
                                name="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Enter your username"
                            />
                        </div>
                        <div className={styles.inputGroup}>
                            <label>Password:</label>
                            <input
                                type="password"
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Enter your password"
                            />
                        </div>
                        {/* Removed Remember me and Forgot Password elements */}
                        {error && <p className={styles.error}>{error}</p>}
                        <button className={styles.loginButton} type="submit">Login</button>
                        <button className={styles.closeButton} onClick={onClose} type="button">
                            Close
                        </button>

                    </form>

                </div>
            </div>
        );

    }

    // Handle closing of login modal
    const handleCloseLoginModal = () => {
        setIsLoginModalOpen(false);
    };

    // Class names for tabs styling
    const tabClassNames = {
        tabs: styles.tabs,
        tab: `${tabStyles.reactTabsTab} ${styles.tab}`,
        tabList: `${tabStyles.reactTabsTabList} ${styles.tabList}`,
        tabPanel: `${tabStyles.reactTabsTabPanel} ${styles.tabPanel}`,
        tabPanelSelected: `${tabStyles.reactTabsTabPanelSelected} ${styles.isSelected}`,
        tabSelected: `${tabStyles.reactTabsTabSelected} ${styles.isSelected}`
    };

    return (
        <MediaQuery minWidth={layout.fullSizeMinWidth}>
            {isFullSize => {
                const stageSize = resolveStageSize(stageSizeMode, isFullSize);

                return isPlayerOnly ? (
                    <StageWrapper
                        isFullScreen={isFullScreen}
                        isRendererSupported={isRendererSupported}
                        isRtl={isRtl}
                        loading={loading}
                        stageSize={STAGE_SIZE_MODES.large}
                        vm={vm}
                        onToggleSimulation= {handleToggleSimulation}
                        isSimulationOpen={isSimulationOpen}
                    >
                        {alertsVisible ? (
                            <Alerts className={styles.alertsContainer} />
                        ) : null}
                    </StageWrapper>
                ) : (
                    <Box
                        className={styles.pageWrapper}
                        dir={isRtl ? 'rtl' : 'ltr'}
                        {...componentProps}
                    >
                        {telemetryModalVisible ? (
                            <TelemetryModal
                                isRtl={isRtl}
                                isTelemetryEnabled={isTelemetryEnabled}
                                onCancel={onTelemetryModalCancel}
                                onOptIn={onTelemetryModalOptIn}
                                onOptOut={onTelemetryModalOptOut}
                                onRequestClose={onRequestCloseTelemetryModal}
                                onShowPrivacyPolicy={onShowPrivacyPolicy}
                            />
                        ) : null}
                        {loading ? (
                            <Loader />
                        ) : null}
                        {isCreating ? (
                            <Loader messageId="gui.loader.creating" />
                        ) : null}
                        {tipsLibraryVisible ? (
                            <TipsLibrary />
                        ) : null}
                        {cardsVisible ? (
                            <Cards />
                        ) : null}
                        {alertsVisible ? (
                            <Alerts className={styles.alertsContainer} />
                        ) : null}
                        {connectionModalVisible ? (
                            <ConnectionModal
                                vm={vm}
                            />
                        ) : null}
                        {costumeLibraryVisible ? (
                            <CostumeLibrary
                                vm={vm}
                                onRequestClose={onRequestCloseCostumeLibrary}
                            />
                        ) : null}
                        {backdropLibraryVisible ? (
                            <BackdropLibrary
                                vm={vm}
                                onRequestClose={onRequestCloseBackdropLibrary}
                            />

                        ) : null}
                        <MenuBar
                            accountNavOpen={accountNavOpen}
                            authorId={authorId}
                            authorThumbnailUrl={authorThumbnailUrl}
                            authorUsername={authorUsername}
                            canChangeLanguage={canChangeLanguage}
                            audioVideoSetting={audioVideoSetting}
                            canChangeTheme={canChangeTheme}
                            canCreateCopy={canCreateCopy}
                            canCreateNew={canCreateNew}
                            canEditTitle={canEditTitle}
                            canManageFiles={canManageFiles}
                            canRemix={canRemix}
                            canSave={canSave}
                            canShare={canShare}
                            className={styles.menuBarPosition}
                            enableCommunity={enableCommunity}
                            isShared={isShared}
                            isTotallyNormal={isTotallyNormal}
                            logo={logo}
                            renderLogin={renderLogin}
                            showComingSoon={showComingSoon}
                            onClickAbout={onClickAbout}
                            onClickAccountNav={onClickAccountNav}
                            onClickLogo={onClickLogo}
                            onCloseAccountNav={onCloseAccountNav}
                            onLogOut={onLogOut}
                            onOpenRegistration={onOpenRegistration}
                            onProjectTelemetryEvent={onProjectTelemetryEvent}
                            onSeeCommunity={onSeeCommunity}
                            onShare={onShare}
                            onStartSelectingFileUpload={onStartSelectingFileUpload}
                            onToggleLoginOpen={onToggleLoginOpen}
                        />
                        <Box className={styles.bodyWrapper}>
                            <Box className={styles.flexWrapper}>
                                <Box className={styles.editorWrapper}>
                                    <Tabs
                                        forceRenderTabPanel
                                        className={tabClassNames.tabs}
                                        selectedIndex={activeTabIndex}
                                        selectedTabClassName={tabClassNames.tabSelected}
                                        selectedTabPanelClassName={tabClassNames.tabPanelSelected}
                                        onSelect={onActivateTab}
                                    >
                                        <TabList className={tabClassNames.tabList}>
                                            <Tab className={tabClassNames.tab}>
                                                <img
                                                    draggable={false}
                                                    src={codeIcon}
                                                />
                                                <FormattedMessage
                                                    defaultMessage="Code"
                                                    description="Button to get to the code panel"
                                                    id="gui.gui.codeTab"
                                                />
                                            </Tab>
                                            <Tab
                                                className={tabClassNames.tab}
                                                onClick={onActivateCostumesTab}
                                            >
                                                <img
                                                    draggable={false}
                                                    src={costumesIcon}
                                                />
                                                {targetIsStage ? (
                                                    <FormattedMessage
                                                        defaultMessage="Backdrops"
                                                        description="Button to get to the backdrops panel"
                                                        id="gui.gui.backdropsTab"
                                                    />
                                                ) : (
                                                    <FormattedMessage
                                                        defaultMessage="Costumes"
                                                        description="Button to get to the costumes panel"
                                                        id="gui.gui.costumesTab"
                                                    />
                                                )}
                                            </Tab>
                                            <Tab
                                                className={tabClassNames.tab}
                                                onClick={onActivateSoundsTab}
                                            >
                                                <img
                                                    draggable={false}
                                                    src={soundsIcon}
                                                />
                                                <FormattedMessage
                                                    defaultMessage="Sounds"
                                                    description="Button to get to the sounds panel"
                                                    id="gui.gui.soundsTab"
                                                />
                                            </Tab>
                                            <Tab
                                                className={tabClassNames.tab}
                                                onClick={onPythontab}
                                            >
                                                <img
                                                    draggable={false}
                                                    src={pythonIcon}
                                                />
                                                <FormattedMessage
                                                    defaultMessage='Python'
                                                    description='Button to upload python script from file in the editor tab'
                                                    id='gui.pythonTab.uploadPythonScript' // Ensure this line is correctly closed with a '

                                                />
                                            </Tab>
                                            <Tab
                                                className={tabClassNames.tab}
                                                onClick={onGametab}
                                            >
                                                <img
                                                    draggable={false}
                                                    src={gameIcon}
                                                />
                                                <FormattedMessage
                                                    defaultMessage='Game'
                                                    description='Button to open game'
                                                    id='gui.gameTab.openGameTab' // Ensure this line is correctly closed with a '

                                                />
                                            </Tab>
                                        </TabList>
                                        <TabPanel className={tabClassNames.tabPanel}>
                                            <Box className={styles.blocksWrapper}>
                                                <Blocks
                                                    key={`${blocksId}/${theme}`}
                                                    canUseCloud={canUseCloud}
                                                    grow={1}
                                                    isVisible={blocksTabVisible}
                                                    options={{
                                                        media: `${basePath}static/${themeMap[theme].blocksMediaFolder}/`
                                                    }}
                                                    stageSize={stageSize}
                                                    theme={theme}
                                                    vm={vm}
                                                />
                                            </Box>
                                            <Box className={styles.extensionButtonContainer}>
                                                <button
                                                    className={styles.extensionButton}
                                                    title={intl.formatMessage(messages.addExtension)}
                                                    onClick={onExtensionButtonClick}
                                                >
                                                    <img
                                                        className={styles.extensionButtonIcon}
                                                        draggable={false}
                                                        src={addExtensionIcon}
                                                    />
                                                </button>
                                            </Box>
                                            <Box className={styles.watermark}>
                                                <Watermark />
                                            </Box>
                                        </TabPanel>
                                        <TabPanel className={tabClassNames.tabPanel}>
                                            {costumesTabVisible ? <CostumeTab vm={vm} /> : null}
                                        </TabPanel>
                                        <TabPanel className={tabClassNames.tabPanel}>
                                            {soundsTabVisible ? <SoundTab vm={vm} /> : null}
                                        </TabPanel>
                                        <TabPanel className={tabClassNames.tabPanel}>
                                            {pythonTabVisible ? <PYTHONTab /> : null}
                                        </TabPanel>
                                        <TabPanel className={tabClassNames.tabPanel}>
                                            {/* {console.log(gameTabVisible)} */}
                                            {gameTabVisible ? <UnityGame /> : null}
                                        </TabPanel>
                                    </Tabs>
                                    {backpackVisible ? (
                                        <Backpack host={backpackHost} />
                                    ) : null}
                                </Box>
                                <Box className={`${styles.stageAndTargetWrapper} ${styles[stageSize]}`}>
                                    <StageWrapper
                                        isFullScreen={isFullScreen}
                                        isRendererSupported={isRendererSupported}
                                        isRtl={isRtl}
                                        stageSize={stageSize}
                                        vm={vm}
                                        onToggleSimulation={handleToggleSimulation}
                                        isSimulationOpen={isSimulationOpen}
                                    />
                                    {!isSimulationOpen ? (
                                        <Box className={styles.targetWrapper}>
                                            <TargetPane stageSize={stageSize} vm={vm} />
                                    </Box>
                                    ): null}
                                </Box>
                            </Box>
                        </Box>
                        <DragLayer />
                        {/* Modal Structure */}
                        {
                            isModalOpen && (
                                <div className={styles.modalOverlay}>
                                    <h6 className={styles.copyright}>Copyright 2024 - Stem World. <br /> www.stemworld.in</h6>
                                    <video className={styles.backgroundVideo} autoPlay muted loop onClick={(e) => e.stopPropagation()}>
                                        <source src={leftvid} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                    <div className={styles.modalContent} onClick={e => e.stopPropagation()}>

                                        <h2 className={styles.loading}>STEM <span style={{ color: "red", fontSize: 90 }}>V</span>ERSE</h2>
                                        <h2 className={styles.heading}>
                                            STEM VERSE provides K-12 experimental learning platform
                                        </h2>
                                        <div className={styles.modalOptionsContainer}>
                                            <div className={styles.modalOptionsSubContainer}>
                                                <button
                                                    className={styles.modalButton}
                                                    onClick={() => handleOptionClick('coding')}
                                                // onMouseEnter={() => handleMouseEnter('https://media.istockphoto.com/id/1224500457/photo/programming-code-abstract-technology-background-of-software-developer-and-computer-script.jpg?s=612x612&w=0&k=20&c=nHMypkMTU1HUUW85Zt0Ff7MDbq17n0eVeXaoM9Knt4Q=')}
                                                // onMouseLeave={handleMouseLeave}
                                                >
                                                    <img src={codingImage} alt="Coding" className={styles.optionImage} />
                                                    CODING
                                                </button>
                                                <button
                                                    className={styles.modalButton}
                                                    onClick={() => handleOptionClick('ai_ml')}
                                                // onMouseEnter={() => handleMouseEnter('https://www.accord-global.com/images/aiml/AIML.jpg')}
                                                // onMouseLeave={handleMouseLeave}
                                                >
                                                    <img src={aiMlImage} alt="AI_ML" className={styles.optionImage} />
                                                    AI and ML
                                                </button>
                                                <button
                                                    className={styles.modalButton}
                                                    onClick={() => handleOptionClick('iot_elec')}
                                                // onMouseEnter={() => handleMouseEnter('https://media.istockphoto.com/id/1184401187/photo/internet-of-things-wireless-communication-network-abstract-image-visual.jpg?s=612x612&w=0&k=20&c=Thb4RuYqcfOHMepqOfazEhU3ZIp5J_eTerZ5GxxreOQ=')}
                                                // onMouseLeave={handleMouseLeave}
                                                >
                                                    <img src={iotElecImage} alt="IOT_Elec" className={styles.optionImage} />
                                                    IOT/ELEC
                                                </button>
                                                <button
                                                    className={styles.modalButton}
                                                    onClick={() => handleOptionClick('ar_vr')}
                                                // onMouseEnter={() => handleMouseEnter('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvFOhA0rO_KydZv51QY9cHp-ytWT9icnZbnw&s')}
                                                // onMouseLeave={handleMouseLeave}
                                                >
                                                    <img src={arVrImage} alt="AR/VR" className={styles.optionImage} />
                                                    AR/VR
                                                </button>
                                                <button
                                                    className={styles.modalButton}
                                                    onClick={() => handleOptionClick('GEN_AI')}
                                                // onMouseEnter={() => handleMouseEnter('https://miro.medium.com/v2/resize:fit:605/1*D3jgLXA6Y5f8ixdLOE5TNg.png')}
                                                // onMouseLeave={handleMouseLeave}
                                                >
                                                    <img src={gen_ai_img} alt="GEN AI" className={styles.optionImage} />
                                                    GEN AI
                                                </button>
                                            </div>
                                            <div className={styles.logoContainer}>
                                                <img src={logoalt} alt="Logo" className={styles.logo} />
                                            </div>
                                            <div className={styles.modalOptionsSubContainer}>
                                                <button
                                                    className={styles.modalButton}
                                                    onClick={() => handleOptionClick('robotics')}
                                                // onMouseEnter={() => handleMouseEnter('https://t3.ftcdn.net/jpg/05/90/82/32/360_F_590823233_97YNah2bYsEW9llwf7UNK5L3r1cM0Ei3.jpg')}
                                                // onMouseLeave={handleMouseLeave}
                                                >
                                                    <img src={roboticsImage} alt="Robotics" className={styles.optionImage} />
                                                    ROBOTICS
                                                </button>
                                                <button
                                                    className={styles.modalButton}
                                                    onClick={() => handleOptionClick('game_dev')}
                                                // onMouseEnter={() => handleMouseEnter('https://simpleprogrammer.com/wp-content/uploads/2018/01/How-To-Get-Started-In-Game-Development.png')}
                                                // onMouseLeave={handleMouseLeave}
                                                >
                                                    <img src={gameDevImage} alt="Game_Dev" className={styles.optionImage} />
                                                    GAME DEV
                                                </button>
                                                <button
                                                    className={styles.modalButton}
                                                    onClick={() => handleOptionClick('3d_print')}
                                                // onMouseEnter={() => handleMouseEnter('https://media.istockphoto.com/id/1140075616/photo/red-black-3d-printer-printing-blue-logo-symbol-on-metal-diamond-plate-future-technology.jpg?s=612x612&w=0&k=20&c=Vheew0ERHxb1r7IX1InG-a9b4EOLnkuQeZ4EPPw8YyE=')}
                                                // onMouseLeave={handleMouseLeave}
                                                >
                                                    <img src={print3dImage} alt="3D_Print" className={styles.optionImage} />
                                                    3D PRINT
                                                </button>
                                                <button
                                                    className={styles.modalButton}
                                                    onClick={() => handleOptionClick('drones')}
                                                // onMouseEnter={() => handleMouseEnter('https://cdn.mos.cms.futurecdn.net/kXUY9hyetVzhZ2scwJP7p3-1200-80.jpg')}
                                                // onMouseLeave={handleMouseLeave}
                                                >
                                                    <img src={dronesImage} alt="Drones" className={styles.optionImage} />
                                                    DRONES
                                                </button>
                                                <button
                                                    className={styles.modalButton}
                                                    onClick={() => handleOptionClick('Cyber_Kids')}
                                                // onMouseEnter={() => handleMouseEnter('https://www.shutterstock.com/image-photo/professional-girl-gamer-plays-video-600nw-2078121400.jpg')}
                                                // onMouseLeave={handleMouseLeave}
                                                >
                                                    <img src={Cyber_img} alt="Cyber" className={styles.optionImage} />
                                                    CYBER KIDS
                                                </button>
                                            </div>
                                        </div>
                                        {/* <h2 className={styles.bottom}>www.stemworld.in</h2> */}
                                        {/* <h2 className={styles.bottom} style={{ fontSize: "29px",paddingLeft : "-50px", paddingRight: "500px" }}>STEM VERSE provides K-12 experimental learning platform</h2> */}
                                    </div>

                                    {/* <div style={{ marginLeft: "100px" }}>
                                    <div>
                                        <img src={backgroundImage} className={styles.animationImage} style={{ width: "250px", borderRadius: "150px",marginBottom:"100px" }} />
                                    </div>
                                    <div>
                                        <img src={backgroundImage} className={styles.animationImage} style={{ width: "250px", borderRadius: "150px" }} />
                                    </div>
                                </div> */}

                                </div>
                            )
                        }
                        {
                            isLoginModalOpen && (
                                <LoginModal onClose={() => setIsLoginModalOpen(false)} />
                            )
                        }
                    </Box>

                );
            }}
        </MediaQuery >
    );
};

GUIComponent.propTypes = {
    accountNavOpen: PropTypes.bool,
    activeTabIndex: PropTypes.number,
    authorId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    authorThumbnailUrl: PropTypes.string,
    authorUsername: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    backdropLibraryVisible: PropTypes.bool,
    backpackHost: PropTypes.string,
    backpackVisible: PropTypes.bool,
    basePath: PropTypes.string,
    blocksTabVisible: PropTypes.bool,
    blocksId: PropTypes.string,
    canChangeLanguage: PropTypes.bool,
    canChangeTheme: PropTypes.bool,
    audioVideoSetting: PropTypes.bool,
    canCreateCopy: PropTypes.bool,
    canCreateNew: PropTypes.bool,
    canEditTitle: PropTypes.bool,
    canManageFiles: PropTypes.bool,
    canRemix: PropTypes.bool,
    canSave: PropTypes.bool,
    canShare: PropTypes.bool,
    canUseCloud: PropTypes.bool,
    cardsVisible: PropTypes.bool,
    children: PropTypes.node,
    costumeLibraryVisible: PropTypes.bool,
    costumesTabVisible: PropTypes.bool,
    enableCommunity: PropTypes.bool,
    intl: intlShape.isRequired,
    isCreating: PropTypes.bool,
    isFullScreen: PropTypes.bool,
    isPlayerOnly: PropTypes.bool,
    isRtl: PropTypes.bool,
    isShared: PropTypes.bool,
    isTelemetryEnabled: PropTypes.bool,
    isTotallyNormal: PropTypes.bool,
    loading: PropTypes.bool,
    logo: PropTypes.string,
    onActivateCostumesTab: PropTypes.func,
    onActivateSoundsTab: PropTypes.func,
    onPythontab: PropTypes.func,
    onGametab: PropTypes.func,
    onActivateTab: PropTypes.func,
    onClickAccountNav: PropTypes.func,
    onClickLogo: PropTypes.func,
    onCloseAccountNav: PropTypes.func,
    onExtensionButtonClick: PropTypes.func,
    onLogOut: PropTypes.func,
    onOpenRegistration: PropTypes.func,
    onRequestCloseBackdropLibrary: PropTypes.func,
    onRequestCloseCostumeLibrary: PropTypes.func,
    onRequestCloseTelemetryModal: PropTypes.func,
    onSeeCommunity: PropTypes.func,
    onShare: PropTypes.func,
    onShowPrivacyPolicy: PropTypes.func,
    onStartSelectingFileUpload: PropTypes.func,
    onTelemetryModalCancel: PropTypes.func,
    onTelemetryModalOptIn: PropTypes.func,
    onTelemetryModalOptOut: PropTypes.func,
    onToggleLoginOpen: PropTypes.func,
    renderLogin: PropTypes.func,
    showComingSoon: PropTypes.bool,
    soundsTabVisible: PropTypes.bool,
    pythonTabVisible: PropTypes.bool,
    gameTabVisible: PropTypes.bool,
    stageSizeMode: PropTypes.oneOf(Object.keys(STAGE_SIZE_MODES)),
    targetIsStage: PropTypes.bool,
    telemetryModalVisible: PropTypes.bool,
    theme: PropTypes.string,
    tipsLibraryVisible: PropTypes.bool,
    vm: PropTypes.instanceOf(VM).isRequired
};

GUIComponent.defaultProps = {
    backpackHost: null,
    backpackVisible: false,
    basePath: './',
    blocksId: 'original',
    canChangeLanguage: true,
    canChangeTheme: true,
    audioVideoSetting: true,
    canCreateNew: false,
    canEditTitle: false,
    canManageFiles: true,
    canRemix: false,
    canSave: false,
    canCreateCopy: false,
    canShare: false,
    canUseCloud: false,
    enableCommunity: false,
    isCreating: false,
    isShared: false,
    isTotallyNormal: false,
    loading: false,
    showComingSoon: false,
    stageSizeMode: STAGE_SIZE_MODES.large
};

const mapStateToProps = state => ({
    blocksId: state.scratchGui.timeTravel.year.toString(),
    stageSizeMode: state.scratchGui.stageSize.stageSize,
    theme: state.scratchGui.theme.theme
});

export default injectIntl(connect(mapStateToProps)(GUIComponent));
