const BlockType = require('../../extension-support/block-type');
const ArgumentType = require('../../extension-support/argument-type');
const Scratch3LooksBlocks = require('../../blocks/scratch3_looks');

class Scratch3IndiaStatesExtension {
    constructor(runtime) {
        this.runtime = runtime;
        this.rivers = {
            'Ganges': 'Uttarakhand, Uttar Pradesh, Bihar, Jharkhand, West Bengal',
            'Brahmaputra': 'Arunachal Pradesh, Assam',
            'Indus': 'Jammu and Kashmir, Punjab, Haryana, Rajasthan, Gujarat',
            'Godavari': 'Maharashtra, Telangana, Andhra Pradesh, Chhattisgarh, Odisha',
            'Krishna': 'Maharashtra, Karnataka, Telangana, Andhra Pradesh',
            'Yamuna': 'Uttarakhand, Himachal Pradesh, Haryana, Delhi, Uttar Pradesh',
            'Narmada': 'Madhya Pradesh, Maharashtra, Gujarat',
            'Tapti': 'Madhya Pradesh, Maharashtra, Gujarat',
            'Mahanadi': 'Chhattisgarh, Odisha',
            'Cauvery': 'Karnataka, Tamil Nadu',
            'Chenab': 'Himachal Pradesh, Jammu and Kashmir, Punjab',
            'Beas': 'Himachal Pradesh, Punjab'
        };
    }

    getInfo() {
        return {
            id: 'IndiaStatesAndRivers',
            name: 'Indian Geography',
            color1: '#9B59B6',  // Lavender color
            color2: '#9B59B6',  // Lighter shade of lavender
            blocks: [
                {
                    opcode: 'getStateCapital',
                    blockType: BlockType.REPORTER,
                    text: 'capital of [state]',
                    arguments: {
                        state: {
                            type: ArgumentType.STRING,
                            menu: 'statesMenu'
                        }
                    }
                },
                {
                    opcode: 'getStatePark',
                    blockType: BlockType.REPORTER,
                    text: 'park in [state]',
                    arguments: {
                        state: {
                            type: ArgumentType.STRING,
                            menu: 'statesMenu'
                        }
                    }
                },
                {
                    opcode: 'getLongestRiver',
                    blockType: BlockType.COMMAND,
                    text: 'longest river in [state]',
                    arguments: {
                        state: {
                            type: ArgumentType.STRING,
                            menu: 'statesMenu'
                        }
                    }
                },
                {
                    opcode: 'getLongestRiverLength',
                    blockType: BlockType.REPORTER,
                    text: 'length of longest river in [state]',
                    arguments: {
                        state: {
                            type: ArgumentType.STRING,
                            menu: 'statesMenu'
                        }
                    }
                },
                {
                    opcode: 'getStateLanguage',
                    blockType: BlockType.REPORTER,
                    text: 'language(s) of [state]',
                    arguments: {
                        state: {
                            type: ArgumentType.STRING,
                            menu: 'statesMenu'
                        }
                    }
                },
                {
                    opcode: 'showRivers',
                    blockType: BlockType.COMMAND,
                    text: 'Show states of [river] for [seconds] seconds',
                    arguments: {
                        river: {
                            type: ArgumentType.STRING,
                            menu: 'riversMenu'
                        },
                        seconds: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 2
                        }
                    }
                },
                {
                    opcode: 'showUnescoPlace',
                    blockType: BlockType.COMMAND,
                    text: 'Show UNESCO place in [state] for [seconds] seconds',
                    arguments: {
                        state: {
                            type: ArgumentType.STRING,
                            menu: 'statesMenu'
                        },
                        seconds: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 2
                        }
                    }
                }
            ],
            menus: {
                statesMenu: {
                    acceptReporters: false,
                    items: [
                        'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand', 'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttar Pradesh', 'Uttarakhand', 'West Bengal', 'Andaman and Nicobar Islands', 'Chandigarh', 'Dadra and Nagar Haveli and Daman and Diu', 'Delhi', 'Lakshadweep', 'Puducherry'
                    ].map(state => ({ text: state, value: state }))
                },
                riversMenu: {
                    acceptReporters: true,
                    items: Object.keys(this.rivers).map(river => ({ text: river, value: river }))
                },
                unescoSitesMenu: {
                    acceptReporters: false,
                    items: [
                        { text: 'Taj Mahal', value: 'Uttar Pradesh' },
                        { text: 'Khajuraho Group of Monuments', value: 'Madhya Pradesh' },
                        { text: 'Konark Sun Temple', value: 'Odisha' },
                        { text: 'Kaziranga National Park', value: 'Assam' },
                        { text: 'Qutb Minar and its Monuments', value: 'Delhi' },
                        { text: 'Hill Forts of Rajasthan', value: 'Rajasthan' },
                        { text: 'Mahabodhi Temple', value: 'Bihar' }
                    ]
                }
            }
        };
    }

    getStateCapital(args) {
        const stateData = this.getStateData(args.state);
        return stateData ? stateData.capital : 'Unknown';
    }

    getStatePark(args) {
        const stateData = this.getStateData(args.state);
        return stateData ? stateData.park : 'Unknown';
    }

    getLongestRiver(args) {
        const stateData = this.getStateData(args.state);
        return stateData ? stateData.longestRiver : 'Unknown';
    }

    getLongestRiverLength(args) {
        const stateData = this.getStateData(args.state);
        return stateData ? stateData.longestRiverLength : 'Unknown';
    }

    getStateLanguage(args) {
        const stateData = this.getStateData(args.state);
        return stateData ? stateData.language : 'Unknown';
    }

    getStateData(state) {
        const states = {
            'Andhra Pradesh': {
                capital: 'Amaravati',
                park: 'Rajiv Gandhi National Park',
                longestRiver: 'Godavari',
                longestRiverLength: '1,465 km',
                language: 'Telugu'
            },
            'Arunachal Pradesh': {
                capital: 'Itanagar',
                park: 'Namdapha National Park',
                longestRiver: 'Brahmaputra',
                longestRiverLength: '2,900 km',
                language: 'English'
            },
            'Assam': {
                capital: 'Dispur',
                park: 'Kaziranga National Park',
                longestRiver: 'Brahmaputra',
                longestRiverLength: '2,900 km',
                language: 'Assamese'
            },
            'Bihar': {
                capital: 'Patna',
                park: 'Valmiki National Park',
                longestRiver: 'Ganges',
                longestRiverLength: '2,525 km',
                language: 'Hindi'
            },
            'Chhattisgarh': {
                capital: 'Raipur',
                park: 'Indravati National Park',
                longestRiver: 'Mahanadi',
                longestRiverLength: '858 km',
                language: 'Hindi'
            },
            'Goa': {
                capital: 'Panaji',
                park: 'Bhagwan Mahavir Wildlife Sanctuary',
                longestRiver: 'Mandovi',
                longestRiverLength: '77 km',
                language: 'Konkani'
            },
            'Gujarat': {
                capital: 'Gandhinagar',
                park: 'Gir National Park',
                longestRiver: 'Narmada',
                longestRiverLength: '1,312 km',
                language: 'Gujarati'
            },
            'Haryana': {
                capital: 'Chandigarh',
                park: 'Sultanpur National Park',
                longestRiver: 'Yamuna',
                longestRiverLength: '1,376 km',
                language: 'Hindi'
            },
            'Himachal Pradesh': {
                capital: 'Shimla',
                park: 'Great Himalayan National Park',
                longestRiver: 'Beas',
                longestRiverLength: '470 km',
                language: 'Hindi'
            },
            'Jharkhand': {
                capital: 'Ranchi',
                park: 'Betla National Park',
                longestRiver: 'Ganges',
                longestRiverLength: '2,525 km',
                language: 'Hindi'
            },
            'Karnataka': {
                capital: 'Bangalore',
                park: 'Bandipur National Park',
                longestRiver: 'Krishna',
                longestRiverLength: '1,400 km',
                language: 'Kannada'
            },
            'Kerala': {
                capital: 'Thiruvananthapuram',
                park: 'Periyar National Park',
                longestRiver: 'Periyar',
                longestRiverLength: '244 km',
                language: 'Malayalam'
            },
            'Madhya Pradesh': {
                capital: 'Bhopal',
                park: 'Kanha National Park',
                longestRiver: 'Narmada',
                longestRiverLength: '1,312 km',
                language: 'Hindi'
            },
            'Maharashtra': {
                capital: 'Mumbai',
                park: 'Tadoba Andhari Tiger Reserve',
                longestRiver: 'Godavari',
                longestRiverLength: '1,465 km',
                language: 'Marathi'
            },
            'Manipur': {
                capital: 'Imphal',
                park: 'Keibul Lamjao National Park',
                longestRiver: 'Barak',
                longestRiverLength: '900 km',
                language: 'Meitei'
            },
            'Meghalaya': {
                capital: 'Shillong',
                park: 'Nokrek National Park',
                longestRiver: 'Umngot',
                longestRiverLength: '97 km',
                language: 'English'
            },
            'Mizoram': {
                capital: 'Aizawl',
                park: 'Dampa Tiger Reserve',
                longestRiver: 'Tlawng',
                longestRiverLength: '185 km',
                language: 'Mizo'
            },
            'Nagaland': {
                capital: 'Kohima',
                park: 'Intangki National Park',
                longestRiver: 'Dhansiri',
                longestRiverLength: '352 km',
                language: 'English'
            },
            'Odisha': {
                capital: 'Bhubaneswar',
                park: 'Simlipal National Park',
                longestRiver: 'Mahanadi',
                longestRiverLength: '858 km',
                language: 'Odia'
            },
            'Punjab': {
                capital: 'Chandigarh',
                park: 'Harike Wetland',
                longestRiver: 'Sutlej',
                longestRiverLength: '1,450 km',
                language: 'Punjabi'
            },
            'Rajasthan': {
                capital: 'Jaipur',
                park: 'Ranthambore National Park',
                longestRiver: 'Chambal',
                longestRiverLength: '960 km',
                language: 'Hindi'
            },
            'Sikkim': {
                capital: 'Gangtok',
                park: 'Khangchendzonga National Park',
                longestRiver: 'Teesta',
                longestRiverLength: '315 km',
                language: 'English'
            },
            'Tamil Nadu': {
                capital: 'Chennai',
                park: 'Mudumalai National Park',
                longestRiver: 'Cauvery',
                longestRiverLength: '805 km',
                language: 'Tamil'
            },
            'Telangana': {
                capital: 'Hyderabad',
                park: 'Kasu Brahmananda Reddy National Park',
                longestRiver: 'Godavari',
                longestRiverLength: '1,465 km',
                language: 'Telugu'
            },
            'Tripura': {
                capital: 'Agartala',
                park: 'Clouded Leopard National Park',
                longestRiver: 'Gomati',
                longestRiverLength: '87 km',
                language: 'Bengali'
            },
            'Uttar Pradesh': {
                capital: 'Lucknow',
                park: 'Dudhwa National Park',
                longestRiver: 'Ganges',
                longestRiverLength: '2,525 km',
                language: 'Hindi'
            },
            'Uttarakhand': {
                capital: 'Dehradun',
                park: 'Jim Corbett National Park',
                longestRiver: 'Ganges',
                longestRiverLength: '2,525 km',
                language: 'Hindi'
            },
            'West Bengal': {
                capital: 'Kolkata',
                park: 'Sundarbans National Park',
                longestRiver: 'Ganges',
                longestRiverLength: '2,525 km',
                language: 'Bengali'
            },
            'Andaman and Nicobar Islands': {
                capital: 'Port Blair',
                park: 'Mahatma Gandhi Marine National Park',
                longestRiver: 'None',
                longestRiverLength: 'None',
                language: 'Hindi'
            },
            'Chandigarh': {
                capital: 'Chandigarh',
                park: 'Sukhna Wildlife Sanctuary',
                longestRiver: 'Ghaggar-Hakra',
                longestRiverLength: '465 km',
                language: 'Hindi'
            },
            'Dadra and Nagar Haveli and Daman and Diu': {
                capital: 'Daman',
                park: 'Vansda National Park',
                longestRiver: 'Damanganga',
                longestRiverLength: '131 km',
                language: 'Gujarati'
            },
            'Delhi': {
                capital: 'New Delhi',
                park: 'Yamuna Biodiversity Park',
                longestRiver: 'Yamuna',
                longestRiverLength: '1,376 km',
                language: 'Hindi'
            },
            'Lakshadweep': {
                capital: 'Kavaratti',
                park: 'Marine National Park',
                longestRiver: 'None',
                longestRiverLength: 'None',
                language: 'Malayalam'
            },
            'Puducherry': {
                capital: 'Puducherry',
                park: 'Ousteri Lake',
                longestRiver: 'None',
                longestRiverLength: 'None',
                language: 'Tamil'
            }
        };

        return states[state];
    }

    showRivers(args, util) {
        const river = args.river;
        const seconds = args.seconds;
        const states = this.rivers[river] || 'Unknown states';

        this.runtime.emit(Scratch3LooksBlocks.SAY_OR_THINK, util.target, 'say', `States through which ${river} passes: ${states}`);

        // Wait for the specified number of seconds
        setTimeout(() => {
            // Clear the message
            this.runtime.emit(Scratch3LooksBlocks.SAY_OR_THINK, util.target, 'say', '');
        }, seconds * 1000);
    }

    showUnescoPlace(args, util) {
        const state = args.state;
        const seconds = args.seconds;
        const unescoSites = {
            'Himachal pradesh Pradesh': 'Great Himalayan national park',
            'Madhya Pradesh': 'Khajuraho Group of Monuments',
            'Odisha': 'Konark Sun Temple',
            'Assam': 'Kaziranga National Park,Manas wildlife sanctuary',
            'Delhi': 'Qutb Minar and its Monuments',
            'Rajasthan': 'Keoladeo ghana national park',
            'Bihar': 'Mahabodhi Temple',
            'Tamil Nadu': 'Western Ghats',
            'West Bengal' : 'Sundarbans National Park',
            'Uttarakhand' : 'Nanda Devi National Park and Valley of Flowers',
            'Kerala' : 'Western Ghats',
            'Goa' : 'Western Ghats' ,
            //'Assam': 'Manas Wildlife sanctuary',
            'Karnataka': 'Western Ghats',
            'Goa' : 'Western Ghats',
            'West bengal' : 'Sundarbans National Park'
            // Add more UNESCO sites as needed
        };

        const place = unescoSites[state] || 'No UNESCO site found';

        this.runtime.emit(Scratch3LooksBlocks.SAY_OR_THINK, util.target, 'say', `UNESCO place in ${state}: ${place}`);

        // Wait for the specified number of seconds
        setTimeout(() => {
            // Clear the message
            this.runtime.emit(Scratch3LooksBlocks.SAY_OR_THINK, util.target, 'say', '');
        }, seconds * 1000);
    }
}

module.exports = Scratch3IndiaStatesExtension;
